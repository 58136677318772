import React, { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector";

import { dataProvidingPlayer } from "../features/view-controls";
import { GridData, gridDataFromProps, NodeInfo } from "../features/grid";
import {
  SetSquareSource,
  isBelowLg,
  setFocus,
  useViewControlsSelector,
  FocusLocation,
  ClickLocation,
  Dimensions
} from "../features/view-controls/viewSlice";

import CopyToClipboard from "../utils/clipboard";
import handspace from "../utils/handspace";
import { linkToNode } from "../utils/nav";
import {
  DisplayStringLength,
  displayStringForNode,
  Node
} from "../utils/nodespace";
import { useUserPrefsContext } from "../utils/preferences";
import { CenteredSpinner } from "../utils";

import {
  OmniGrid,
  OmniDetails,
  OmniGridLegend,
  OmniGridStrategyLegend
} from "./grid";
import { SpotInfo } from "../services/piosaasTreeApi";
import { AnyAction } from "@reduxjs/toolkit";
import { useNodeStateSelector } from "../features/view-controls/nodeSlice";
import { useSpotStateSelector } from "../features/view-controls/spotSlice";
import { GridDisplayProps } from "./grid/grid.types";
import { CenteredSpinnerProps } from "../utils/spinner/Spinner";
import { NodeError as NodeDataError } from "../features/grid/gridData.types";

type HalfVertical = "top" | "bottom";
function detailsPopoverLocation(
  clickLocation: ClickLocation | undefined,
  half: HalfVertical
) {
  if (clickLocation == null) return "65%";
  if (half === "top") return clickLocation.y;
  return `calc(${clickLocation.y}px - 25%)`;
}

function handGroupHalf(handGroup: string | null) {
  if (handGroup == null) return "top";
  const idx = handspace.handspaceIndexMap[handGroup];
  if (idx[0] > 6) return "bottom" as HalfVertical;
  return "top" as HalfVertical;
}

function focusChangeHandler(
  dispatch: Dispatch<AnyAction>,
  location: FocusLocation
) {
  return (e: unknown) => {
    dispatch(setFocus(location));
  };
}

function FloatingDetails(props: VizDetailsProps) {
  const { isLoading, isFetching } = props;
  if (isLoading) return <CenteredSpinner />;
  if (isFetching) return null;
  return <OmniDetails squareSize={90} detailsStyle="floating" {...props} />;
}

interface VizDetailsProps extends GridDisplayProps {
  isLoading: boolean;
  isFetching: boolean;
  componentDims: Dimensions;
  isDisplayingStrategy: boolean;
  isEndNode: boolean;
  isSplitNode: boolean;
  gridData: GridData;
}
function PopoverDetails(props: VizDetailsProps) {
  const { isLoading, isFetching } = props;
  const { square, viewportDimensions } = useViewControlsSelector(
    (state) => state.viewControls
  );
  if (square.source !== SetSquareSource.CLICK) return null;
  if (!isBelowLg(viewportDimensions)) return null;

  if (isLoading) return <CenteredSpinner />;
  if (isFetching) return null;
  if (props.data.error != null) return null;

  const handGroup = square.handGroup;
  const zIndex = handGroup == null ? -1 : 2000;
  const display = handGroup == null ? "none" : "block";
  const half = handGroupHalf(handGroup);
  const top = detailsPopoverLocation(square.location, half);

  return (
    <div className="board-popover-details" style={{ display, top, zIndex }}>
      <OmniDetails squareSize={90} detailsStyle="popover" {...props} />
    </div>
  );
}

function RetryableNotice({ retryable }: { retryable: boolean }) {
  if (!retryable) return null;
  return (
    <p>
      <b className="fw-semibold">
        If you refresh your browser and navigate to this node again, we think it
        will work the next time.
      </b>
    </p>
  );
}
type NodeErrorProps = {
  error: NodeDataError;
};
function NodeError(props: NodeErrorProps) {
  const { displayNodeId, rootBoard, board } = useNodeStateSelector(
    (state) => state.nodeState
  );
  const { spot } = useSpotStateSelector((state) => state.spotState);
  const link = linkToNode(spot, rootBoard, displayNodeId, board);

  const retryable = props.error.retryable;
  return (
    <div className="w-100 h-100">
      <div>
        <h3>Data retrieval failed</h3>
        <p>There was an error in retrieving the data for this node.</p>
        <p>
          This service is still under development, and you seem to have
          encountered one of the rough edges, sorry about that!
        </p>
        <div className="bg-dark p-2 px-3 my-2">
          <h2>What to do</h2>
          <RetryableNotice retryable={retryable} />
          <p>
            If this problem persists, please{" "}
            <CopyToClipboard clipboardText={link}>
              copy a link to this node
            </CopyToClipboard>{" "}
            and forward it to us, so we can investigate.
          </p>
        </div>
        <h4>Error details</h4>
        <p>
          <i>{props.error.message}</i>
        </p>
      </div>
    </div>
  );
}

interface BoardHandGridProps extends GridDisplayProps {
  gridData: GridData;
  componentDims: Dimensions;
  isLoading: boolean;
}
function BoardHandGrid(props: BoardHandGridProps) {
  if (props.data.error != null) return <NodeError error={props.data.error} />;

  return <OmniGrid squareSize={30} {...props} />;
}

interface BoardLegendProps extends GridDisplayProps {
  isLoading: boolean;
  gridData: GridData;
}
function BoardLegend(props: BoardLegendProps) {
  const { display, data, isLoading, node, player, rangeAction } = props;
  const nodeTree = useSpotStateSelector((state) => state.spotState.nodeTree);
  if (data?.error != null) return <div />;
  const stratDisplay = display.toUpperCase().includes("STRAT");
  let displayDesc = display as string;
  if (display === "Action+Range") {
    const arNode = nodeTree[node.children[rangeAction?.index as number]];
    const desc = displayStringForNode(
      arNode,
      DisplayStringLength.SHORT,
      "Chips"
    );
    displayDesc = `${desc} Range`;
  }

  const title = (
    <div className="flex-fill flex-shrink-0 me-2">
      <b>{player}</b> {displayDesc}
    </div>
  );

  if (!stratDisplay && (isLoading || !data)) return title;

  return (
    <div className="d-flex flex-row justify-content-between">
      {title}
      <div className="flex-fill flex-shrink-1">
        <OmniGridLegend squareSize={30} {...props} />
      </div>
    </div>
  );
}

interface SpinnerOverlayProps extends CenteredSpinnerProps {
  isFetching: boolean;
}
function SpinnerOverlay({ isFetching, message }: SpinnerOverlayProps) {
  if (!isFetching) return null;
  return (
    <div className="spinner-overlay">
      <CenteredSpinner message={message} />
    </div>
  );
}

type StrategyOverlayProps = {
  isDisplayingStrategy: boolean;
  isSplitNode: boolean;
  isEndNode: boolean;
};
function StrategyOverlay({
  isDisplayingStrategy,
  isSplitNode,
  isEndNode
}: StrategyOverlayProps) {
  if (!isDisplayingStrategy) return null;
  let noStratMessage = null;

  if (isSplitNode)
    noStratMessage = "Strategy will be shown once a card is selected...";
  else if (isEndNode)
    noStratMessage = "Strategy can be shown only on decision nodes";
  if (noStratMessage == null) return null;

  return (
    <div className="spinner-overlay">
      <div className="board-strat-legend"></div>
      <div className="board-big-grid no-strat-message">{noStratMessage}</div>
    </div>
  );
}

type VizProps = {
  data: NodeInfo;
  node: Node;
  isFetching: boolean;
  isLoading: boolean;
  spotInfo: SpotInfo;
};
function Viz({ data, node, isFetching, isLoading, spotInfo }: VizProps) {
  const dispatch = useDispatch();
  const { display, player, rangeAction, viewportDimensions } =
    useViewControlsSelector((state) => state.viewControls);
  // const stratLegendRef = React.useRef < HTMLDivElement > null;
  const stratLegendRef = React.useRef(null);
  const { width: stratLegendWidth } = useResizeDetector({
    targetRef: stratLegendRef
  });
  const bigGridRef = React.useRef(null);
  const bigGridDims = useResizeDetector({ targetRef: bigGridRef });

  const floatingDetailsRef = React.useRef(null);
  const floatingDetailsDims = useResizeDetector({
    targetRef: floatingDetailsRef
  });

  const popoverDetailsRef = React.useRef(null);
  const popoverDetailsDims = useResizeDetector({
    targetRef: popoverDetailsRef
  });

  const { presentation } = useUserPrefsContext();

  if (node == null) return null;
  const squareSize = isBelowLg(viewportDimensions) ? 42 : 58;
  const isDisplayingStrategy =
    display.toUpperCase().includes("STRAT") || display === "Action+Range";
  const usedPlayer = dataProvidingPlayer(isDisplayingStrategy, node, player);
  const evRescale = presentation.evDisplay.evRescale;

  const nodeTypeNormalized = node.type.toUpperCase();
  const isSplitNode = nodeTypeNormalized.includes("SPLIT");
  const isEndNode = nodeTypeNormalized.includes("END");
  const strategyOverlay = (
    <StrategyOverlay
      isDisplayingStrategy={isDisplayingStrategy}
      isSplitNode={isSplitNode}
      isEndNode={isEndNode}
    />
  );

  const dataProps = {
    data,
    player: usedPlayer,
    spotInfo
  };

  const gridData =
    data != null && data.error == null ? gridDataFromProps(dataProps) : null;
  const content = (
    <>
      <div className="board-strat-legend" ref={stratLegendRef}>
        <OmniGridStrategyLegend
          data={data}
          display={display}
          evRescale={evRescale}
          gridData={gridData as GridData}
          isLoading={isLoading}
          isFetching={isFetching}
          node={node}
          player={usedPlayer}
          rangeAction={rangeAction}
          spotInfo={spotInfo}
          squareSize={squareSize}
          width={stratLegendWidth ?? viewportDimensions.width}
        />
      </div>
      <div
        className="board-big-grid"
        onMouseDown={focusChangeHandler(dispatch, "grid")}
        ref={popoverDetailsRef}>
        <PopoverDetails
          componentDims={popoverDetailsDims as Dimensions}
          data={data}
          display={display}
          gridData={gridData as GridData}
          isLoading={isLoading}
          isFetching={isFetching}
          isDisplayingStrategy={isDisplayingStrategy}
          isSplitNode={isSplitNode}
          isEndNode={isEndNode}
          player={usedPlayer}
          rangeAction={rangeAction}
          spotInfo={spotInfo}
          node={node}
          evRescale={evRescale}
        />
        <div className="big-grid" ref={bigGridRef}>
          <BoardHandGrid
            componentDims={bigGridDims as Dimensions}
            data={data}
            display={display}
            evRescale={evRescale}
            gridData={gridData as GridData}
            isLoading={isLoading}
            player={usedPlayer}
            rangeAction={rangeAction}
            spotInfo={spotInfo}
            node={node}
          />
        </div>
      </div>
      <div
        className="board-details"
        onMouseDown={focusChangeHandler(dispatch, "grid")}
        ref={floatingDetailsRef}>
        <FloatingDetails
          componentDims={floatingDetailsDims as Dimensions}
          data={data}
          display={display}
          gridData={gridData as GridData}
          isLoading={isLoading}
          isFetching={isFetching}
          isDisplayingStrategy={isDisplayingStrategy}
          isSplitNode={isSplitNode}
          isEndNode={isEndNode}
          player={usedPlayer}
          rangeAction={rangeAction}
          spotInfo={spotInfo}
          node={node}
          evRescale={evRescale}
        />
      </div>
      <div
        className="board-legend"
        onMouseDown={focusChangeHandler(dispatch, "grid")}>
        <BoardLegend
          data={data}
          display={display}
          gridData={gridData as GridData}
          isLoading={isLoading}
          player={usedPlayer}
          rangeAction={rangeAction}
          spotInfo={spotInfo}
          node={node}
          evRescale={evRescale}
        />
      </div>
    </>
  );

  return (
    <>
      <SpinnerOverlay isFetching={isFetching} message="Fetching node data" />
      {strategyOverlay}
      {content}
    </>
  );
}

export default Viz;
