import React from "react";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  cardsCompressAndSplit,
  CommunityCards,
  PlayingCard
} from "../../../utils/cards";
import { DealChooserProps, streetsToCardIndex } from "./deal.types";
import type { StreetName } from "../../../utils/nodespace";

import { RunoutsInlineLong, RunoutsInlineWide } from "./runouts";
import FlopInline from "./flop-chooser";

function DealInline(props: DealChooserProps) {
  const { board, wideMode, node, flopSubset } = props;
  if (node.street === 0) {
    return (
      <div className="mb-1" style={{ maxWidth: "300px" }}>
        <FlopInline
          board={board}
          flops={flopSubset}
          node={node}
          useCardStyle={false}
        />
      </div>
    );
  }

  if (wideMode) return <RunoutsInlineWide {...props} />;
  return <RunoutsInlineLong {...props} />;
}

const chosenCards = (boardArray: string[], cardIndex: number) => {
  if (!boardArray) return [""];
  if (cardIndex === 2) return boardArray.slice(0, 3);

  return [boardArray[cardIndex]];
};

type DealCardsProps = {
  board: string;
  cardIndex: number;
  isBold?: boolean;
};
function DealCards({ board, cardIndex }: DealCardsProps) {
  const cards = chosenCards(cardsCompressAndSplit(board), cardIndex);
  const isFlop = cards.length === 3;
  return isFlop ? (
    <CommunityCards cards={cards.join("")} isPlain={false} isBold={false} />
  ) : (
    <PlayingCard isBold={false} isSelected={false} value={cards[0]} />
  );
}
type InlineDealDisplayProps = {
  board: string;
  isDisplayNode?: boolean;
  streetString: StreetName;
  onClick: (card: unknown) => void;
};

function DealDisplay({
  board,
  isDisplayNode,
  streetString,
  onClick
}: InlineDealDisplayProps) {
  const cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const cardIsChosen = boardArray && boardArray[cardIndex];
  const className = `btn btn-dark ${
    isDisplayNode ? "active-tree-node" : "non-active-tree-node"
  }`;
  return (
    <Button className={className} type="button" size="sm" onClick={onClick}>
      {cardIsChosen ? (
        <DealCards board={board} cardIndex={cardIndex} isBold={isDisplayNode} />
      ) : (
        streetString
      )}
    </Button>
  );
}

function DealChooser(props: DealChooserProps) {
  const { board, hasFocus, streetString } = props;
  const dealProps = props;
  const cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const cardIsChosen = boardArray && boardArray[cardIndex];
  return (
    <OverlayTrigger
      trigger={["click", "focus"]}
      placement="bottom"
      show={hasFocus ? undefined : false}
      defaultShow={hasFocus}
      overlay={(props) => (
        <Tooltip
          className="position-absolute"
          {...props}
          data-cy="deal-chooser">
          <DealInline {...dealProps} />
        </Tooltip>
      )}>
      <Button className="btn btn-dark" type="button" size="sm">
        {cardIsChosen ? (
          <DealCards board={board} cardIndex={cardIndex} key="DealCards" />
        ) : (
          streetString
        )}
      </Button>
    </OverlayTrigger>
  );
}

export default DealChooser;
export { DealDisplay, DealCards };
export type { DealChooserProps };
