/**
 * nodeDna
 * A compressed node navigation widget.
 * **Not currently used** but kept here in case we want to re-introduce it.
 */
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  DisplayStringLength,
  Node,
  NodeTree,
  NodeTypes,
  displayStringForNode,
  isNodeChildOfNode,
  parentsOfNodeByStreet,
  selectedChildOfNode,
  streetStringForNode
} from "../../../utils/nodespace";

import { DealDisplay } from "../deals";
import { useNodeStateDispatch, useNodeStateSelector } from "../nodeSlice";
import { useViewControlsDispatch } from "../viewSlice";

import {
  ActionNodeBreadcrumbButton,
  DealControl,
  availableStreets,
  onSelectCurrentNodeGhost,
  onSelectDisplayNode,
  styleForActionButton
} from "./node";
import { BetSizeDisplay } from "../../../utils/preferences";
import { useSpotStateSelector } from "../spotSlice";

type NodeSelectorBackProps = {
  allNodes: NodeTree;
  node: Node;
  board: string;
};

function NodeSelectorBack({ allNodes, node, board }: NodeSelectorBackProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();

  const onClick = () => {
    if (!node.parent) return null;
    onSelectCurrentNodeGhost(
      dispatch,
      allNodes,
      board,
      node.parent.id,
      viewControlsDispatch
    );
  };

  return (
    <Button
      className="mx-1"
      size="sm"
      data-cy="node-back"
      style={{ width: "fit-content" }}
      onClick={onClick}
      variant="secondary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-counterclockwise"
        viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg>
    </Button>
  );
}

type SelectorQuickControlProps = {
  allNodes: NodeTree;
  board: string;
  node: Node;
  selectedNode?: Node;
  betSizeDisplay: BetSizeDisplay;
};

function NodeSelectorQuickControl({
  allNodes,
  board,
  node,
  selectedNode,
  betSizeDisplay
}: SelectorQuickControlProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();

  if (node.type === NodeTypes.END_NODE) return null; //<span className="fw-bold">End</span>;

  return (
    <span className="my-1">
      {node.children.map((childId, i) => {
        const isSelected = selectedNode?.id === childId;
        const className = isSelected ? "mb-1 mx-1 fw-bold" : "mb-1 mx-1";
        return (
          <Button
            className={className}
            id={childId}
            key={i}
            onClick={(e) =>
              onSelectCurrentNodeGhost(
                dispatch,
                allNodes,
                board,
                childId,
                viewControlsDispatch
              )
            }
            size="sm"
            style={styleForActionButton(allNodes[childId].action)}
            value={childId}>
            {displayStringForNode(
              allNodes[childId],
              DisplayStringLength.FULL,
              betSizeDisplay
            )}
          </Button>
        );
      })}
    </span>
  );
}
function NodeSelectorQuick({
  board,
  betSizeDisplay
}: {
  board: string;
  betSizeDisplay: BetSizeDisplay;
}) {
  const { nodeTree: allNodes } = useSpotStateSelector(
    (state) => state.spotState
  );
  const { currentNodeGhostId, displayNodeId, flopSubset } =
    useNodeStateSelector((state) => ({
      currentNodeGhostId: state.nodeState.currentNodeGhostId,
      displayNodeId: state.nodeState.displayNodeId,
      flopSubset: state.nodeState.flopSubset
    }));
  const dispatch = useNodeStateDispatch();

  const node = allNodes[displayNodeId];
  const selectedNode =
    currentNodeGhostId !== displayNodeId
      ? selectedChildOfNode(node, allNodes[currentNodeGhostId])
      : undefined;
  return (
    <div className="my-2 node-action">
      <NodeSelectorBack allNodes={allNodes} board={board} node={node} />
      <span>Action </span>{" "}
      {node.type === NodeTypes.SPLIT ? (
        <>
          <DealControl
            allNodes={allNodes}
            board={board}
            clearGhostNodes={currentNodeGhostId === displayNodeId}
            dispatch={dispatch}
            flopSubset={flopSubset}
            hasFocus={true}
            node={node}
            wideMode={false}
          />
        </>
      ) : (
        <>
          <NodeSelectorQuickControl
            allNodes={allNodes}
            board={board}
            node={node}
            selectedNode={selectedNode}
            betSizeDisplay={betSizeDisplay}
          />
        </>
      )}
    </div>
  );
}

interface BreadcrumbProps {
  allNodes: NodeTree;
  board: string;
  node: Node;
  betSizeDisplay: BetSizeDisplay;
}

function RootNodeBreadcrumb({
  allNodes,
  board,
  node,
  betSizeDisplay
}: BreadcrumbProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();

  return (
    <ActionNodeBreadcrumbButton
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      node={node}
      useFullLabel={false}
      betSizeDisplay={betSizeDisplay}
    />
  );
}

function ActionBreadcrumb({
  allNodes,
  board,
  node,
  betSizeDisplay
}: BreadcrumbProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();

  return (
    <ActionNodeBreadcrumbButton
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      node={node}
      useFullLabel={false}
      betSizeDisplay={betSizeDisplay}
    />
  );
}

function DealBreadcrumb({ allNodes, node, board }: BreadcrumbProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();
  const displayNodeId = useNodeStateSelector(
    (state) => state.nodeState.displayNodeId
  );
  const isDisplayNode = displayNodeId === node.id;
  const streetString = streetStringForNode(node);

  return (
    <DealDisplay
      board={board}
      isDisplayNode={isDisplayNode}
      streetString={streetString}
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      key={`${streetString}Display`}
    />
  );
}

function NodeBreadcrumb({
  allNodes,
  board,
  node,
  betSizeDisplay
}: BreadcrumbProps) {
  const displayNodeId = useNodeStateSelector(
    (state) => state.nodeState.displayNodeId
  );
  const nodeDownstreamOfDisplay = isNodeChildOfNode(node.id, displayNodeId);
  const style = nodeDownstreamOfDisplay ? { opacity: 0.4 } : {};
  return (
    <div className="mx-1" style={style}>
      {node.parent?.type === NodeTypes.SPLIT ? (
        <DealBreadcrumb
          allNodes={allNodes}
          board={board}
          node={node}
          betSizeDisplay={betSizeDisplay}
        />
      ) : (
        <ActionBreadcrumb
          allNodes={allNodes}
          board={board}
          node={node}
          betSizeDisplay={betSizeDisplay}
        />
      )}
    </div>
  );
}

type NodeStreetBreadcrumbProps = {
  rowNodes: Node[];
  allNodes: NodeTree;
  board: string;
  street: number;
  betSizeDisplay: BetSizeDisplay;
};

function StreetBreadcrumb({
  rowNodes,
  allNodes,
  board,
  street,
  betSizeDisplay
}: NodeStreetBreadcrumbProps) {
  if (!rowNodes) return null;
  const isRootStreet = allNodes["r:0"].street === street;
  if (isRootStreet) {
    // We do not want the root node, since that is already displayed
    rowNodes = rowNodes.slice(1);
  }

  return (
    <>
      {rowNodes.map((node, i) => (
        <NodeBreadcrumb
          key={i}
          allNodes={allNodes}
          board={board}
          node={node}
          betSizeDisplay={betSizeDisplay}
        />
      ))}
    </>
  );
}

function NodeDnaBreadcrumb({
  board,
  betSizeDisplay
}: {
  board: string;
  betSizeDisplay: BetSizeDisplay;
}) {
  const currentNodeGhostId = useNodeStateSelector(
    (state) => state.nodeState.currentNodeGhostId
  );
  const allNodes = useSpotStateSelector((state) => state.spotState.nodeTree);
  const nodesByStreet = parentsOfNodeByStreet(allNodes[currentNodeGhostId]);
  return (
    <div className="d-flex node-breadcrumb">
      <div className="mx-1">
        <RootNodeBreadcrumb
          allNodes={allNodes}
          board={board}
          node={allNodes["r:0"]}
          betSizeDisplay={betSizeDisplay}
        />
      </div>
      {availableStreets.map((street) => (
        <StreetBreadcrumb
          key={`streetRow${street}`}
          rowNodes={nodesByStreet[street]}
          allNodes={allNodes}
          board={board}
          street={street}
          betSizeDisplay={betSizeDisplay}
        />
      ))}
    </div>
  );
}

type NodeDnaControlsProps = {
  board: string;
  betSizeDisplay: BetSizeDisplay;
};

function NodeDnaControls({ board, betSizeDisplay }: NodeDnaControlsProps) {
  return (
    <>
      <Row>
        <Col>
          <NodeDnaBreadcrumb board={board} betSizeDisplay={betSizeDisplay} />
          <NodeSelectorQuick board={board} betSizeDisplay={betSizeDisplay} />
        </Col>
      </Row>
    </>
  );
}

export default NodeDnaControls;
