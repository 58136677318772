import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

import { Spinner } from "../../utils";
import { ForgotPasswordLink } from "./RequireUser";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
  return "status" in error;
}

interface ErrorData {
  error: string;
}

function isEmailNotConfirmedError(
  error: FetchBaseQueryError | SerializedError | undefined
) {
  if (!error) return false;
  if (!isFetchBaseQueryError(error)) return false;
  if (error.status !== 401) return false;
  return (error.data as ErrorData).error === "Email not confirmed";
}

interface LoginEmail {
  email: string;
}

interface LoginCredentials extends LoginEmail {
  password: string;
}

interface LoginProps {
  login: (credentials: LoginCredentials) => Promise<unknown>;
  userLoginError: FetchBaseQueryError | SerializedError | undefined;
  userUpdating: boolean;
}

function Login({ login, userLoginError, userUpdating }: LoginProps) {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await login({ email, password });
  };

  let error = "";

  if (isEmailNotConfirmedError(userLoginError))
    error = "Please confirm your email address before you log in.";
  else if (userLoginError) error = "Incorrect username and/or password.";

  return (
    <Form style={{ maxWidth: "400px" }} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="userEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          autoComplete="email"
          placeholder="Email address"
          readOnly={userUpdating}
          onChange={(e) => setEmail(e.target.value.trim())}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="userPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          readOnly={userUpdating}
          onChange={(e) => setPassword(e.target.value.trim())}
        />
        <div className="mt-2">
          <ForgotPasswordLink email={email} />
        </div>
      </Form.Group>
      <Form.Group
        className="d-flex flex-row-reverse mb-3"
        controlId="submitButton">
        <div>
          <Button variant="primary" type="submit" disabled={userUpdating}>
            {userUpdating ? (
              <Spinner size={16} variant="light" message="Logging in..." />
            ) : (
              "Login"
            )}
          </Button>
        </div>
        <div className="mx-2 align-self-center text-danger">{error}</div>
      </Form.Group>
    </Form>
  );
}

export default Login;

export type { LoginProps };
