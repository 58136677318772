import { HandCardsSvg } from "../../utils/cards";
import type { HandSquareData } from "../../utils/handspace/handspace.types";

import {
  FieldProps,
  GridComponentProps,
  GridHandGroupSquare
} from "./grid.types";

// import { EmptySquareRect, EmptySquareText } from "./emptySquare";
import {
  Dimensions,
  SquareRangeScaling,
  SquareRangeScalingBasis
} from "../../features/view-controls/viewSlice";

function actionRangeData<T extends GridComponentProps>(gcProps: T): T {
  if (gcProps.rangeAction == null) return gcProps;
  if (gcProps.rangeAction.index >= gcProps.gridData.actionRanges.length)
    return gcProps;
  const actionRange = gcProps.gridData.actionRanges[gcProps.rangeAction.index];
  // const dpp = dataProvidingPlayer(true, gcProps.node, gcProps.player);
  const gridData = { ...gcProps.gridData, range: actionRange };
  return { ...gcProps, gridData };
}

function heightForRect(
  fullHeight: number,
  rangeValue: number,
  squareRangeScaling: SquareRangeScaling
) {
  if (squareRangeScaling !== SquareRangeScaling.HEIGHT) return fullHeight;

  return rangeValue * fullHeight;
}

function isRangeBelowCutoff(range: number) {
  return range === 0;
}

function fontSizeForDetails(
  _targetLineHeight: number,
  dimensions: Dimensions,
  fraction: number,
  numberOfRows: number,
  maxNumberOfChars: number
) {
  const lineHeight = (dimensions.height * fraction) / numberOfRows;
  // characters are on avg 70% as wide as they are high
  const lineWidth = dimensions.width / (maxNumberOfChars * 0.7);
  return Math.min(lineHeight, lineWidth);
}

function rescaleRangeValue(range: number, base: number) {
  if (base === 1) return range;
  return range / base;
}

function rescaleRangeToBasis(
  range: number[],
  squareRangeScalingBasis: SquareRangeScalingBasis
) {
  if (squareRangeScalingBasis !== SquareRangeScalingBasis.MAX) return range;
  const maxValue = Math.max(...range);
  return range.map((v) => v / maxValue);
}

function textStyleForNumbers(
  targetFontSize: number,
  totalDimensions: Dimensions,
  precision: number
) {
  // for numbers, find a font size that works for all details configurations
  const dimensions = {
    height: totalDimensions.height / 3,
    width: totalDimensions.width / 3
  };
  const fontSize = fontSizeForDetails(
    targetFontSize,
    dimensions,
    0.75,
    1,
    precision + 1
  );
  return { fontSize: `${fontSize}px` };
}

interface DetailsGridFieldProps<T extends HandSquareData> extends FieldProps {
  bgFunc: (square: T) => JSX.Element;
  row: T[];
  rowIndex: number;
  totalDimensions: Dimensions;
}

function DetailsGridRow<T extends HandSquareData>({
  bgFunc,
  fieldDimensions,
  fontSizes,
  row,
  rowIndex
}: DetailsGridFieldProps<T>) {
  const stroke = "rgba(100, 100, 100, 0.7)";
  const cardFontSize = fontSizeForDetails(
    fontSizes.details.cards,
    fieldDimensions,
    0.33,
    1,
    5 // two cards + space
  );
  const cardStyle = { fontSize: `${cardFontSize}px` };
  const rowSquares = row.map((v) => {
    return (
      <g
        className="hand-details"
        key={v.colIndex}
        transform={`translate(${fieldDimensions.width * v.colIndex})`}>
        <HandGridSquareBackground
          fieldDimensions={fieldDimensions}
          fontSizes={fontSizes}
          isOnDiagonal={false}
          isForDetails={true}
          rootRangeMean={1}
        />
        {bgFunc(v)}
        <text
          className="details-cards"
          dx={5}
          dy={cardFontSize}
          fill="#FFF"
          style={cardStyle}
          textAnchor="start">
          <HandCardsSvg cards={v.hand} />
        </text>
        <rect
          className="square-border"
          fillOpacity={0}
          height={fieldDimensions.height}
          width={fieldDimensions.width}
          stroke={stroke}
          strokeWidth="0.2"
        />
      </g>
    );
  });
  return (
    <g
      key={rowIndex}
      transform={`translate(0, ${fieldDimensions.height * rowIndex})`}>
      {rowSquares}
    </g>
  );
}

interface HandGridSquareBackgroundProps extends FieldProps {
  rootRangeMean: number;
  isOnDiagonal: boolean;
  isForDetails?: boolean;
}

function HandGridSquareBackground(props: HandGridSquareBackgroundProps) {
  const fieldDimensions = props.fieldDimensions;
  const fill = "rgb(255, 255, 255)";
  const opacity = props.isOnDiagonal ? 0.4 : props.isForDetails ? 0.5 : 0.3;
  return (
    <rect
      fill={fill}
      fillOpacity={opacity}
      height={fieldDimensions.height}
      width={fieldDimensions.width}
    />
  );
}

interface HandGridRowBackgroundProps extends FieldProps {
  row: GridHandGroupSquare[];
  rowIndex: number;
}

function HandGridRowBackgrounds({
  fieldDimensions,
  fontSizes,
  row,
  rowIndex
}: HandGridRowBackgroundProps) {
  const rowSquares = row.map((v, i) => (
    <g key={i} transform={`translate(${fieldDimensions.width * i})`}>
      <HandGridSquareBackground
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        isOnDiagonal={i === rowIndex}
        rootRangeMean={v.rootRangeMean}
      />
    </g>
  ));
  return (
    <g
      key={rowIndex}
      transform={`translate(0, ${fieldDimensions.height * rowIndex})`}>
      {rowSquares}
    </g>
  );
}

interface HandGridBackgroundProps extends FieldProps {
  squares: GridHandGroupSquare[][];
}

function HandGridBackground({
  fieldDimensions,
  fontSizes,
  squares
}: HandGridBackgroundProps) {
  const svgRowBorders = squares.map((r, i) => {
    return (
      <HandGridRowBackgrounds
        key={`${i}-border`}
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        row={r}
        rowIndex={i}
      />
    );
  });
  return <g className="borders">{svgRowBorders}</g>;
}

interface SummaryGridHandGroup {
  borderColor?: string;
  squareName: string;
  rangeMean: number;
  rootRangeMean: number;
}

type SummaryEltFunc<T> = (summary: T) => JSX.Element | null;

interface SummaryGridFieldProps<T extends SummaryGridHandGroup>
  extends FieldProps {
  bgFunc: SummaryEltFunc<T>;
  colIndex: number;
  labelFunc: SummaryEltFunc<T>;
  summary: T;
}

function SummaryGridField<T extends SummaryGridHandGroup>(
  props: SummaryGridFieldProps<T>
) {
  const { colIndex, fieldDimensions, summary } = props;
  const { width: rectWidth, height: rectHeight } = fieldDimensions;
  const x = rectWidth * 0.1;
  const dy = rectHeight < 45 ? rectHeight * 0.45 : rectHeight * 0.3;
  const textAnchor = "start";

  const label = props.labelFunc(summary);

  if (isRangeBelowCutoff(summary.rootRangeMean)) {
    return (
      <g className="hand-grid" transform={`translate(${rectWidth * colIndex})`}>
        <text className="square-name" x={x} dy={dy} textAnchor={textAnchor}>
          {summary.squareName}
        </text>
      </g>
    );
  }

  return (
    <g className="hand-grid" transform={`translate(${rectWidth * colIndex})`}>
      {props.bgFunc(summary)}
      <text className="square-name" x={x} dy={dy} textAnchor={textAnchor}>
        {summary.squareName}
      </text>
      {label}
    </g>
  );
}

interface SummaryGridRowProps<T extends SummaryGridHandGroup>
  extends FieldProps {
  bgFunc: SummaryEltFunc<T>;
  labelFunc: SummaryEltFunc<T>;
  rowIndex: number;
  rowSummaries: T[];
}

function SummaryGridRow<T extends SummaryGridHandGroup>(
  props: SummaryGridRowProps<T>
) {
  const { rowIndex, rowSummaries, fieldDimensions, fontSizes } = props;
  const { height: rectHeight } = fieldDimensions;
  const squares = rowSummaries.map((s, i) => {
    return (
      <SummaryGridField
        key={i}
        bgFunc={props.bgFunc}
        colIndex={i}
        fontSizes={fontSizes}
        labelFunc={props.labelFunc}
        summary={s}
        fieldDimensions={fieldDimensions}
      />
    );
  });
  return <g transform={`translate(0, ${rectHeight * rowIndex})`}>{squares}</g>;
}

export {
  actionRangeData,
  heightForRect,
  isRangeBelowCutoff,
  fontSizeForDetails,
  rescaleRangeValue,
  rescaleRangeToBasis,
  textStyleForNumbers
};

export { DetailsGridRow, HandGridBackground, SummaryGridField, SummaryGridRow };
export type { SummaryGridHandGroup };
