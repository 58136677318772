import { Dispatch } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  AnyAction,
  PayloadAction,
  ThunkDispatch,
  createSlice
} from "@reduxjs/toolkit";

import type { SpotInfo } from "../../services/piosaasTreeApi";
import { buildNodeTree, NodeTree } from "../../utils/nodespace";
import { cardsCompressAndSplit } from "../../utils/cards";

type SpotState = { spot: string; data: SpotInfo; nodeTree: NodeTree };

type RootStateWithSpotsState = {
  spotState: SpotState;
};

const spotSlice = createSlice({
  name: "spotState",
  initialState: {} as SpotState,
  reducers: {
    setSpot(state, action: PayloadAction<string>) {
      state.spot = action.payload;
    },
    setSpotData(state, action: PayloadAction<SpotInfo>) {
      if (action.payload == null) return;
      state.data = action.payload;
      const rootStreet =
        !action.payload.boards || action.payload.boards.length === 0
          ? 0
          : cardsCompressAndSplit(action.payload.boards[0]).length;
      state.nodeTree = buildNodeTree(
        action.payload.all_lines,
        action.payload.pot,
        rootStreet
      );
    }
  }
});

export const { setSpot, setSpotData } = spotSlice.actions;

export type { RootStateWithSpotsState, SpotInfo, SpotState };

export type SpotStateDispatch = Dispatch<AnyAction> &
  ThunkDispatch<RootStateWithSpotsState, null, AnyAction>;

export const useSpotStateDispatch = () => useDispatch<SpotStateDispatch>();
export const useSpotStateSelector: TypedUseSelectorHook<RootStateWithSpotsState> =
  useSelector;
export default spotSlice.reducer;
