import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type IUser = {
  userId: string;
  displayName: string;
};
// Define a service using a base URL and expected endpoints
type UploadSpotsArgs = {
  spotName: string;
  spotFiles: string[];
};
export const piosaasStorageApi = createApi({
  reducerPath: "piosaasStorageApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/storage/" }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    submitSpots: builder.mutation({
      query: (spotData: UploadSpotsArgs) => {
        return {
          url: "upload/spots",
          method: "POST",
          body: spotData
        };
      }
    }),
    testUser: builder.mutation<IUser, string>({
      query: (displayName: string) => {
        return {
          url: "users/test",
          method: "POST",
          body: { displayName }
        };
      }
    })
  })
});

export const { useSubmitSpotsMutation, useTestUserMutation } =
  piosaasStorageApi;
export type { IUser };
