import { Dispatch } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  AnyAction,
  PayloadAction,
  ThunkDispatch,
  createSlice
} from "@reduxjs/toolkit";

type Dimensions = {
  width: number;
  height: number;
};

type DisplayOptions =
  | "EV"
  | "EQ"
  | "Strat"
  | "Strat+EV"
  | "Range"
  | "Action+Range";
type TablePosition = "OOP" | "IP";
type FocusLocation = "board/controls" | "grid" | "tree";

type FontSizes = {
  grid: {
    text: string;
    squareName: string;
  };
  details: {
    text: number;
    cards: number;
    strategyFontSize: number;
  };
  legend: {
    strategyFontSize: number;
  };
};

type RangeAction = {
  action: string;
  code: string;
  index: number;
};

enum SquareRangeScaling {
  NONE,
  HEIGHT,
  ALPHA
}

type SquareRangeScalingString = keyof typeof SquareRangeScaling;

enum SquareRangeScalingBasis {
  VALUE,
  MAX
}

type SquareDisplayOptions = {
  squareRangeScaling: SquareRangeScaling;
  squareRangeScalingBasis: SquareRangeScalingBasis;
};

type ClickLocation = { x: number; y: number };

type SquareType = {
  handGroup: string | null;
  location?: ClickLocation;
  source: string | null;
};

type ViewControlsState = {
  display: DisplayOptions;
  focus: FocusLocation;
  fontSizes: FontSizes;
  player: TablePosition;
  rangeAction?: RangeAction;
  square: SquareType;
  squareDisplay: SquareDisplayOptions;
  viewportDimensions: Dimensions;
};

type RootStateWithViewControls = { viewControls: ViewControlsState };

const tablePosition: TablePosition[] = ["OOP", "IP"];
const radioDisplayOptions: DisplayOptions[] = [
  "Strat",
  "Strat+EV",
  "Range",
  "EV",
  "EQ"
];
const scaleOptions: SquareRangeScalingString[] = ["NONE", "HEIGHT"];

const SetSquareSource = {
  MOUSEOVER: "mouseover",
  CLICK: "click"
};

function isBelowSm(viewportDimensions: Dimensions) {
  return viewportDimensions.width < 576;
}

function isBelowLg(viewportDimensions: Dimensions) {
  return viewportDimensions.width < 992;
}

const FONTS_SMALL: FontSizes = {
  grid: {
    text: "13px",
    squareName: "11px"
  },
  details: {
    text: 32,
    cards: 20,
    strategyFontSize: 11
  },
  legend: {
    strategyFontSize: 11
  }
};

const FONTS_MEDIUM: FontSizes = {
  grid: {
    text: "16px",
    squareName: "14px"
  },
  details: {
    text: 32,
    cards: 20,
    strategyFontSize: 14
  },
  legend: {
    strategyFontSize: 9
  }
};

const FONTS_LARGE: FontSizes = {
  grid: {
    text: "14px",
    squareName: "12px"
  },
  details: {
    text: 24,
    cards: 20,
    strategyFontSize: 16
  },
  legend: {
    strategyFontSize: 12
  }
};

const viewControlsSlice = createSlice({
  name: "viewControls",
  initialState: {
    display: radioDisplayOptions[0],
    focus: "tree",
    fontSizes: FONTS_SMALL,
    player: tablePosition[0],
    square: { handGroup: null, location: undefined, source: null },
    rangeAction: undefined,
    squareDisplay: {
      squareRangeScaling: SquareRangeScaling.HEIGHT,
      squareRangeScalingBasis: SquareRangeScalingBasis.MAX
    },
    viewportDimensions: { width: 800, height: 800 }
  } as ViewControlsState,
  reducers: {
    onNodeChanged(state, action: PayloadAction<void>) {
      if (state.display !== "Action+Range") return;
      state.rangeAction = undefined;
      state.display = "Range";
    },
    setDisplay(state, action: PayloadAction<DisplayOptions>) {
      state.display = action.payload;
    },
    setFocus(state, action: PayloadAction<FocusLocation>) {
      state.focus = action.payload;
    },
    setPlayer(state, action: PayloadAction<TablePosition>) {
      state.player = action.payload;
    },
    setRangeAction(state, action: PayloadAction<RangeAction>) {
      state.rangeAction = action.payload;
      state.display = "Action+Range";
    },
    setSquare(state, action: PayloadAction<SquareType>) {
      const currentSquareFromClick =
        state.square.source === SetSquareSource.CLICK;
      const newSquareFromClick =
        action.payload.source === SetSquareSource.CLICK;
      if (
        state.square.handGroup != null &&
        currentSquareFromClick &&
        !newSquareFromClick
      )
        return; // Click has higher precedence

      if (
        state.square.handGroup === action.payload.handGroup &&
        currentSquareFromClick &&
        newSquareFromClick
      ) {
        // Treat a click as a toggle
        state.square.source = SetSquareSource.MOUSEOVER;
        return;
      }

      const treatAsToggle = isBelowSm(state.viewportDimensions);
      if (treatAsToggle && state.focus === "tree") {
        // Do not switch until focus is changed
        return;
      }

      state.square = action.payload;
    },
    setSquareRangeScaling(state, action: PayloadAction<SquareRangeScaling>) {
      state.squareDisplay.squareRangeScaling = action.payload;
    },
    setSquareRangeScalingBasis(
      state,
      action: PayloadAction<SquareRangeScalingBasis>
    ) {
      state.squareDisplay.squareRangeScalingBasis = action.payload;
    },
    setViewportDimensions(state, action: PayloadAction<Dimensions>) {
      state.viewportDimensions = action.payload;
      if (isBelowSm(state.viewportDimensions)) {
        state.fontSizes = FONTS_SMALL;
      } else {
        if (isBelowLg(state.viewportDimensions)) state.fontSizes = FONTS_MEDIUM;
        else state.fontSizes = FONTS_LARGE;
      }
    }
  }
});

export const {
  onNodeChanged,
  setDisplay,
  setFocus,
  setPlayer,
  setRangeAction,
  setSquare,
  setSquareRangeScaling,
  setSquareRangeScalingBasis,
  setViewportDimensions
} = viewControlsSlice.actions;

export {
  SetSquareSource,
  SquareRangeScaling,
  SquareRangeScalingBasis,
  radioDisplayOptions,
  isBelowLg,
  isBelowSm,
  tablePosition,
  scaleOptions
};

export type {
  Dimensions,
  DisplayOptions,
  FocusLocation,
  FontSizes,
  TablePosition,
  RootStateWithViewControls,
  RangeAction,
  SquareRangeScalingString,
  SquareType,
  SquareDisplayOptions,
  ViewControlsState,
  ClickLocation
};

export type ViewControlsDispatch = Dispatch<AnyAction> &
  ThunkDispatch<RootStateWithViewControls, null, AnyAction>;

export const useViewControlsDispatch = () =>
  useDispatch<ViewControlsDispatch>();

export const useViewControlsSelector: TypedUseSelectorHook<RootStateWithViewControls> =
  useSelector;

export default viewControlsSlice.reducer;
