import { useEffect, useState } from "react";

// From https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
/* eslint-disable */
function debounce(func: Function, wait: number, immediate = false) {
  let timeout: NodeJS.Timeout | undefined;
  return function (this: any, ...args: any) {
    const context: any = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}
/* eslint-enable */

// From https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export function useWindowSize(delay = 0) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    const debouncedHandleResize = debounce(handleResize, delay);
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [delay]);

  return windowDimensions;
}
