import Card from "react-bootstrap/Card";

import type { SpotState } from "../../services/piosaasTreeApi";
import { CommunityCards, cardsCompress } from "../../utils/cards";
import { Link } from "../../utils/nav";
import math from "../../utils/math";

function RandomBoard({ spotBoard }: { spotBoard: SpotState }) {
  const spot = spotBoard.spot;
  const index = math.randomUpTo(spotBoard.boards.length);
  const community = spotBoard.boards[index];

  const isPreflop =
    community === null || community === undefined || community.trim() === "";
  return (
    <Link
      className="no-decor"
      to={`/board/pack/${spot}/${cardsCompress(community)}`}>
      <Card style={{ minWidth: "16rem" }}>
        <Card.Body>
          <Card.Title>
            {spot /* <b>{spot["hero"]}</b> vs. <b>{spot["villain"]}</b>{" "} */}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <div>
              <b>?</b> <span className="fw-normal">table</span>
            </div>
            <div>
              <b>?</b> <span className="fw-normal">stacks deep</span>
            </div>
            <div>
              <b>?</b> <span className="fw-normal">pot</span>
            </div>
          </Card.Subtitle>
          <Card.Text>
            {isPreflop ? "Preflop" : <CommunityCards cards={community} />}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}
export default RandomBoard;
