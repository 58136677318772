// Partially following https://ui.dev/react-router-protected-routes-authentication/
import React, { Dispatch, Fragment, SetStateAction } from "react";
//import { Navigate, useLocation } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import Login from "./Login";
import type { LoginProps } from "./Login";
import Register from "./Register";
import type { RegisterProps } from "./Register";
import useUser from "./useUser";
import { CenteredSpinner } from "../../utils";
import { useNavigate } from "react-router-dom";

interface PropsWithToggle<T> {
  toggleLoginType: Dispatch<SetStateAction<LoginFormType>>;
  passThroughProps: T;
}

function RequireUserRegister(props: PropsWithToggle<RegisterProps>) {
  return (
    <>
      <Row>
        <Col md={8} lg={6}>
          <h2 className="fs-4">Create an account</h2>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <Register {...props.passThroughProps} />
        </Col>
      </Row>
      <Row>
        <LoginLink toggleLoginType={props.toggleLoginType} />
      </Row>
    </>
  );
}

function RequireUserLogin(props: PropsWithToggle<LoginProps>) {
  return (
    <>
      <Row>
        <Col md={8} lg={6}>
          <h2 className="fs-4">Welcome back to PioSOLVER</h2>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <Login {...props.passThroughProps} />
        </Col>
      </Row>
      <Row>
        <RegisterLink toggleLoginType={props.toggleLoginType} />
      </Row>
    </>
  );
}

type LoginFormType = "Login" | "Register";
interface RequireUserProps {
  loginProps: LoginProps;
  loginType: LoginFormType;
  registerProps: RegisterProps;
}

function RequireUserForms({
  loginProps,
  loginType,
  registerProps
}: RequireUserProps) {
  const [toggleLoginType, setToggleLoginType] = React.useState(loginType);

  if (toggleLoginType === "Register") loginProps.userLoginError = undefined;
  else registerProps.userRegisterError = undefined;

  return (
    <div className="mx-md-3">
      <h1 style={{ width: 360, fontSize: "xxx-large" }}>PioCloud</h1>
      {toggleLoginType === "Register" ? (
        <RequireUserRegister
          passThroughProps={registerProps}
          toggleLoginType={setToggleLoginType}
        />
      ) : (
        <RequireUserLogin
          passThroughProps={loginProps}
          toggleLoginType={setToggleLoginType}
        />
      )}
    </div>
  );
}

type ForgotPasswordLinkComp = {
  email?: string;
};

function ForgotPasswordLink({ email }: ForgotPasswordLinkComp) {
  const navigate = useNavigate();
  return (
    <Button
      className="border-0 p-0"
      size="sm"
      variant="link"
      onClick={() => navigate(`/forgot?email_from_login=${email ?? ""}`)}>
      Forgot your password?
    </Button>
  );
}
type LoginTypeLinkComp = {
  toggleLoginType: Dispatch<SetStateAction<LoginFormType>>;
};
function RegisterLink({ toggleLoginType }: LoginTypeLinkComp) {
  const buttonFontSizeStyle = { fontSize: "var(--bs-btn-font-size)" };
  return (
    <div className="d-flex mt-1">
      <div className="text-muted btn-sm" style={buttonFontSizeStyle}>
        Need an account?
      </div>
      <Button
        className="border-0 py-0"
        onClick={() => toggleLoginType("Register")}
        size="sm"
        variant="link">
        Register
      </Button>
    </div>
  );
}
function LoginLink({ toggleLoginType }: LoginTypeLinkComp) {
  const buttonFontSizeStyle = { fontSize: "var(--bs-btn-font-size)" };
  return (
    <div className="d-flex mt-1">
      <div className="text-muted btn-sm" style={buttonFontSizeStyle}>
        Already have an account?
      </div>
      <Button
        className="border-0 py-0"
        onClick={() => toggleLoginType("Login")}
        size="sm"
        variant="link">
        Sign in
      </Button>
    </div>
  );
}

const canAuthenticate = true;

type ChildrenProps = { children?: JSX.Element | JSX.Element[] };

function HideIfNoUser({ children }: ChildrenProps) {
  const { user, userError, userLoading } = useUser();

  if (!canAuthenticate) return <>{children}</>;

  if (userLoading) return null;
  if (userError) return null;
  return user != null && user.email != null ? <>{children}</> : null;
}

function RequireUser({ children }: ChildrenProps) {
  const {
    user,
    userError,
    userLoading,
    userUpdating,
    userLoginError,
    userRegisterError,
    login,
    register
  } = useUser();
  //   const location = useLocation();
  if (!canAuthenticate) return <>{children}</>;

  if (userLoading) return <DataLoading source="user" />;

  const loginProps = {
    login,
    userLoginError,
    userUpdating
  };

  const registerProps = {
    register,
    userRegisterError,
    userUpdating
  };

  //User registered incorrectly but went back and logged in correctly
  //Error still exists in userRegisterError but the user was set
  if (userRegisterError && user == null) {
    return (
      <RequireUserForms
        loginProps={loginProps}
        registerProps={registerProps}
        loginType={"Register"}
      />
    );
  }

  // User is not authenticated
  if (
    (userError && (userError as FetchBaseQueryError).status === 401) ||
    (!userRegisterError && userError)
  ) {
    return (
      <RequireUserForms
        loginProps={loginProps}
        registerProps={registerProps}
        loginType={"Login"}
      />
    );
  }
  // return (user != null && user.email != null)
  //   ? children
  //   : <Navigate to="/login" replace state={location.pathname} />;

  // Just show the login page if not logged in
  return user != null && user.email != null ? (
    <>{children}</>
  ) : (
    <RequireUserForms
      loginProps={loginProps}
      registerProps={registerProps}
      loginType={"Login"}
    />
  );
}

interface DataLoadingProps {
  /** The data source that is loading */
  source?: string;
}

function DataLoading({ source }: DataLoadingProps) {
  const msg = source ? `Loading ${source}` : undefined;
  return (
    <div className="content-area">
      <CenteredSpinner message={msg} />
    </div>
  );
}

export default RequireUser;
export { DataLoading, HideIfNoUser, useUser, ForgotPasswordLink };
