import { scaleDiverging } from "d3-scale";
import { color } from "d3-color";
import { interpolateRgbBasis } from "d3-interpolate";

import { SpotInfo } from "../../services/piosaasTreeApi";

import { DisplayOptions, TablePosition } from "../view-controls/viewSlice";
import type {
  GridData,
  GridDataProps,
  IScale,
  NodeInfo
} from "./gridData.types";

// Translation of PioViewer code
class GUIColor {
  rgb: [number, number, number];

  constructor(rgb: [number, number, number]) {
    this.rgb = rgb;
  }

  get color() {
    return `rgb(${this.rgb[0]}, ${this.rgb[1]}, ${this.rgb[2]})`;
  }

  get colorObject() {
    return color(this.color);
  }
}

const ev0 = new GUIColor([237, 28, 36]);
const ev1 = new GUIColor([255, 127, 39]);
const ev2 = new GUIColor([255, 242, 0]);
const ev3 = new GUIColor([101, 230, 29]);
const ev4 = new GUIColor([34, 177, 76]);
const colors = [ev0.color, ev1.color, ev2.color, ev3.color, ev4.color];
const interpolateColorMap = interpolateRgbBasis(colors);

function prepareData(data: string, scale = 1.0) {
  return data.split(" ").map((s) => +s * scale);
}

function prepareDataStack(data: string[]) {
  return data.map((d) => d.split(" ").map((s) => +s));
}

function rangeForPlayerData(player: TablePosition, data: NodeInfo) {
  const rangeString =
    player === "IP"
      ? data.displayValues.range_IP.split("\n")[0]
      : data.displayValues.range_OOP.split("\n")[0];
  return prepareData(rangeString);
}
function rootRangeForPlayerSpotInfo(player: TablePosition, spotInfo: SpotInfo) {
  const range = player === "IP" ? spotInfo.range_IP : spotInfo.range_OOP;
  return range;
}

function strategyForCurrentAction(data: NodeInfo) {
  if (data.displayValues.strategy == null) return [[]];

  return prepareDataStack(data.displayValues.strategy.split("\n"));
}

function childrenEvsForCurrentAction(data: NodeInfo): number[][] {
  if (data.displayValues.strategy_ev == null) return [[]];

  return data.displayValues.strategy_ev.map((childEv) => prepareData(childEv));
}

function valueStringForPlayerDisplay(
  player: TablePosition,
  display: DisplayOptions,
  data: NodeInfo
) {
  if (display === "EQ") {
    if (player === "OOP") return data.displayValues.eq_OOP.split("\n")[0];

    return data.displayValues.eq_IP.split("\n")[0];
  }
  if (player === "OOP") return data.displayValues.ev_OOP.split("\n")[0];

  return data.displayValues.ev_IP.split("\n")[0];
}

function valuesForPlayerMatchups(player: TablePosition, data: NodeInfo) {
  if (player === "OOP")
    return prepareData(data.displayValues.ev_OOP.split("\n")[1], 1.0);

  return prepareData(data.displayValues.ev_IP.split("\n")[1], 1.0);
}

function valuesForPlayerDisplay(
  player: TablePosition,
  display: DisplayOptions,
  data: NodeInfo
) {
  const valueString = valueStringForPlayerDisplay(player, display, data);

  if (display === "EV") return prepareData(valueString, 1.0);

  return prepareData(valueString, 100.0);
}

// function evColorScaleForGrid(
//   node: Node,
//   player: TablePosition,
//   evRescale: EvScaling
// ) {
//   //from EQEVLegend
//   let bounds = [0, 50, 100];
//   bounds = rescaleBounds(node, bounds, PotContrib[player], evRescale);

//   const colorScale = scaleDiverging<string>()
//     .domain(bounds)
//     .interpolator(interpolateColorMap);
//   return colorScale as IScale;
// }

function colorScaleForBounds(bounds: number[]) {
  const colorScale = scaleDiverging<string>()
    .domain(bounds)
    .interpolator(interpolateColorMap);
  return colorScale as IScale;
}

function eqColorScaleForGrid() {
  const bounds = [0, 50, 100];
  return colorScaleForBounds(bounds);
}

function actionRangeData(frequencies: number[][], range: number[]): number[][] {
  return frequencies.map((f) => range.map((r, i) => f[i] * r));
}

function gridDataFromProps(props: GridDataProps): GridData {
  const eqColorScale = eqColorScaleForGrid();
  const frequencies = strategyForCurrentAction(props.data);
  const range = rangeForPlayerData(props.player, props.data);
  const actionRanges = actionRangeData(frequencies, range);
  return {
    actionRanges,
    childrenEvs: childrenEvsForCurrentAction(props.data),
    eq: valuesForPlayerDisplay(props.player, "EQ", props.data),
    eqColorScale,
    ev: valuesForPlayerDisplay(props.player, "EV", props.data),
    frequencies,
    range,
    rootRange: rootRangeForPlayerSpotInfo(props.player, props.spotInfo),
    matchups: valuesForPlayerMatchups(props.player, props.data)
  };
}

export { colorScaleForBounds, gridDataFromProps };
