import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardRank, CardSuit } from "../../../utils/cardTypes";

import {
  cardsCompressAndSplit,
  cardsInDeck,
  PlayingCard,
  ranks as cardRanks,
  suits as cardSuits
} from "../../../utils/cards";
import { RunoutsChooserProps, streetsToCardIndex } from "./deal.types";

type SelectionCardProps = {
  card: string;
  onCardClick: (card: string) => void;
  show: boolean;
  isChosenCard: boolean;
  chosenCard?: string;
};

function SelectionCard({
  card,
  onCardClick,
  show,
  isChosenCard
}: SelectionCardProps) {
  if (!isChosenCard && !show) return null;
  const suit = card[1] as CardSuit;
  const style = isChosenCard ? `selected suit-${suit}-borderColor` : "normal";
  return (
    <div
      onClick={() => onCardClick(card)}
      className={`selection-card-${style}`}>
      <PlayingCard value={card} isSelected={isChosenCard} />
    </div>
  );
}

function RunoutsInlineLong({
  board,
  streetString,
  onCardSelection
}: RunoutsChooserProps) {
  const cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const remainingCards = cardsInDeck(board, cardIndex);
  const ranks = cardRanks.split("") as CardRank[];
  const cardIsChosen = boardArray && boardArray[cardIndex];
  const chosenCard = cardIsChosen ? boardArray[cardIndex] : "";

  const mapRankToCols = (rank: CardRank, chosenCard: string) => {
    return cardSuits.map((suit) => (
      <Col className="gx-0" xs={3} key={`col-${suit}`}>
        <SelectionCard
          card={`${rank}${suit}`}
          show={remainingCards[suit].includes(rank)}
          isChosenCard={boardArray[cardIndex] === `${rank}${suit}`}
          onCardClick={onCardSelection}
          chosenCard={chosenCard}
        />
      </Col>
    ));
  };

  const grid = ranks.map((rank) => (
    <Row key={`row-${rank}`}>{mapRankToCols(rank, chosenCard)}</Row>
  ));

  return (
    <div className="p-1" style={{ minWidth: "170px" }}>
      {grid}
    </div>
  );
}

function RunoutsInlineWide({
  board,
  streetString,
  onCardSelection
}: RunoutsChooserProps) {
  const cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const remainingCards = cardsInDeck(board, cardIndex);
  const ranks = cardRanks.split("") as CardRank[];
  const cardIsChosen = boardArray && boardArray[cardIndex];
  const chosenCard = cardIsChosen ? boardArray[cardIndex] : "";

  const mapSuitToRows = (suit: CardSuit, chosenCard: string) => {
    return ranks.map((rank) => (
      <div key={`col-${rank}`} className="d-table-cell">
        <SelectionCard
          card={`${rank}${suit}`}
          show={remainingCards[suit].includes(rank)}
          isChosenCard={boardArray[cardIndex] === `${rank}${suit}`}
          onCardClick={onCardSelection}
          chosenCard={chosenCard}
        />
      </div>
    ));
  };

  const grid = cardSuits.map((suit) => (
    <div key={`row-${suit}`} className="d-table-row">
      {mapSuitToRows(suit, chosenCard)}
    </div>
  ));

  return <div>{grid}</div>;
}

export { RunoutsInlineLong, RunoutsInlineWide, SelectionCard };
