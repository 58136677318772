import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { PlusSquareFill, Trash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import {
  useDeleteGrantMutation,
  useGrantsInfoQuery
} from "../../services/piosaasGrantsApi";
import { Spinner } from "../../utils";

function Grants() {
  return (
    <Tab.Container id="grants" defaultActiveKey="others">
      <Row>
        <Col sm={12} md={4} lg={3}>
          <h1 className="mb-0">Grants</h1>
          <div className="text-assistance mb-2">
            Spots and boards that have been shared
          </div>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="others">Shared with others</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="mine">Shared with me</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12} md={8} lg={9}>
          <GrantList />
        </Col>
      </Row>
    </Tab.Container>
  );
}

enum GrantListType {
  GRANTED_TO_YOU,
  GRANTED_TO_OTHERS
}

function GrantList() {
  const { data, isLoading, isFetching } = useGrantsInfoQuery();
  const [deleteGrant] = useDeleteGrantMutation();
  if (isLoading || isFetching) return <Spinner />;
  return (
    <Tab.Content>
      <Tab.Pane eventKey="others">
        <Granted
          grantedList={data?.grantor ?? []}
          grantListType={GrantListType.GRANTED_TO_OTHERS}
          deleteGrant={deleteGrant}
        />
      </Tab.Pane>
      <Tab.Pane eventKey="mine">
        <Granted
          grantedList={data?.recipient ?? []}
          grantListType={GrantListType.GRANTED_TO_YOU}
        />
      </Tab.Pane>
    </Tab.Content>
  );
}

interface GrantedProps {
  grantedList: string[];
  grantListType: GrantListType;
  deleteGrant?: (grant: string) => void;
}
interface GrantedItemProps extends GrantedProps {
  item: string;
  onItemClick: (item: string) => void;
}

function Granted(props: GrantedProps) {
  const title = getTitle(props.grantListType);

  return (
    <Card className="grant-card mt-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>{title}</span>
        {props.grantListType === GrantListType.GRANTED_TO_OTHERS ? (
          <Link to="/new/grant">
            <PlusSquareFill />
          </Link>
        ) : null}
      </Card.Header>
      <Card.Body>
        <GrantedList {...props} />
      </Card.Body>
    </Card>
  );
}
const getTitle = (grantListType: GrantListType) =>
  `Granted to ${getSuffix(grantListType)}`;

const getSuffix = (grantListType: GrantListType) =>
  grantListType === GrantListType.GRANTED_TO_YOU ? "you" : "others";

function GrantedList(props: GrantedProps) {
  const { grantedList, grantListType } = props;
  const onItemClick = (clickedItem: string) => {
    if (!props.deleteGrant) return;

    props.deleteGrant(clickedItem);
  };
  return (
    <>
      {grantedList.map((grantedItem) => {
        return grantListType === GrantListType.GRANTED_TO_YOU ? (
          <Row>
            <GrantedItemToYou
              onItemClick={onItemClick}
              item={grantedItem}
              {...props}
            />
          </Row>
        ) : (
          <Row>
            <GrantedItemToOthers
              onItemClick={onItemClick}
              item={grantedItem}
              {...props}
            />
          </Row>
        );
      })}
    </>
  );
}

function GrantedItemToYou(props: GrantedItemProps) {
  const spotName = props.item;
  return <Link to={`/board/pack/${spotName}/`}>{spotName}</Link>;
}
function GrantedItemToOthers(props: GrantedItemProps) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>{props.item}</span>
      <Trash
        width="18"
        height="18"
        onClick={() => {
          props.onItemClick(props.item);
        }}
      />
    </div>
  );
}

export default Grants;
