import { GridComponentProps, GridRows } from "./grid.types";
import handspace from "../../utils/handspace";
import { useUserPrefsContext } from "../../utils/preferences";
import math from "../../utils/math";
import type { NumberFormatter } from "../../utils/math";
import {
  SquareRangeScaling,
  SquareRangeScalingBasis
} from "../../features/view-controls/viewSlice";
import {
  HandGroupSquare,
  HandSquareValue
} from "../../utils/handspace/handspace.types";
import { getMixedColorUsingGradient } from "../../utils/nodespace/strategy-colors";
import type { FieldProps, SquareDetailsGridProps } from "./grid.types";
import {
  DetailsGridRow,
  SummaryGridRow,
  heightForRect,
  rescaleRangeValue,
  textStyleForNumbers
} from "./shared";
import type { SummaryGridHandGroup } from "./shared";

function RangeLegend(props: GridComponentProps) {
  return null;
}
interface HandGroupSquareSummary extends SummaryGridHandGroup {
  square: HandGroupSquare<number[]>;
}

interface RangeSquareSummaryProps extends FieldProps {
  formatter: NumberFormatter;
  summary: HandGroupSquareSummary;
  squareRangeScaling: SquareRangeScaling;
  rangeRescaleBasis: number;
}

function getRangeColorForValue(value: number, useOneColor: boolean) {
  return useOneColor
    ? "#ffff00"
    : getMixedColorUsingGradient(["#808080", "#ffff00"], value, false);
}
function isRangeBelowCutoff(range: number) {
  return range === 0;
}
function squareFillSummary(value: number, useOneColor: boolean) {
  if (isRangeBelowCutoff(value)) return "hsla(0, 0%, 30%, 0)";
  const color = getRangeColorForValue(value, useOneColor);
  return `${color}`;
}

interface RangeSummaryRectSliceProps extends RangeSquareSummaryProps {
  index: number;
  value: number;
  width: number;
}

function RangeSummaryRectSlice(props: RangeSummaryRectSliceProps) {
  const {
    index,
    fieldDimensions,
    width,
    value,
    squareRangeScaling,
    rangeRescaleBasis
  } = props;
  const { height: squareHeight } = fieldDimensions;
  const rangeScaled = rescaleRangeValue(value, rangeRescaleBasis);
  const height =
    squareRangeScaling === SquareRangeScaling.HEIGHT
      ? rangeScaled * squareHeight
      : fieldDimensions.height;
  const fill = squareFillSummary(
    value,
    squareRangeScaling === SquareRangeScaling.HEIGHT
  );
  return (
    <rect
      y={squareRangeScaling ? squareHeight - height : 0}
      x={width * index}
      fill={fill}
      height={height}
      stroke={fill}
      width={width}
    />
  );
}

function RangeSummaryHeightScaledRect(props: RangeSquareSummaryProps) {
  const { summary, fieldDimensions } = props;

  const { width } = fieldDimensions;

  const squareValues = summary.square.handSquareValues.filter((v) => !isNaN(v));
  squareValues.sort((a, b) => b - a);
  const sliceWidth = width;
  const slices = (
    <RangeSummaryRectSlice
      key={0}
      index={0}
      value={summary.rangeMean}
      width={sliceWidth}
      {...props}
    />
  );
  return <g>{slices}</g>;
}

function RangeSummaryBasicRect(props: RangeSquareSummaryProps) {
  const { summary, fieldDimensions } = props;

  const { width } = fieldDimensions;

  const squareValues = summary.square.handSquareValues.filter((v) => !isNaN(v));
  squareValues.sort((a, b) => b - a);
  const sliceWidth = width / squareValues.length;
  const slices = squareValues.map((v, i) => (
    <RangeSummaryRectSlice
      key={i}
      index={i}
      value={v}
      width={sliceWidth}
      {...props}
    />
  ));
  return <g>{slices}</g>;
}

function RangeSummaryRect(props: RangeSquareSummaryProps) {
  const { squareRangeScaling } = props;
  return squareRangeScaling === SquareRangeScaling.HEIGHT ? (
    <RangeSummaryHeightScaledRect {...props} />
  ) : (
    <RangeSummaryBasicRect {...props} />
  );
}

function RangeSummaryLabel(props: RangeSquareSummaryProps) {
  const { width: rectWidth, height: rectHeight } = props.fieldDimensions;
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;
  return (
    <text
      className="square-value"
      x={rectWidth * 0.95}
      dy={rectHeight * 0.8}
      textAnchor="end">
      {math.formattedFloat(
        props.summary.rangeMean,
        props.formatter,
        trailingZerosHidden
      )}
    </text>
  );
}

function rangeRows(props: GridComponentProps): GridRows {
  const { gridData, squareRangeScaling, squareRangeScalingBasis } = props;
  const rangedHandspace = handspace.rangedHandspace(
    gridData.rootRange,
    gridData.range
  );
  const values = gridData.range;
  const handSquareSummaries =
    rangedHandspace.mapValuesRows<HandGroupSquareSummary>(values, (r) => {
      return r.mapValues((v) => {
        return {
          squareName: v.squareName,
          rangeMean: v.rangeMean,
          rootRangeMean: v.rootRangeMean,
          square: v
        };
      });
    });
  const { fieldDimensions, fontSizes } = props;

  let rangeRescaleBasis = 1;
  if (
    squareRangeScaling === SquareRangeScaling.HEIGHT &&
    squareRangeScalingBasis === SquareRangeScalingBasis.MAX
  ) {
    rangeRescaleBasis = Math.max(
      ...handSquareSummaries.map((row) =>
        Math.max(...row.map((v) => v.rangeMean))
      )
    );
  }
  const precision = props.numberDisplayPrecision;
  const formatter = math.formatterForFloat(1.0, precision);

  const bgFunc = (summary: HandGroupSquareSummary) => (
    <RangeSummaryRect
      formatter={formatter}
      rangeRescaleBasis={rangeRescaleBasis}
      summary={summary}
      {...props}
    />
  );
  const labelFunc = (summary: HandGroupSquareSummary) => (
    <RangeSummaryLabel
      formatter={formatter}
      rangeRescaleBasis={rangeRescaleBasis}
      summary={summary}
      {...props}
    />
  );

  const rows = handSquareSummaries.map((s, i) => (
    <SummaryGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      labelFunc={labelFunc}
      rowIndex={i}
      rowSummaries={s}
    />
  ));
  return {
    rows,
    squares: handSquareSummaries
  };
}
//details

interface RangeDetailsSquareProps extends FieldProps {
  formatter: NumberFormatter;
  square: HandSquareValue;
  squareRangeScaling: SquareRangeScaling;
  rangeRescaleBasis: number;
  valueTextStyle: React.CSSProperties;
}

function squareFillValue(square: HandSquareValue, useOneColor: boolean) {
  if (isNaN(square.value)) return "hsla(0, 0%, 30%, 0)";
  const color = getRangeColorForValue(square.value, useOneColor);
  return `${color}`;
}

function RangeDetailsSquare(props: RangeDetailsSquareProps) {
  const {
    fieldDimensions,
    square,
    squareRangeScaling,
    rangeRescaleBasis,
    valueTextStyle
  } = props;
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;

  const valueIsNaN = isNaN(square.value);
  const fill = squareFillValue(
    square,
    squareRangeScaling === SquareRangeScaling.HEIGHT
  );
  //distance from bottom edge fieldDimensions.height * 0.05
  const dy = fieldDimensions.height - fieldDimensions.height * 0.05;
  const alpha = 1;
  const rangeScaled = rescaleRangeValue(square.value, rangeRescaleBasis);
  const { width: squareWidth, height: squareHeight } = fieldDimensions;
  const height = heightForRect(
    squareHeight,
    rangeScaled,
    props.squareRangeScaling
  );
  return (
    <>
      {valueIsNaN ? null : (
        <rect
          fill={fill}
          height={height}
          width={squareWidth}
          y={
            squareRangeScaling === SquareRangeScaling.HEIGHT
              ? squareHeight - height
              : 0
          }
        />
      )}
      <text
        className="details-value"
        x={fieldDimensions.width * 0.95}
        dy={dy}
        fill={`hsla(0, 0%, 5%, ${alpha})`}
        style={valueTextStyle}
        textAnchor="end">
        {valueIsNaN
          ? null
          : math.formattedFloat(
              square.value,
              props.formatter,
              trailingZerosHidden
            )}
      </text>
    </>
  );
}

function rangeSquareDetailsRows(props: SquareDetailsGridProps) {
  const {
    fontSizes,
    gridData,
    square,
    fieldDimensions,
    squareRangeScaling,
    squareRangeScalingBasis,
    width,
    height
  } = props;

  const handGroup = square.handGroup;
  if (handGroup == null) return null;

  const values = props.gridData.range;

  const precision = props.numberDisplayPrecision;
  const formatter = math.formatterForFloat(1.0, precision);
  const totalDimensions = { width, height };
  const valueTextStyle = textStyleForNumbers(
    fontSizes.details.text,
    totalDimensions,
    precision
  );

  const range = gridData.range;
  const rhs = handspace.rangedHandspace(gridData.rootRange, range);
  const handValueDetails = rhs.mapChunkedHandGroupDetails<HandSquareValue>(
    handGroup,
    values,
    (r) => r.mapValues((v) => v)
  );
  let rangeRescaleBasis = 1;
  if (
    squareRangeScaling === SquareRangeScaling.HEIGHT &&
    squareRangeScalingBasis === SquareRangeScalingBasis.MAX
  )
    rangeRescaleBasis = Math.max(...gridData.range);
  const bgFunc = (square: HandSquareValue) => (
    <RangeDetailsSquare
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      formatter={formatter}
      square={square}
      squareRangeScaling={squareRangeScaling}
      rangeRescaleBasis={rangeRescaleBasis}
      valueTextStyle={valueTextStyle}
    />
  );

  const svgRows = handValueDetails.map((row, i) => (
    <DetailsGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      row={row}
      rowIndex={i}
      totalDimensions={totalDimensions}
    />
  ));

  return svgRows;
}

export {
  RangeLegend,
  rangeRows,
  rangeSquareDetailsRows,
  getRangeColorForValue
};
