import { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { streetsToCardIndex } from ".";
import { DealCards } from "./deal-chooser";
// import { RunoutsChooserProps } from "../features/view-controls/deals/deal.types";
import { RunoutsInlineLong } from "./runouts";
import { cardsCompressAndSplit } from "../../../utils/cards";
import { StreetName } from "../../../utils/nodespace";

type Selector = {
  streetString: StreetName;
  selectedBoard: string;
  setSelectedBoard: (newBoard: string) => void;
  board: string;
};
const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  return `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}`;
};
const getPreviousStreet = (street: number) => {
  if (street <= 3) return 0; //minStreet, just in case
  return street - 1;
};
function RunoutsSelection({
  streetString,
  selectedBoard,
  setSelectedBoard,
  board
}: Selector) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBoard, setShow]);
  const runoutStreet = streetsToCardIndex[streetString.toLowerCase()];
  const cardIndex = runoutStreet - 1;
  const selectedBoardArray = cardsCompressAndSplit(selectedBoard);
  const cardIsChosen =
    Boolean(selectedBoardArray) && Boolean(selectedBoardArray[cardIndex]);
  const onCardSelection = (card: string) => {
    const newBoardArray = [...selectedBoardArray];
    newBoardArray[cardIndex] = card;
    setSelectedBoard(newBoardArray.join(""));
  };

  return (
    <OverlayTrigger
      trigger={["click"]}
      placement="bottom"
      show={show ? undefined : false}
      defaultShow={show}
      overlay={(props) => (
        <Tooltip
          className="position-absolute runout-change-popover"
          {...props}
          data-cy={`runouts-inline-${streetString}`}>
          <RunoutsInlineLong
            board={selectedBoard}
            streetString={streetString}
            onCardSelection={onCardSelection}
          />
        </Tooltip>
      )}>
      <Button
        className="btn btn-dark"
        style={{ backgroundColor: "black" }}
        type="button"
        size="sm"
        onClick={() => setShow(!show)}
        disabled={selectedBoardArray.length < getPreviousStreet(runoutStreet)}>
        {cardIsChosen ? (
          <DealCards board={selectedBoard} cardIndex={cardIndex} />
        ) : (
          capitalizeFirstLetter(streetString)
        )}
      </Button>
    </OverlayTrigger>
  );
}
export { RunoutsSelection };
