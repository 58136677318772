import math from "../math";
import { BetSizeDisplay } from "../preferences";

import type { Node, NodeText } from "./nodespace.types";

import { DealStrings, NodeTypes, baseStreetPotContribution } from "./nodespace";

enum DisplayStringLength {
  SHORT,
  DETAILS,
  FULL
}

function combineActionSizeText(text: string, sizeText: string | null) {
  if (!sizeText) return text;

  return `${text} ${sizeText}`;
}

function getShortText(action: string) {
  return action[0];
}

function getDetailsText(action: string) {
  if (action === "Call" || action === "Check" || action === "Fold")
    return action;
  return action[0];
}

function getFullText(action: string) {
  if (action === "r:0") return "root";
  return action;
}

function createNodeText(
  action: string,
  size: number | undefined | null,
  parentPot: number[],
  baseStreetContrib: number
) {
  if (Object.keys(DealStrings).includes(action)) return undefined;

  const shortText = getShortText(action);
  const detailsText = getDetailsText(action);
  const fullText = getFullText(action);
  const sizeChips = size ? `${size}` : null;
  const sizePercent = getPotPercentString(
    parentPot,
    size,
    action,
    baseStreetContrib
  );
  const sizeBoth = size ? `${sizeChips as string} (${sizePercent})` : null;

  return {
    DetailsTextSizeHide: detailsText,
    DetailsTextSizeChips: combineActionSizeText(detailsText, sizeChips),
    DetailsTextSizePercent: combineActionSizeText(detailsText, sizePercent),
    DetailsTextSizeBoth: combineActionSizeText(detailsText, sizeBoth),
    FullTextSizeHide: fullText,
    FullTextSizeChips: combineActionSizeText(fullText, sizeChips),
    FullTextSizePercent: combineActionSizeText(fullText, sizePercent),
    FullTextSizeBoth: combineActionSizeText(fullText, sizeBoth),
    ShortTextSizeHide: shortText,
    ShortTextSizeChips: combineActionSizeText(shortText, sizeChips),
    ShortTextSizePercent: combineActionSizeText(shortText, sizePercent),
    ShortTextSizeBoth: combineActionSizeText(shortText, sizeBoth)
  } as NodeText;
}

function prefixForLength(
  length: DisplayStringLength
): "Short" | "Details" | "Full" {
  switch (length) {
    case DisplayStringLength.SHORT:
      return "Short";
    case DisplayStringLength.DETAILS:
      return "Details";
    case DisplayStringLength.FULL:
      return "Full";
    default:
      return "Full";
  }
}

function displayStringForNode(
  node: Node,
  length: DisplayStringLength,
  sizeType: BetSizeDisplay
): string {
  const nodeText = nodeTextRaw(node);
  if (!nodeText) return "";
  const prefix = prefixForLength(length);
  const suffix = sizeType;

  return nodeText[`${prefix}TextSize${suffix}`];
}

function getPotPercentString(
  parentPot: number[],
  size: number | null | undefined,
  action: string,
  baseStreetContrib: number
) {
  if (!size) return "";
  const isRaise = action[0].toLocaleLowerCase() === "r";
  let sizeOverCall = size;
  let potSum = parentPot.reduce((a, b) => a + b);
  const potDiff = Math.abs(parentPot[0] - parentPot[1]);

  //raise
  if (isRaise) {
    const playerContrib = Math.min(parentPot[0], parentPot[1]);
    sizeOverCall = sizeOverCall - potDiff - (playerContrib - baseStreetContrib);
    potSum += potDiff;
  }

  const sizePercent = (sizeOverCall / potSum) * 100;
  const formatterForFloat = math.formatterForFloat(sizePercent, 3);
  const txt = `${math.formattedFloat(sizePercent, formatterForFloat, true)}%`;
  return txt;
}

function nodeTextRaw(node: Node): NodeText | undefined {
  const pot = node.pot;
  if (!node.parent)
    return createNodeText(node.action.action, node.action.size, pot, 0);

  if (node.type === NodeTypes.SPLIT) {
    return createNodeText(
      node.action.action,
      node.action.size,
      node.parent.pot,
      baseStreetPotContribution(node)
    );
  }
  const baseStreetContrib = baseStreetPotContribution(node.parent);
  return createNodeText(
    node.action.action,
    node.action.size,
    node.parent.pot,
    baseStreetContrib
  );
}

export { DisplayStringLength, displayStringForNode };
export { nodeTextRaw };
