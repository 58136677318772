import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NodeInfo } from "../features/grid";
import { nodeIdToSolverNodeId } from "../utils/solverUtil";

type SpotState = {
  boards: string[];
  spot: string;
};

type SpotsState = SpotState[];

type SpotInfo = {
  all_lines: string[];
  boards: string[];
  effective_stack: string;
  pot: number[];
  range_OOP: number[];
  range_IP: number[];
  flopSubset?: string;
};
type NodeInfoQueryArg = {
  board: string;
  node: string;
  rootBoard: string;
  spot: string;
};

export const piosaasTreeApi = createApi({
  reducerPath: "piosaasTreeApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/spots" }),
  tagTypes: [],
  keepUnusedDataFor: 60 * 30, //0.5h
  endpoints: (builder) => ({
    spots: builder.query<SpotsState, void>({
      query: () => {
        return {
          url: ""
        };
      }
    }),
    spotInfo: builder.query<SpotInfo, { spot?: string }>({
      query: (arg) => {
        const { spot } = arg;
        return {
          url: `/${spot ?? ""}`
        };
      }
    }),
    nodeInfo: builder.query<NodeInfo, NodeInfoQueryArg>({
      query: (arg) => {
        const { spot, rootBoard, node, board } = arg;
        return {
          url: `${spot}/${rootBoard}/${nodeIdToSolverNodeId(node, board)}`
        };
      }
    })
  })
});

export const { useSpotsQuery, useSpotInfoQuery, useNodeInfoQuery } =
  piosaasTreeApi;

export type { SpotInfo, SpotState, SpotsState };
