import { colorScaleForBounds } from "../../features/grid";
import type { IScale, GridData } from "../../features/grid";
import {
  SquareRangeScaling,
  SquareRangeScalingBasis
} from "../../features/view-controls/viewSlice";

import handspace from "../../utils/handspace";
import math from "../../utils/math";
import type { NumberFormatter } from "../../utils/math";
import {
  EvEqAverage,
  EvScaling,
  useUserPrefsContext
} from "../../utils/preferences";

import type {
  DisplayOptions,
  FieldProps,
  GridComponentProps,
  GridRows,
  TablePosition
} from "./grid.types";

import {
  HandGroupSquare,
  HandSquareValue
} from "../../utils/handspace/handspace.types";

import { SquareDetailsGridProps } from "./grid.types";
import {
  DetailsGridRow,
  SummaryGridRow,
  heightForRect,
  isRangeBelowCutoff,
  rescaleRangeValue,
  rescaleRangeToBasis,
  textStyleForNumbers
} from "./shared";
import type { SummaryGridHandGroup } from "./shared";
import { Node, PotContrib } from "../../utils/nodespace";
import { handsValuesMap } from "../../utils/handspace/handspace";

function valuesForGrid(props: GridComponentProps, evRescale: EvScaling) {
  return props.display === "EQ"
    ? props.gridData.eq
    : scaleEvData(props.gridData.ev, props.node, props.player, evRescale);
}

function rescaleBounds(
  node: Node,
  percentBounds: number[],
  heroIndex: number,
  evRescale: EvScaling
): number[] {
  if (evRescale === EvScaling.PERCENT) return percentBounds;
  const pot = node.pot;
  const d = pot[PotContrib.EXTRA];
  const h = pot[heroIndex];
  const min = Math.min(pot[PotContrib.IP], pot[PotContrib.OOP]);

  if (evRescale === EvScaling.STANDARD)
    return percentBounds.map((percent) => (percent * (d + h + min)) / 100);

  return percentBounds.map((percent) => (percent * (d + h + min)) / 100 - h);
}

function colorScaleForGrid(
  gridData: GridData,
  node: Node,
  player: TablePosition,
  display: DisplayOptions,
  evRescale: EvScaling
) {
  if (display === "EQ") return gridData.eqColorScale;
  //from EQEVLegend
  let bounds = [0, 50, 100];
  bounds = rescaleBounds(node, bounds, PotContrib[player], evRescale);
  return colorScaleForBounds(bounds);
}

function formatterForGrid(
  allValues: number[],
  precision: number,
  display: DisplayOptions
): NumberFormatter {
  if (display === "EQ") return math.formatterForFloat(95.0, precision);

  const values = allValues.filter((v) => !isNaN(v));
  const keyValue = Math.max(...values) * 0.95;
  return math.formatterForFloat(keyValue, precision);
}

function squareFillSummarySlice(
  rootRangeMean: number,
  valueMean: number,
  value: number,
  colorScale: IScale
) {
  if (isRangeBelowCutoff(rootRangeMean)) return "hsla(0, 0%, 30%, 0)";
  if (isNaN(valueMean)) return "hsla(0, 0%, 30%, 0)";
  const color = colorScale(value);
  return `${color}`;
}

function squareFillValue(square: HandSquareValue, colorScale: IScale) {
  if (isNaN(square.value)) return "hsla(0, 0%, 30%, 0)";
  const color = colorScale(square.value);
  return `${color}`;
}

interface EvEqSquareSummaryProps extends FieldProps {
  colorScale: IScale;
  formatter: NumberFormatter;
  summary: EvEqHandGroupSummary;
  squareRangeScaling: SquareRangeScaling;
  rangeRescaleBasis: number;
}

interface EvEqSummaryRectSliceProps extends EvEqSquareSummaryProps {
  index: number;
  value: number;
  width: number;
}

function EvEqSummaryRectSlice(props: EvEqSummaryRectSliceProps) {
  const {
    colorScale,
    index,
    fieldDimensions,
    summary,
    width,
    value,
    squareRangeScaling,
    rangeRescaleBasis
  } = props;
  const { height: fieldHeight } = fieldDimensions;
  const fill = squareFillSummarySlice(
    summary.rootRangeMean,
    summary.valueMean,
    value,
    colorScale
  );
  const rangeScaled = rescaleRangeValue(summary.rangeMean, rangeRescaleBasis);
  const height = heightForRect(fieldHeight, rangeScaled, squareRangeScaling);
  return (
    <rect
      x={width * index}
      y={fieldHeight - height}
      fill={fill}
      height={height}
      stroke={fill}
      width={width}
    />
  );
}

function EvEqSummaryRect(props: EvEqSquareSummaryProps) {
  const { summary, fieldDimensions } = props;

  const { width } = fieldDimensions;

  const squareValues = summary.square.handSquareValues.filter((v) => !isNaN(v));
  squareValues.sort((a, b) => b - a);
  const sliceWidth = width / squareValues.length;
  const slices = squareValues.map((v, i) => (
    <EvEqSummaryRectSlice
      key={i}
      index={i}
      value={v}
      width={sliceWidth}
      {...props}
    />
  ));
  return <g>{slices}</g>;
}

function EvEqSummaryLabel(props: EvEqSquareSummaryProps) {
  const { summary, fieldDimensions } = props;
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;
  const { width: rectWidth, height: rectHeight } = fieldDimensions;
  const value = summary.valueMean ?? 0;
  return (
    <text
      className="square-value"
      x={rectWidth * 0.95}
      dy={rectHeight * 0.8}
      textAnchor="end">
      {math.formattedFloat(value, props.formatter, trailingZerosHidden)}
    </text>
  );
}

interface EvEqHandGroupSummary extends SummaryGridHandGroup {
  square: HandGroupSquare<number[]>;
  valueMean: number;
}

function evEqSummaries(props: GridComponentProps) {
  const { gridData, averageForEvEq } = props;
  const rangedHandspace = handspace.rangedHandspace(
    gridData.rootRange,
    gridData.range
  );
  const values = valuesForGrid(props, props.evRescale);
  const { node, player, display, evRescale } = props;
  const colorScale = colorScaleForGrid(
    gridData,
    node,
    player,
    display,
    evRescale
  );

  const handSquareSummaries =
    rangedHandspace.mapValuesRows<EvEqHandGroupSummary>(values, (r) => {
      return r.mapValues((v) => {
        const valueMean = getValueMean(
          v.squareName,
          v.handSquareValues,
          gridData.matchups,
          averageForEvEq as EvEqAverage
        );
        const borderColor = squareFillSummarySlice(
          v.rootRangeMean,
          valueMean,
          valueMean,
          colorScale
        );
        return {
          borderColor,
          square: v,
          squareName: v.squareName,
          rangeMean: v.rangeMean,
          rootRangeMean: v.rootRangeMean,
          valueMean
        };
      });
    });
  return handSquareSummaries;
}

function getValueMean(
  squareName: string,
  handSquareValues: number[],
  matchups: number[],
  averageForEvEq: EvEqAverage
) {
  if (averageForEvEq === EvEqAverage.WEIGHTED) {
    return weightedAverageForGroupOfHands(
      squareName,
      handSquareValues,
      matchups
    );
  }

  return math.mean(handSquareValues);
}

const weightedAverageForGroupOfHands = (
  squareName: string,
  handSquareValues: number[],
  matchups: number[]
) => {
  const squareHandsIndexes =
    handspace.handGroupToHandsMap[squareName].handIndexMap;
  const weights = Object.keys(squareHandsIndexes).map(
    (key) => matchups[squareHandsIndexes[key]]
  );

  return weightedAverage(handSquareValues, weights);
};

const weightedAverage = (values: number[], weights: number[]) => {
  let sum = 0;
  let weightSum = 0;

  values.forEach((value, i) => {
    if (isNaN(value)) return;
    const weight = weights[i];
    if (isNaN(weight)) return;

    sum += value * weight;
    weightSum += weight;
  });

  return sum / weightSum;
};

function eqEvRows(props: GridComponentProps): GridRows {
  const { fontSizes, squareRangeScaling, squareRangeScalingBasis } = props;
  const values = valuesForGrid(props, props.evRescale);
  const { node, player, display, evRescale } = props;
  const colorScale = colorScaleForGrid(
    props.gridData,
    node,
    player,
    display,
    evRescale
  );
  const precision = props.numberDisplayPrecision;
  const formatter = formatterForGrid(values, precision, props.display);
  const handSquareSummaries = evEqSummaries(props);

  let rangeRescaleBasis = 1;
  if (
    squareRangeScaling === SquareRangeScaling.HEIGHT &&
    squareRangeScalingBasis === SquareRangeScalingBasis.MAX
  ) {
    rangeRescaleBasis = Math.max(
      ...handSquareSummaries.map((row) =>
        Math.max(...row.map((v) => v.rangeMean))
      )
    );
  }

  const { fieldDimensions } = props;

  const bgFunc = (summary: EvEqHandGroupSummary) => (
    <EvEqSummaryRect
      colorScale={colorScale}
      formatter={formatter}
      summary={summary}
      rangeRescaleBasis={rangeRescaleBasis}
      {...props}
    />
  );
  const labelFunc = (summary: EvEqHandGroupSummary) => (
    <EvEqSummaryLabel
      colorScale={colorScale}
      formatter={formatter}
      summary={summary}
      rangeRescaleBasis={rangeRescaleBasis}
      {...props}
    />
  );

  const rows = handSquareSummaries.map((s, i) => (
    <SummaryGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      labelFunc={labelFunc}
      rowIndex={i}
      rowSummaries={s}
    />
  ));
  return {
    rows,
    squares: handSquareSummaries
  };
}

interface EvEqDetailsSquareProps extends FieldProps {
  colorScale: IScale;
  formatter: NumberFormatter;
  valueTextStyle: React.CSSProperties;
  square: HandSquareValue;
  squareRangeScaling: SquareRangeScaling;
  rangeValue: number;
}

function EqEvDetailsSquare(props: EvEqDetailsSquareProps) {
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;
  const {
    colorScale,
    fieldDimensions,
    square,
    rangeValue,
    squareRangeScaling
  } = props;

  const { width: rectWidth, height: rectHeight } = fieldDimensions;
  const height = heightForRect(rectHeight, rangeValue, squareRangeScaling);
  const valueIsNaN = isNaN(square.value);
  const fill = squareFillValue(square, colorScale);
  //distance from bottom edge fieldDimensions.height * 0.05
  const dy = rectHeight - fieldDimensions.height * 0.05;
  const alpha = 1;

  return (
    <>
      {valueIsNaN ? null : (
        <rect
          y={rectHeight - height}
          fill={fill}
          height={height}
          width={rectWidth}
        />
      )}
      <text
        className="details-value"
        x={fieldDimensions.width * 0.95}
        dy={dy}
        fill={`hsla(0, 0%, 5%, ${alpha})`}
        style={props.valueTextStyle}
        textAnchor="end">
        {valueIsNaN
          ? null
          : math.formattedFloat(
              square.value,
              props.formatter,
              trailingZerosHidden
            )}
      </text>
    </>
  );
}

const EvScalingFunctions = {
  standard: (ev: number, hero: number) => ev + hero,
  percent: (ev: number, hero: number, dead: number, min: number) =>
    (100 * (ev + hero)) / (dead + hero + min)
};

function scaleEvData(
  evRaw: number[],
  node: Node,
  player: TablePosition,
  scalingType: EvScaling
): number[] {
  if (scalingType === EvScaling.RAW) return evRaw;

  const heroIndex = PotContrib[player];
  const villainIndex =
    heroIndex === PotContrib.IP ? PotContrib.OOP : PotContrib.IP;

  const hero = node.pot[heroIndex];
  const villain = node.pot[villainIndex];
  const dead = node.pot[PotContrib.EXTRA];
  const min = Math.min(hero, villain);
  return evRaw.map((ev) => scaleEvValue(ev, hero, dead, min, scalingType));
}

function scaleEvValue(
  ev: number,
  hero: number,
  dead: number,
  min: number,
  scalingType: EvScaling
) {
  if (scalingType === EvScaling.STANDARD)
    return EvScalingFunctions.standard(ev, hero);
  return EvScalingFunctions.percent(ev, hero, dead, min);
}

function eqEvSquareDetailsRows(props: SquareDetailsGridProps) {
  const {
    fieldDimensions,
    fontSizes,
    gridData,
    square,
    squareRangeScaling,
    squareRangeScalingBasis,
    width,
    height
  } = props;

  const handGroup = square.handGroup;
  if (handGroup == null) return null;

  const values = valuesForGrid(props, props.evRescale);
  const { node, player, display, evRescale } = props;
  const colorScale = colorScaleForGrid(
    gridData,
    node,
    player,
    display,
    evRescale
  );

  const precision = props.numberDisplayPrecision;
  const formatter = formatterForGrid(values, precision, props.display);
  const totalDimensions = { width, height };
  const valueTextStyle = textStyleForNumbers(
    fontSizes.details.text,
    totalDimensions,
    precision
  );

  const range = gridData.range;
  const rhs = handspace.rangedHandspace(gridData.rootRange, range);
  const handValueDetails = rhs.mapChunkedHandGroupDetails<HandSquareValue>(
    handGroup,
    values,
    (r) => r.mapValues((v) => v)
  );
  const handsRangeMap = handsValuesMap(
    rescaleRangeToBasis(range, squareRangeScalingBasis)
  );
  const bgFunc = (square: HandSquareValue) => (
    <EqEvDetailsSquare
      colorScale={colorScale}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      formatter={formatter}
      rangeValue={handsRangeMap[square.hand]}
      square={square}
      squareRangeScaling={squareRangeScaling}
      valueTextStyle={valueTextStyle}
    />
  );

  const svgRows = handValueDetails.map((row, i) => (
    <DetailsGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      row={row}
      rowIndex={i}
      totalDimensions={totalDimensions}
    />
  ));

  return svgRows;
}

/* eslint-disable @typescript-eslint/no-unsafe-call */

interface LegendProps extends FieldProps {
  colorScale: IScale;
}

function EqEvLegendViz(props: LegendProps) {
  const { colorScale, fieldDimensions } = props;
  const height = fieldDimensions.height;
  const ticksCount = 5;
  const tickPos = colorScale.ticks(ticksCount);
  const tickFormat = colorScale.tickFormat(ticksCount);
  const squareWidth = (1 / tickPos.length) * 100;
  const squareHeight = height;
  const ticksSvg = tickPos.map((t, i) => {
    const fill = colorScale(t);
    const label = tickFormat(t);
    return (
      <g key={i}>
        <rect
          x={`${squareWidth * i}%`}
          y={0}
          fill={fill}
          stroke={undefined}
          height={squareHeight}
          width={`${squareWidth}%`}
        />
        <text
          dx={`${squareWidth * 0.5}%`}
          dy={squareHeight * 0.5 + 4.5}
          fill={`hsl(0, 0%, 5%))`}
          x={`${squareWidth * i}%`}
          y={0}
          style={{ fontSize: "9pt" }}
          textAnchor="middle">
          {label}
        </text>
      </g>
    );
  });

  return (
    <div style={{ height: height }}>
      <svg className="viz" width="100%" height={height}>
        {ticksSvg}
      </svg>
    </div>
  );
}

/* eslint-enable @typescript-eslint/no-unsafe-call */

function EqEvLegend(props: GridComponentProps) {
  const { display, evRescale, fieldDimensions, fontSizes, node, player } =
    props;
  const colorScale = colorScaleForGrid(
    props.gridData,
    node,
    player,
    display,
    evRescale
  );
  return (
    <EqEvLegendViz
      colorScale={colorScale}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
    />
  );
}

export {
  EqEvLegend,
  EqEvLegendViz,
  eqEvRows,
  eqEvSquareDetailsRows,
  colorScaleForGrid,
  weightedAverageForGroupOfHands,
  weightedAverage
};
