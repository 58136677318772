import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

import { Spinner } from "../../utils";
import { useNavigate } from "react-router-dom";

type LoginCredentials = {
  email: string;
};

interface ResetProps {
  resetPassword: (credentials: LoginCredentials) => Promise<unknown>;
  userResetError: FetchBaseQueryError | SerializedError | undefined;
  userUpdating: boolean;
  email: string;
}

function Reset(resetProps: ResetProps) {
  const userUpdating = false;
  const navigate = useNavigate();
  const [email, setEmail] = React.useState(resetProps.email);
  const [validated, setValidated] = React.useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    const form = e.currentTarget as HTMLFormElement;
    const isValid = form.checkValidity();
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);
    if (isValid) {
      await resetProps.resetPassword({ email });
      navigate("/");
    }
  };

  return (
    <div>
      <Form
        noValidate
        validated={validated}
        style={{ maxWidth: "400px" }}
        onSubmit={handleSubmit}>
        <div className="mb-3">
          <label
            className="form-label"
            htmlFor="reset-email-address"
            style={{ fontSize: "small" }}>
            Enter the email address associated with your PioCloud account, and
            we will send you an email with a link to reset your password.
          </label>
          <div className="input-group">
            <input
              autoComplete="email"
              className="form-control"
              id="reset-email-address"
              onChange={(e) => setEmail(e.target.value.trim())}
              placeholder="name@example.com"
              required={true}
              type="email"
              value={email}
            />
            <div className="invalid-feedback">
              Please provide an email address.
            </div>
          </div>
        </div>
        <Form.Group
          className="d-flex flex-row-reverse align-items-center mb-3"
          controlId="submitButton">
          <div>
            <Button variant="primary" type="submit" disabled={userUpdating}>
              {userUpdating ? (
                <Spinner
                  size={16}
                  variant="light"
                  message="Waiting for reset..."
                />
              ) : (
                "Reset"
              )}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

function ForgotPassword(resetProps: ResetProps) {
  return (
    <>
      <Row>
        <Col md={8} lg={6}>
          <h2 className="fs-4">Forgot your password?</h2>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <Reset {...resetProps} />
        </Col>
      </Row>
    </>
  );
}

export { ForgotPassword };
