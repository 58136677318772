import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";

import { Node } from "../../utils/nodespace";

import {
  SquareRangeScaling,
  tablePosition,
  radioDisplayOptions,
  isBelowSm,
  scaleOptions,
  setPlayer,
  setDisplay,
  setSquareRangeScaling,
  useViewControlsDispatch,
  useViewControlsSelector
} from "./viewSlice";

import type {
  DisplayOptions,
  TablePosition,
  SquareRangeScalingString,
  ViewControlsDispatch
} from "./viewSlice";

function playerForNode(node: Node) {
  const decisionType = node.type.toUpperCase();

  if (!["OOP", "IP"].includes(decisionType)) return null;

  return decisionType;
}

function dataProvidingPlayer(
  displayingStrategy: boolean,
  node: Node | null,
  selectedPlayer: TablePosition
): TablePosition {
  if (!node) return selectedPlayer;
  if (!displayingStrategy) return selectedPlayer;
  const decisionType = node.type;

  if (!["OOP", "IP"].includes(decisionType.toUpperCase()))
    return selectedPlayer;

  return decisionType as TablePosition;
}

function variantForPlayer(
  option: TablePosition,
  selectedPlayer: TablePosition,
  usedPlayer: TablePosition
): string {
  if (usedPlayer === selectedPlayer)
    return selectedPlayer === option ? "primary" : "secondary";
  // Show differently when the selected player is not being used for data
  return selectedPlayer === option ? "primary" : "info";
}

function PlayerControlsLarge({
  display,
  node,
  player
}: {
  display: DisplayOptions;
  node: Node;
  player: TablePosition;
}) {
  const displayingStrategy =
    display === "Strat" || display === "Strat+EV" || display === "Action+Range";
  const usedPlayer = dataProvidingPlayer(displayingStrategy, node, player);
  const nodePlayer = playerForNode(node);
  const dispatch = useViewControlsDispatch();
  return (
    <Form>
      <Form.Group className="player-toggle" controlId="player">
        <Form.Label>Player</Form.Label>
        <Form.Check>
          <ButtonGroup>
            {tablePosition.map((p, i) => (
              <ToggleButton
                checked={player === p}
                id={`player-${i}`}
                key={i}
                name="radio"
                onChange={(e) =>
                  dispatch(setPlayer(e.currentTarget.value as TablePosition))
                }
                size="sm"
                type="radio"
                value={p}
                variant={variantForPlayer(p, player, usedPlayer)}>
                {p}
                <span className={nodePlayer === p ? "visible" : "invisible"}>
                  *
                </span>
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Check>
      </Form.Group>
    </Form>
  );
}

function PlayerControls({ node }: { node: Node }) {
  const { display, player, viewportDimensions } = useViewControlsSelector(
    (state) => state.viewControls
  );
  return isBelowSm(viewportDimensions) ? null : (
    <PlayerControlsLarge display={display} node={node} player={player} />
  );
}

interface DisplayButtonGroupProps {
  dispatch: ViewControlsDispatch;
  display: DisplayOptions;
  player: TablePosition;
}

function DisplayButtonGroup(props: DisplayButtonGroupProps) {
  const { dispatch, display } = props;
  return (
    <ButtonGroup>
      {radioDisplayOptions.map((p, i) => (
        <ToggleButton
          checked={display === p}
          id={`display-${p}`}
          key={i}
          name="radio"
          onChange={(e) =>
            dispatch(setDisplay(e.currentTarget.value as DisplayOptions))
          }
          size="sm"
          type="radio"
          value={p}
          variant={display === p ? "primary" : "secondary"}>
          {p}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}

function otherMobileDisplayOptions(
  otherDisplayOptions: DisplayOptions[],
  display: DisplayOptions,
  player: TablePosition,
  dispatch: ViewControlsDispatch
) {
  const tablePositions: TablePosition[] = ["OOP", "IP"];
  const options = otherDisplayOptions.map((p, i) =>
    tablePositions.map((t) => {
      const id = `display-${p}-${t}`;
      const isSelected = display === p && player === t;
      return (
        <Dropdown.Item
          checked={isSelected}
          id={id}
          key={id}
          name="radio"
          onClick={() => {
            dispatch(setDisplay(p));
            dispatch(setPlayer(t));
          }}
          size="sm"
          type="radio"
          value={p}
          variant={isSelected ? "primary" : "secondary"}>
          {p} ({t})
        </Dropdown.Item>
      );
    })
  );
  return options.flat();
}

function DisplayButtonGroupMobile(props: DisplayButtonGroupProps) {
  const { dispatch, display, player } = props;
  const mobileDisplayOptions = radioDisplayOptions.slice(0, 2);
  const otherDisplayOptions = radioDisplayOptions.slice(2);
  const isDisplayOther = otherDisplayOptions.includes(display);
  const title = isDisplayOther ? `${display.slice(0, 2)}..` : "  ...";
  return (
    <>
      <ButtonGroup>
        {mobileDisplayOptions.map((p, i) => (
          <ToggleButton
            checked={display === p}
            id={`display-${p}`}
            key={i}
            name="radio"
            onChange={() => dispatch(setDisplay(p))}
            size="sm"
            type="radio"
            value={p}
            variant={display === p ? "primary" : "secondary"}>
            {p}
          </ToggleButton>
        ))}
        <DropdownButton
          as={ButtonGroup}
          size="sm"
          title={title}
          variant={isDisplayOther ? "primary" : "secondary"}>
          {otherMobileDisplayOptions(
            otherDisplayOptions,
            display,
            player,
            dispatch
          )}
        </DropdownButton>
      </ButtonGroup>
    </>
  );
}

function DisplayControls() {
  const { display, player, viewportDimensions } = useViewControlsSelector(
    (state) => ({
      display: state.viewControls.display,
      player: state.viewControls.player,
      viewportDimensions: state.viewControls.viewportDimensions
    })
  );
  const dispatch = useViewControlsDispatch();
  return (
    <Form>
      <Form.Group className="display-toggle" controlId="display">
        <Form.Label>Display</Form.Label>
        <Form.Check>
          {isBelowSm(viewportDimensions) ? (
            <DisplayButtonGroupMobile
              dispatch={dispatch}
              display={display}
              player={player}
            />
          ) : (
            <DisplayButtonGroup
              dispatch={dispatch}
              display={display}
              player={player}
            />
          )}
        </Form.Check>
      </Form.Group>
    </Form>
  );
}

function rangeScaleDisplayIcon(str: SquareRangeScalingString) {
  const x = "2";
  const width = "12";
  if (str === "NONE") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16">
        <rect x={x} y="1" width={width} height="1" rx="0.2" />
        <rect x={x} y="3" width={width} height="11" rx="1" />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16">
      <rect x={x} y="1" width={width} height="5" rx="1" fill="none" />
      <rect x={x} y="7" width={width} height="1" rx="0.2" />
      <rect x={x} y="9" width={width} height="5" rx="1" />
    </svg>
  );
}

function RangeScalingControls() {
  const scaling = useViewControlsSelector(
    (state) => state.viewControls.squareDisplay.squareRangeScaling
  );
  const dispatch = useViewControlsDispatch();
  return (
    <Form>
      <Form.Group className="display-toggle" controlId="display">
        <Form.Label>Scale</Form.Label>
        <Form.Check>
          <ButtonGroup>
            {scaleOptions.map((p, i) => (
              <ToggleButton
                checked={scaling === SquareRangeScaling[p]}
                id={`scale-${p}`}
                key={i}
                name="radio"
                onChange={(e) =>
                  dispatch(
                    setSquareRangeScaling(
                      SquareRangeScaling[
                        e.currentTarget.value as SquareRangeScalingString
                      ]
                    )
                  )
                }
                size="sm"
                type="radio"
                value={p}
                variant={
                  scaling === SquareRangeScaling[p] ? "primary" : "secondary"
                }>
                <span className="px-1">{rangeScaleDisplayIcon(p)}</span>
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Check>
      </Form.Group>
    </Form>
  );
}

function ViewControls({ node }: { node: Node }) {
  return (
    <>
      <div className="d-flex">
        <div className="me-2">
          <PlayerControls node={node} />
        </div>
        <div className="me-2">
          <DisplayControls />
        </div>
        <div>
          <RangeScalingControls />
        </div>
      </div>
    </>
  );
}

export default ViewControls;

export { dataProvidingPlayer };
