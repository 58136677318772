import React from "react";
import { useResizeDetector } from "react-resize-detector";

import { useNodeStateSelector } from "../../features/view-controls/nodeSlice";
import type { NodeState } from "../../features/view-controls/nodeSlice";
import {
  Dimensions,
  isBelowLg,
  useViewControlsSelector
} from "../../features/view-controls/viewSlice";
// import { dataProvidingPlayer } from "../../features/view-controls";
import type { ViewControlsState } from "../../features/view-controls/viewSlice";
import { useUserPrefsContext } from "../../utils/preferences";
import type { IUserPreferences } from "../../utils/preferences";

import HandGridBorders from "./borders";

import { GridComponentProps, GridProps } from "./grid.types";

import { EqEvLegend, eqEvRows } from "./eq-ev";
import { HandGridBackground, actionRangeData } from "./shared";
import { StrategyLegend, strategyRows } from "./strategy";
import { StrategyEvLegend, strategyEvRows } from "./strategy-ev";
import { RangeLegend, rangeRows } from "./range";
import { CenteredSpinner } from "../../utils";
import {
  SpotState,
  useSpotStateSelector
} from "../../features/view-controls/spotSlice";

function getRows(props: GridComponentProps) {
  switch (props.display) {
    case "Strat":
      return strategyRows(props);
    case "Strat+EV":
      return strategyEvRows(props);
    case "Range":
      return rangeRows(props);
    case "EV":
    case "EQ":
      return eqEvRows(props);
    case "Action+Range": {
      const actionRangeProps = actionRangeData(props);
      return rangeRows(actionRangeProps);
    }
    default:
      return null;
  }
}

interface BigGridComponentProps extends GridComponentProps {
  totalDimensions: Dimensions;
}

function BigGrid(props: BigGridComponentProps) {
  const components = getRows(props);
  if (components == null) return <div className="h-100 w-100" />;

  const { rows, squares } = components ?? { rows: [], squares: [] };
  const { fieldDimensions, fontSizes, totalDimensions } = props;
  const width = totalDimensions.width,
    height = totalDimensions.height;
  return (
    <svg
      height="100%"
      width="100%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMin meet">
      <HandGridBackground
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        squares={squares}
      />
      {rows}
      <HandGridBorders
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        squares={squares}
      />
    </svg>
  );
}

function toGridComponentProps(
  spotState: SpotState,
  nodeState: NodeState,
  props: GridProps,
  prefs: IUserPreferences,
  viewState: ViewControlsState,
  dimensions: Dimensions
): GridComponentProps {
  const { presentation } = prefs;
  const { nodeTree: allNodes } = spotState;
  const { displayNodeId } = nodeState;
  const { fontSizes } = viewState;
  const { squareRangeScaling, squareRangeScalingBasis } =
    viewState.squareDisplay;
  const node = allNodes[displayNodeId];
  const pot = node.pot;

  const { height, width } = dimensions;

  const fieldDimensions = {
    width: width / 13,
    height: height / 13
  };
  const { precisionGrid } = presentation.grid;
  const { gridDetails: gridDetailsSizeDisplay } = presentation.betSizesDisplay;
  return {
    allNodes,
    childIds: node.children,
    fieldDimensions: fieldDimensions,
    fontSizes,
    gridDetailsSizeDisplay: gridDetailsSizeDisplay,
    numberDisplayPrecision: precisionGrid,
    pot,
    squareRangeScaling: squareRangeScaling,
    squareRangeScalingBasis: squareRangeScalingBasis,
    averageForEvEq: presentation.grid.averageForEvEq,
    ...props
  };
}

interface OmniStrategyLegendProps extends GridProps {
  isLoading?: boolean;
  isFetching?: boolean;
  width: number;
}

function OmniGridStrategyLegend(props: OmniStrategyLegendProps) {
  const viewControlsState = useViewControlsSelector(
    (state) => state.viewControls
  );
  const componentProps = toGridComponentProps(
    useSpotStateSelector((state) => state.spotState),
    useNodeStateSelector((state) => state.nodeState),
    props,
    useUserPrefsContext(),
    viewControlsState,
    {
      height: props.squareSize * 13,
      width: props.width
    }
  );
  if (props.isLoading || props.isFetching) return <CenteredSpinner />;
  if (props.gridData == null) return <div></div>;
  return <StrategyLegend {...componentProps} />;
}

function GridLegend(props: GridComponentProps) {
  return <Legend {...props} />;
}

function Legend(props: GridComponentProps) {
  const { display } = props;
  if (display === "Strat") return <div />;
  if (display === "Strat+EV") return <StrategyEvLegend {...props} />;
  if (display === "Range") return <RangeLegend {...props} />;
  if (display === "Action+Range") return <div />;

  return <EqEvLegend {...props} />;
}

function OmniGridLegend(props: GridProps) {
  const viewState = useViewControlsSelector((state) => state.viewControls);
  const targetRef = React.useRef<HTMLDivElement>(null);
  let { width } = useResizeDetector({ targetRef });
  const height = props.squareSize * 13;
  width = width || viewState.viewportDimensions.width;

  const componentProps = toGridComponentProps(
    useSpotStateSelector((state) => state.spotState),
    useNodeStateSelector((state) => state.nodeState),
    props,
    useUserPrefsContext(),
    viewState,
    { height, width }
  );

  return (
    <div className="w-100 h-100 user-select-none" ref={targetRef}>
      <GridLegend {...componentProps} />
    </div>
  );
}

interface OmniGridProps extends GridProps {
  componentDims?: Dimensions;
}

function OmniGrid(props: OmniGridProps) {
  const detectedDims = props.componentDims;
  const viewState = useViewControlsSelector((state) => state.viewControls);
  let height =
    detectedDims?.height || viewState.viewportDimensions.height - 120;
  let width = detectedDims?.width || viewState.viewportDimensions.width * 0.65;
  if (isBelowLg(viewState.viewportDimensions))
    height = width = Math.min(height, width);
  const totalDimensions = { height, width };
  const componentProps = toGridComponentProps(
    useSpotStateSelector((state) => state.spotState),
    useNodeStateSelector((state) => state.nodeState),
    props,
    useUserPrefsContext(),
    viewState,
    totalDimensions
  );

  return (
    <div className="w-100 h-100 user-select-none">
      <BigGrid totalDimensions={totalDimensions} {...componentProps} />
    </div>
  );
}

export default OmniGrid;

export { OmniGridLegend, OmniGridStrategyLegend };
