/**
 * Math utilities
 */

import { max, mean, min, sum } from "d3-array";
import { format } from "d3-format";

const digitMatcher = /[0-9]/g;
const trailingZeroMatcher = /(\.|[1-9])(0+)$/;

type NumberLike = number | { valueOf(): number };
type NumberFormatter = (n: NumberLike) => string;

const MathFunctions = {
  bounds(array: number[]) {
    return [min(array), max(array)];
  },

  /**
   * Return a formatter based on the inputs.
   * @param num number to format
   * @param precision the number of significant digits to show
   */
  formatterForFloat(num: number, precision: number) {
    const [beforeCount, _afterCount] = num
      .toString()
      .split(".")
      .map((s) => s.match(digitMatcher)?.length ?? 0);
    const specifier =
      beforeCount >= precision
        ? `.${precision}r`
        : `.${max([0, precision - beforeCount]) ?? 0}f`;
    return format(specifier);
  },
  /**
   * Format a number.
   * @param num number to format
   * @param precision the number of significant digits to show
   * @param trailingZerosHidden are trailing zeros hidden?
   */
  formattedFloat(
    num: number,
    formatter: NumberFormatter,
    trailingZerosHidden: boolean
  ) {
    const result = formatter(num);
    if (!trailingZerosHidden) return result;
    const match = result.match(trailingZeroMatcher);
    if (!match) return result;
    return result.slice(0, 1 + (match.index ?? 0)).replace(/\.$/, "");
  },
  mean(array: number[]) {
    return mean(array) as number;
  },
  numberToProportion(num: number) {
    return `${num * 100}%`;
  },
  /**
   * Return a random number between 0 (inclusive) and max (exclusive).
   * @param max Exclusive max value
   * @returns number
   */
  randomUpTo(max: number) {
    return Math.floor(Math.random() * max);
  },
  sum(array: number[]) {
    return sum(array);
  }
};

export default MathFunctions;

export type { NumberFormatter, NumberLike };
