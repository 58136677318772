// Partially following https://ui.dev/react-router-protected-routes-authentication/
import {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useNewPasswordMutation,
  useResetPasswordMutation
} from "../../services/piosaasServerApi";

function useUser() {
  const { data, error, isLoading } = useGetUserQuery();
  const [updateLogin, { isLoading: loginUpdating, error: loginError }] =
    useLoginMutation();
  const [updateLogout, { isLoading: logoutUpdating, error: logoutError }] =
    useLogoutMutation();
  const [
    updateRegister,
    { isLoading: registerUpdating, error: registerError }
  ] = useRegisterMutation();
  const [
    updateResetPassword,
    { isLoading: resetPasswordUpdating, error: resetPasswordError }
  ] = useResetPasswordMutation();
  const [
    updateNewPassword,
    { isLoading: newPasswordUpdating, error: newPasswordError }
  ] = useNewPasswordMutation();

  const isUpdating =
    loginUpdating ||
    logoutUpdating ||
    registerUpdating ||
    resetPasswordUpdating ||
    newPasswordUpdating;

  return {
    user: data,
    userError: error,
    userLoading: isLoading,
    userUpdating: isUpdating,
    userLoginError: loginError,
    userLogoutError: logoutError,
    userRegisterError: registerError,
    userResetError: resetPasswordError,
    userResetPasswordError: newPasswordError,
    login: updateLogin,
    logout: updateLogout,
    register: updateRegister,
    resetPassword: updateResetPassword,
    newPassword: updateNewPassword
  };
}

export default useUser;
