import { useEffect, useRef, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import About from "./components/about";
import BoardContent from "./components/board";
import DashboardContent from "./components/dashboard";
import Footer from "./components/footer";
import Help from "./components/help";
import PioCloudNavbar from "./components/navbar";

import {
  AuthAction,
  DataLoading,
  HideIfNoUser,
  RequireUser,
  useUser
} from "./features/auth";

import { SpotInfo, useSpotInfoQuery } from "./services/piosaasTreeApi";
import { useDispatch } from "react-redux";
import {
  initialNodeId,
  setBoard,
  setBoardAndGhost,
  setBoardGhost,
  setDisplayAndGhostId,
  setRootBoard,
  useNodeStateSelector
} from "./features/view-controls/nodeSlice";
import {
  setSpot,
  setSpotData,
  useSpotStateSelector
} from "./features/view-controls/spotSlice";
import { setViewportDimensions } from "./features/view-controls/viewSlice";
import { cardsCompressAndSplit } from "./utils/cards";
import { ErrorBoundary, HandleError } from "./utils/errors";
import { useWindowSize } from "./utils/hooks/useWindowSize";
import SpotContent from "./components/spot";
import { CenteredSpinner } from "./utils";
import { dealActionForStreet } from "./utils/nodespace/nodespace";
import { ForgotPassword as ResetPassword } from "./features/auth/";
import NewSpot from "./components/spot-new";
import GrantsPage from "./features/grants/grants";
import NewGrantPage from "./features/grants/grants-new";

function Dashboard() {
  return (
    <RequireUser>
      <Row>
        <Col>
          <DashboardContent />
        </Col>
      </Row>
    </RequireUser>
  );
}

function NewSpots() {
  return (
    <RequireUser>
      <NewSpot />
    </RequireUser>
  );
}
function Grants() {
  return (
    <RequireUser>
      <GrantsPage />
    </RequireUser>
  );
}
function NewGrant() {
  return (
    <RequireUser>
      <NewGrantPage />
    </RequireUser>
  );
}
function NavBar({ browsingTree }: { browsingTree: boolean }) {
  return (
    <HideIfNoUser>
      <PioCloudNavbar browsingTree={browsingTree} />
    </HideIfNoUser>
  );
}

// eslint-disable-next-line spellcheck/spell-checker
const nonDealActions = "r0cbf";
const isNonDealAction = (action: string) =>
  nonDealActions.includes(action.toLowerCase()[0]);

const parseNodeParams = (nodeParam: string, rootBoard: string | undefined) => {
  const nodeValueSplit = nodeParam.split(".");
  //const rootStreet = rootBoard ? cardsCompressAndSplit(rootBoard).length : 0;
  let newBoardArr = rootBoard ? cardsCompressAndSplit(rootBoard) : [];

  for (let i = 0; i < nodeValueSplit.length; i++) {
    const action = nodeValueSplit[i];
    if (isNonDealAction(action)) continue;
    newBoardArr = newBoardArr.concat(cardsCompressAndSplit(action));
    nodeValueSplit[i] = dealActionForStreet(newBoardArr.length);
  }
  const nodeValue = nodeValueSplit.join(":");
  return { nodeValue, newBoard: newBoardArr.join("") };
};

function Board() {
  const { board, spot } = useParams();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [transferNodeState, setTransferNodeState] = useState(false);
  const [paramsBoardState, setParamsBoardState] = useState({
    newBoard: "",
    nodeValue: ""
  });
  const { board: oldBoard, boardGhost } = useNodeStateSelector(
    (state) => state.nodeState
  );
  const { data } = useSpotStateSelector((state) => state.spotState);
  const dispatch = useDispatch();

  const {
    data: spotData,
    error,
    isLoading,
    isSuccess,
    isFetching
  } = useSpotInfoQuery({ spot });

  useEffect(() => {
    const boardStringed = board ?? "";
    dispatch(setRootBoard(boardStringed));
    const searchParams = new URLSearchParams(search);
    const paramsNodeValue = searchParams.get("node");

    if (paramsNodeValue) {
      setTransferNodeState(false);
      const parsedParams = parseNodeParams(paramsNodeValue, board);
      setParamsBoardState(parsedParams);
      navigate(pathname);
      return;
    }

    if (transferNodeState) {
      setTransferNodeState(false);
      const newBoard = getInitialBoardOnUpdate(boardStringed, oldBoard);
      const newGhostBoard = getInitialBoardOnUpdate(boardStringed, boardGhost);
      const newGhostBoardProper = !boardHasDuplicates(newGhostBoard);
      if (newGhostBoardProper) {
        dispatch(setBoard(newBoard));
        dispatch(setBoardGhost(newGhostBoard));
        return;
      }
    }

    if (paramsBoardState.nodeValue !== "") {
      const { newBoard, nodeValue } = paramsBoardState;
      dispatch(setBoardAndGhost(newBoard));
      dispatch(setDisplayAndGhostId(nodeValue));
      setParamsBoardState({ newBoard: "", nodeValue: "" });
      return;
    }

    dispatch(setDisplayAndGhostId(initialNodeId));

    // Cannot add oldBoard and paramsBoardState to the list of deps because we need to ignore
    // changes that are made in this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, dispatch, search]);

  useEffect(() => {
    dispatch(setSpot(spot ?? ""));
  }, [spot, dispatch]);

  useEffect(() => {
    if (!spotData) return;
    if (spotData === data) return;
    dispatch(setSpotData(spotData));
  }, [spotData, dispatch, data]);

  return (
    <RequireUser>
      <>{isLoading || isFetching ? <DataLoading source="spot" /> : null}</>

      <HandleError error={error}>
        <>
          {isSuccess ? (
            <BoardContent
              setTransferNodeState={setTransferNodeState}
              spot={spot as string}
            />
          ) : null}
        </>
      </HandleError>
    </RequireUser>
  );
}
function Spot() {
  const { spot } = useParams();
  const { data } = useSpotStateSelector((state) => state.spotState);
  const dispatch = useDispatch();
  const {
    data: spotData,
    error,
    isLoading,
    isSuccess,
    isError,
    isFetching
  } = useSpotInfoQuery({ spot });

  useEffect(() => {
    dispatch(setSpot(spot ?? ""));
  }, [spot, dispatch]);
  useEffect(() => {
    if (!spotData) return;
    if (spotData === data) return;
    dispatch(setDisplayAndGhostId(initialNodeId));
    dispatch(setSpotData(spotData));
  }, [spotData, dispatch, data]);

  return (
    <RequireUser>
      <>
        {isLoading || isFetching ? (
          <CenteredSpinner message="Spot info fetching" />
        ) : null}
      </>
      <>{isError && isErrorWithMessage(error) ? error.message : null}</>
      <>
        {isSuccess && (
          <SpotContent spot={spot ?? ""} spotData={spotData as SpotInfo} />
        )}
      </>
    </RequireUser>
  );
}
function ForgotPassword() {
  const { userUpdating, userResetError, resetPassword } = useUser();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email_from_login") ?? "";
  const resetProps = {
    resetPassword,
    userResetError,
    userUpdating,
    email
  };
  return (
    <div className="mx-md-3">
      <h1 style={{ width: 360, fontSize: "xxx-large" }}>PioCloud</h1>
      <ResetPassword {...resetProps} />
    </div>
  );
}
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as { [key: string]: string }).message === "string"
  );
}
function getInitialBoardOnUpdate(rootBoard: string, oldBoard: string) {
  if (!rootBoard) return oldBoard;
  const newBoard = `${rootBoard}${oldBoard.substring(rootBoard.length)}`;

  return newBoard;
}

function boardHasDuplicates(board: string) {
  if (!board) return false;
  const splitBoard = cardsCompressAndSplit(board);
  return hasDuplicates(splitBoard);
}

function hasDuplicates(arr: unknown[]) {
  return arr.length !== new Set(arr).size;
}

function App() {
  const targetRef = useRef(null);
  // const dimensions = useResizeDetector({ targetRef });
  const dimensions = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    if (dimensions) dispatch(setViewportDimensions(dimensions));
  }, [dimensions, dispatch]);

  return (
    <div className="app-grid">
      <div className="app-navbar">
        <header id="navheader">
          <Routes>
            <Route
              path="/board/:pack/:spot/:board"
              element={<NavBar browsingTree={true} />}
            />
            <Route path="/*" element={<NavBar browsingTree={false} />} />
          </Routes>
        </header>
      </div>
      <div className="app-content">
        <main ref={targetRef} id="content">
          <Container className="h-100" fluid>
            <div className="h-100 mx-md-1">
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/auth/action" element={<AuthAction />} />
                  <Route path="/board/:pack/:spot/" element={<Spot />} />
                  <Route path="/board/:pack/:spot/:board" element={<Board />} />
                  <Route path="/forgot" element={<ForgotPassword />} />
                  <Route path="/grants" element={<Grants />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/new/spot" element={<NewSpots />} />
                  <Route path="/new/grant" element={<NewGrant />} />
                </Routes>
              </ErrorBoundary>
            </div>
            <Routes>
              <Route
                path="/"
                element={
                  <footer className="footer mt-auto py-3 mx-2 mx-md-2">
                    <Footer />
                  </footer>
                }
              />
              <Route path="*" element={null} />
            </Routes>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
