import React, { useState } from "react";
import {
  BoxArrowLeft,
  BoxArrowUp,
  CheckLg,
  Link45deg
} from "react-bootstrap-icons";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import useUser from "../features/auth/useUser";

import { StatusIndicator } from "../features/metadata";
import { useSpotStateSelector } from "../features/view-controls/spotSlice";
import { useNodeStateSelector } from "../features/view-controls/nodeSlice";
import { Link, linkToNode } from "../utils/nav";

interface ShareBoardProps {
  browsingTree: boolean;
}

function ShareBoard(props: ShareBoardProps) {
  const { displayNodeId, rootBoard, board } = useNodeStateSelector(
    (state) => state.nodeState
  );
  const { spot } = useSpotStateSelector((state) => state.spotState);
  const [useLinkIcon, setUseLinkIcon] = useState(true);
  if (!props.browsingTree) return null;
  const link = linkToNode(spot, rootBoard, displayNodeId, board);
  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setUseLinkIcon(false);
      setTimeout(() => {
        setUseLinkIcon(true);
      }, 3000);
    } catch (e) {
      // Do nothing
    }
  };
  return (
    <Dropdown align="end" autoClose="outside">
      <Dropdown.Toggle variant="dark">
        <BoxArrowUp className="mb-1" fill="#1F78B4" width="18" height="18" />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item onClick={onCopy}>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ cursor: "pointer" }}>Copy link</span>
            <CheckLink useLinkIcon={useLinkIcon} />
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function UserMenu() {
  const { user, logout } = useUser();
  return (
    <div className="flex-shrink-1">
      <Nav>
        <NavDropdown align="end" title={user?.email[0]} menuVariant="dark">
          <NavDropdown.Item className="pe-none">{user?.email}</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/new/spot">
            <div className="d-flex justify-content-end">New spot</div>
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/grants">
            <div className="d-flex justify-content-end">Grants</div>
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => logout()}>
            <div className="d-flex justify-content-end">
              <div className="me-2">
                <BoxArrowLeft fill="currentColor" width="16" height="16" />
              </div>
              <div>Logout</div>
            </div>
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </div>
  );
}

function PioCloudNavbar({ browsingTree }: { browsingTree: boolean }) {
  const location = useLocation();

  const showSpot = location.pathname.split("/").length > 2;
  const navigate = useNavigate();
  const spotInfo = useSpotStateSelector((state) => state.spotState);

  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container fluid>
        <div className="flex-grow-0 flex-shrink-1 d-inline">
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src="/logo512.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="PioSOLVER logo"
              />
            </Navbar.Brand>
          </LinkContainer>
        </div>
        <div className="flex-grow-1">
          <Breadcrumb>
            <Breadcrumb.Item active>
              {showSpot && (
                <span
                  onClick={() => navigate(`/board/pack/${spotInfo.spot}`)}
                  className="text-primary clickable">
                  {spotInfo.spot}
                  {/* <b>BTN</b> vs. <b>BB</b> | 6 max | 100B depth | 3bet pot */}
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="d-flex flex-grow-1 flex-row-reverse gap-2 align-items-center">
          <UserMenu />
          <ShareBoard browsingTree={browsingTree} />
          <StatusIndicator />
        </div>
      </Container>
    </Navbar>
  );
}

function CheckLink({ useLinkIcon }: { useLinkIcon: boolean }) {
  return useLinkIcon ? (
    <Link45deg className="clickable mt-1" width={20} height={20} />
  ) : (
    <CheckLg width={20} height={20} />
  );
}
export default PioCloudNavbar;
