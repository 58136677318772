import React from "react";

import { useNodeInfoQuery } from "../services/piosaasTreeApi";

import { UserPrefsContext, defaultUserPrefs } from "../utils/preferences";
import { CenteredSpinner } from "../utils";

import Controls from "./controls";
import Viz from "./viz";
import { useNodeStateSelector } from "../features/view-controls/nodeSlice";
import { useSpotStateSelector } from "../features/view-controls/spotSlice";
import { Node } from "../utils/nodespace";

type BoardProps = {
  spot: string;
  setTransferNodeState: React.Dispatch<React.SetStateAction<boolean>>;
};
function Board({ spot, setTransferNodeState }: BoardProps) {
  const { board, rootBoard, displayNodeId } = useNodeStateSelector(
    (state) => state.nodeState
  );
  const { data: spotInfo, nodeTree } = useSpotStateSelector(
    (state) => state.spotState
  );
  const skipQuery =
    !spotInfo ||
    (spotInfo.boards.length > 0 &&
      rootBoard.length !== spotInfo.boards[0].length);

  const { data, isLoading, isFetching, refetch } = useNodeInfoQuery(
    {
      spot,
      rootBoard,
      node: displayNodeId,
      board: board
    },
    { skip: skipQuery }
  );

  const displayNode = nodeTree ? nodeTree[displayNodeId] : null;

  if (isLoading || data === undefined) {
    return (
      <div className="content-area">
        <CenteredSpinner message="Loading node info" />
      </div>
    );
  }

  if (!data) refetch();

  return (
    <UserPrefsContext.Provider value={defaultUserPrefs}>
      <div className="board-grid">
        <Controls
          board={board}
          node={displayNode}
          setTransferNodeState={setTransferNodeState}
          spot={spot}
          spotInfo={spotInfo}
        />
        <Viz
          data={data}
          node={displayNode as Node}
          isFetching={isFetching}
          isLoading={isLoading}
          spotInfo={spotInfo}
        />
      </div>
    </UserPrefsContext.Provider>
  );
}

export default Board;
