import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type Player = {
  email: string;
};

// Define a service using a base URL and expected endpoints
export const piosaasServerApi = createApi({
  reducerPath: "piosaasServerApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<Player, void>({
      query: () => "player",
      providesTags: (user: Player | undefined, _error, _id) => {
        return [{ type: "User", id: user?.email }];
      }
    }),
    login: builder.mutation({
      query: (credentials: { email: string; password: string }) => {
        return {
          url: "login",
          method: "POST",
          body: credentials
        };
      },
      invalidatesTags: ["User"]
    }),
    logout: builder.mutation<null, void>({
      query: () => ({
        url: "logout",
        method: "POST"
      }),
      invalidatesTags: ["User"]
    }),
    register: builder.mutation({
      query: (credentials: { email: string; password: string }) => {
        return {
          url: "register",
          method: "POST",
          body: credentials
        };
      },
      invalidatesTags: ["User"]
    }),
    resetPassword: builder.mutation({
      query: (credentials: { email: string }) => {
        return {
          url: "reset",
          method: "POST",
          body: credentials
        };
      },
      invalidatesTags: ["User"]
    }),
    newPassword: builder.mutation({
      query: (credentials: { newPassword: string; oobCode: string }) => {
        return {
          url: "authAction/resetPassword",
          method: "POST",
          body: credentials
        };
      },
      invalidatesTags: ["User"]
    })
  })
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useNewPasswordMutation
} = piosaasServerApi;
