import { Dispatch } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  AnyAction,
  PayloadAction,
  ThunkDispatch,
  createSlice
} from "@reduxjs/toolkit";

const initialNodeId = "r:0";

type RootStateWithNodeState = {
  nodeState: NodeState;
};

type NodeState = {
  board: string;
  boardGhost: string;
  currentNodeGhostId: string;
  displayNodeId: string;
  flopSubset: string;
  rootBoard: string;
};

const nodeSlice = createSlice({
  name: "nodeState",
  initialState: {
    board: "",
    boardGhost: "",
    currentNodeGhostId: initialNodeId,
    displayNodeId: initialNodeId,
    rootBoard: "",
    flopSubset: ""
  } as NodeState,
  reducers: {
    setBoard(state, action: PayloadAction<string>) {
      state.board = action.payload;
    },
    setBoardAndGhost(state, action: PayloadAction<string>) {
      state.boardGhost = action.payload;
      state.board = action.payload;
    },
    setBoardGhost(state, action: PayloadAction<string>) {
      state.boardGhost = action.payload;
    },
    setDisplayAndGhostId(state, action: PayloadAction<string>) {
      state.currentNodeGhostId = action.payload;
      state.displayNodeId = action.payload;
    },
    setDisplayNodeId(state, action: PayloadAction<string>) {
      state.displayNodeId = action.payload;
    },
    setRootBoard(state, action: PayloadAction<string>) {
      state.board = action.payload;
      state.boardGhost = action.payload;
      state.rootBoard = action.payload;
    }
  }
});

export const {
  setBoard,
  setBoardAndGhost,
  setBoardGhost,
  setDisplayAndGhostId,
  setDisplayNodeId,
  setRootBoard
} = nodeSlice.actions;
export { initialNodeId };
export type { NodeState, RootStateWithNodeState };

export type NodeStateDispatch = Dispatch<AnyAction> &
  ThunkDispatch<RootStateWithNodeState, null, AnyAction>;

export const useNodeStateDispatch = () => useDispatch<NodeStateDispatch>();
export const useNodeStateSelector: TypedUseSelectorHook<RootStateWithNodeState> =
  useSelector;
export default nodeSlice.reducer;
