/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { Fragment } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

import { useSpotsQuery } from "../../services/piosaasTreeApi";
import type { SpotsState } from "../../services/piosaasTreeApi";
import { HandleError, Spinner } from "../../utils";

import RandomBoard from "./RandomBoard";

interface SpotsProps {
  data?: SpotsState;
}

function Boards(props: SpotsProps) {
  return (
    <>
      {props.data?.map((spotBoard, i) => (
        <div className="mt-3 me-2" key={i}>
          <RandomBoard
            spotBoard={spotBoard}
            key={`${spotBoard.spot}_${spotBoard.boards[0]}`}
          />
        </div>
      ))}
    </>
  );
}

function Spots(props: SpotsProps) {
  return (
    <>
      {props.data?.map((spotBoards, i) => (
        <div className="mt-3 me-2" key={i}>
          <SpotSummaryCard
            spot={spotBoards.spot}
            boards={spotBoards.boards}
            key={`spot-card-${spotBoards.spot}}`}
          />
        </div>
      ))}
    </>
  );
}
function SpotSummaryCard({ spot, boards }: { spot: string; boards: string[] }) {
  return (
    <Link className="no-decor" to={`/board/pack/${spot}`}>
      <Card style={{ minWidth: "16rem" }}>
        <Card.Body>
          <Card.Title>{spot}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {boards.length} boards
          </Card.Subtitle>
          {/* <Card.Text></Card.Text> */}
        </Card.Body>
      </Card>
    </Link>
  );
}

function RequireData({
  children,
  isLoading
}: {
  children: JSX.Element;
  isLoading: boolean;
}) {
  return isLoading ? (
    <div className="d-flex flex-md-column justify-content-center">
      <Spinner />
    </div>
  ) : (
    children
  );
}

function Dashboard() {
  const { data, error, isLoading } = useSpotsQuery();
  return (
    <HandleError error={error}>
      <div className="mx-md-3">
        <Row>
          <Col md={{ span: 8, offset: 4 }} lg={{ span: 6, offset: 6 }}>
            <h1>PioSOLVER</h1>
          </Col>
        </Row>
        <Row className="dashboard-section py-3">
          <Col xs={12}>
            <h2 className="mb-0">Spots</h2>
            <div className="text-assistance">
              Explore solutions for a particular spot
            </div>
            <RequireData isLoading={isLoading}>
              <div className="dashboard-section-content">
                <Spots data={data} />
              </div>
            </RequireData>
          </Col>
        </Row>
        <Row className="dashboard-section py-3">
          <Col xs={12}>
            <h2 className="mb-0">Random Boards</h2>
            <div className="text-assistance">A few random boards</div>
            <RequireData isLoading={isLoading}>
              <div className="dashboard-section-content">
                <Boards data={data} />
              </div>
            </RequireData>
          </Col>
        </Row>
      </div>
    </HandleError>
  );
}

export default Dashboard;
