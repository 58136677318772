import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type MetadataState = { version: string };

export const metadataApi = createApi({
  reducerPath: "metadataApi",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  tagTypes: [],
  keepUnusedDataFor: 60 * 5, // 5m
  endpoints: (builder) => ({
    metadata: builder.query<MetadataState, string>({
      // need a dummy parameter so that polling interval can be used :shrug:
      query: (_: string) => {
        return {
          url: "/metadata.json"
        };
      }
    })
  })
});

export const { useMetadataQuery } = metadataApi;

export type { MetadataState };
