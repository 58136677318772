import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

import { Spinner } from "../../utils";

type LoginCredentials = {
  email: string;
  password: string;
};

interface RegisterProps {
  register: (credentials: LoginCredentials) => Promise<unknown>;
  userRegisterError: FetchBaseQueryError | SerializedError | undefined;
  userUpdating: boolean;
}

interface DataError {
  error: string
}

function Register(props: RegisterProps) {
  const userUpdating = false;
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [agree, setAgree] = React.useState(false);
  const [validated, setValidated] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    const form = e.currentTarget as HTMLFormElement;
    const isValid = form.checkValidity();
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (agree && isValid) await props.register({ email, password });
  };

  let error = "";
  if (props.userRegisterError && typeof (props.userRegisterError as FetchBaseQueryError).data === "object" &&
    "error" in ((props.userRegisterError as FetchBaseQueryError).data as DataError))
    error = ((props.userRegisterError as FetchBaseQueryError).data as DataError).error;
  else if (props.userRegisterError)
    error = "Unable to register user";


  return (
    <div>
      <Form
        noValidate
        validated={validated}
        style={{ maxWidth: "400px" }}
        onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="new-email-address">
            Email
          </label>
          <div className="input-group has-validation">
            <input
              autoComplete="email"
              className="form-control"
              id="new-email-address"
              onChange={(e) => setEmail(e.target.value.trim())}
              placeholder="name@example.com"
              required={true}
              type="email"
            />
            <div className="invalid-feedback">
              Please provide a valid email address.
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="new-password" className="form-label">
            New Password
          </label>
          <div className="input-group has-validation">
            <input
              autoComplete="new-password"
              aria-label="new password"
              className="form-control"
              id="new-password"
              onChange={(e) => setPassword(e.target.value.trim())}
              placeholder="Password"
              readOnly={userUpdating}
              required={true}
              type="password"
            />
            <div className="invalid-feedback">Please enter a password.</div>
          </div>
        </div>
        <Form.Group className="mb-3" controlId="termsOfService">
          <Form.Check
            label="I have read and agree to the terms of service"
            onChange={(e) => setAgree(e.target.checked)}
            required={true}
            feedback="You must agree to the terms of service."
            feedbackType="invalid"
            type="checkbox"
          />
        </Form.Group>
        <Form.Group
          className="d-flex flex-row-reverse align-items-center mb-3"
          controlId="submitButton">
          <div>
            <Button variant="primary" type="submit" disabled={userUpdating}>
              {userUpdating ? (
                <Spinner size={16} variant="light" message="Registering..." />
              ) : (
                "Register"
              )}
            </Button>
          </div>
          <div className="mx-2 text-danger">
            {error}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Register;

export type { RegisterProps };
