import type { Node, StreetName } from "../../../utils/nodespace";

interface DealChooserProps extends RunoutsChooserProps {
  flopSubset: string;
  hasFocus: boolean;
  node: Node;

  wideMode?: boolean;
}
interface RunoutsChooserProps {
  board: string;
  streetString: string;
  onCardSelection: (card: string) => void;
}
const streetsToCardIndex: { [key in StreetName]: number } = {
  preflop: 0,
  flop: 3,
  turn: 4,
  river: 5
};

export { streetsToCardIndex };
export type { DealChooserProps, RunoutsChooserProps };
