import Table from "react-bootstrap/Table";

import { useNavigate } from "react-router-dom";

import { FlopFilterDiv } from "../features/view-controls/deals";
import { SpotInfo } from "../services/piosaasTreeApi";
import { sortBoardsByRank } from "../utils/cards";
import RangeSummaries from "./range-summary-grid";

type SpotMetadata = {
  spot: string;
  spotData: SpotInfo;
};
function Spot({ spot, spotData }: { spot: string; spotData: SpotInfo }) {
  return (
    <div className="spot-grid">
      <div className="spot-header">
        <h1>Spot</h1>
      </div>
      <Metadata spot={spot} spotData={spotData} />
      <RangeSummaries spotData={spotData} rectWidth={4} rectHeight={4} />
      <Boards spot={spot} boards={spotData.boards} />
    </div>
  );
}
function Boards({ spot, boards }: { spot: string; boards: string[] }) {
  const navigate = useNavigate();

  const onFlopSelection = (flop: string) => {
    navigate(`/board/pack/${spot}/${flop}`);
  };

  return (
    <div className="spotSummary-boards spot-board">
      <FlopFilterDiv
        board={""}
        flops={sortBoardsByRank(boards, false)}
        onFlopSelection={onFlopSelection}
        showCloseButton={false}
        onClose={() => {
          return;
        }}
      />
    </div>
  );
}
function Metadata({ spot, spotData }: SpotMetadata) {
  return (
    <div className="spot-metadata">
      <h3>{spot}</h3>
      <Table size="sm">
        <tbody>
          <tr>
            <th>Boards</th>
            <td>{spotData.boards.length}</td>
          </tr>
          <tr>
            <th>Pot</th>
            <td>{spotData.pot.join(" ")}</td>
          </tr>
          <tr>
            <th>Effective stack</th>
            <td>{spotData.effective_stack}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Spot;
