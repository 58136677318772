import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { NodeViewerControls, ViewControls } from "../features/view-controls";
import { FocusLocation, setFocus } from "../features/view-controls/viewSlice";

import {
  cardsCompressAndSplit,
  CommunityCards,
  sortBoardsByRank
} from "../utils/cards";

import {
  FlopFilterCard,
  RunoutsSelection
} from "../features/view-controls/deals";
import { CloseButton } from "react-bootstrap";
import { DealActions, Node, NodeTree, StreetName } from "../utils/nodespace";
import {
  setBoard,
  setBoardAndGhost,
  setBoardGhost,
  setDisplayAndGhostId,
  useNodeStateSelector
} from "../features/view-controls/nodeSlice";
import {
  SpotInfo,
  useSpotStateSelector
} from "../features/view-controls/spotSlice";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

function PotSummary({ node }: { node: Node }) {
  const potString = `${node.pot.join(" ")} (${node.pot.reduce(
    (a, b) => a + b
  )})`;
  return <span>{potString}</span>;
}

// function DecisionSummary({ node }) {
//   const type = !["OOP", "IP"].includes(node.type)
//     ? node.type
//     : `${node.type} Decision`;
//   return <span>{type}</span>;
// }

function NodeSummary({
  node,
  board,
  setTransferNodeState,
  spot,
  spotInfo
}: ControlsProps) {
  const [showFlopDialog, setShowFlopDialog] = useState(false);
  const onCloseFlopDialog = (flopSelected: boolean) => {
    setShowFlopDialog(false);
    if (flopSelected) setTransferNodeState(true);
  };

  // const nodeId = nodeIdToSolverNodeId(node.id, board);
  return (
    <>
      <h3 className="pb-1">
        <Button
          className="btn btn-dark btn-lg"
          type="button"
          onClick={() => setShowFlopDialog(true)}>
          <CommunityCards cards={board} />
        </Button>
      </h3>
      {showFlopDialog && (
        <PopoverFlopFilter
          board={board}
          spot={spot}
          spotInfo={spotInfo}
          onCloseFlopDialog={onCloseFlopDialog}
        />
      )}
      <div>
        <PotSummary node={node as Node} />
        {/*
        <DecisionSummary node={node} /> */}
      </div>
    </>
  );
}

const arrHasDuplicates = (arr: unknown[]) => {
  const newSet = new Set(arr);
  return newSet.size !== arr.length;
};

type StreetsInt = 0 | 3 | 4 | 5;
const streets: { [key in StreetsInt]: StreetName } = {
  0: "preflop",
  3: "flop",
  4: "turn",
  5: "river"
};

const getStreetNodeId = (
  nodeTree: NodeTree,
  displayNodeId: string,
  street: StreetsInt
) => {
  const code = DealActions[streets[street].toUpperCase()];
  let currentNode = nodeTree[displayNodeId];
  while (currentNode.action.code !== code) {
    if (!currentNode.parent) break;
    currentNode = currentNode.parent;
  }
  return currentNode.id;
};

const replaceFlopInBoard = (board: string, flop: string) => {
  if (board.length <= flop.length) return flop;
  return flop + board.substring(flop.length);
};

const removeDuplicatesFromBoardArray = (boardArr: string[]) => {
  const newBoardArr = [...boardArr];

  while (arrHasDuplicates(newBoardArr)) newBoardArr.pop();

  return newBoardArr;
};

type PopoverSelector = {
  board: string;
  currentNodeGhostId: string;
  selectedBoard: string;
  setSelectedBoard: (newBoard: string) => void;
};
function PopoverFlopRunoutSelector({
  board,
  currentNodeGhostId,
  selectedBoard,
  setSelectedBoard
}: PopoverSelector) {
  return (
    <div className="d-flex justify-content-end gap-4">
      {!currentNodeGhostId.includes(DealActions.TURN) ? null : (
        <div>
          <RunoutsSelection
            selectedBoard={selectedBoard}
            streetString="turn"
            setSelectedBoard={(newSelectedBoard) => {
              const newBoard = removeDuplicatesFromBoardArray(
                cardsCompressAndSplit(newSelectedBoard)
              );
              setSelectedBoard(newBoard.join(""));
            }}
            board={board}
          />
        </div>
      )}
      {!currentNodeGhostId.includes(DealActions.RIVER) ? null : (
        <div>
          <RunoutsSelection
            selectedBoard={selectedBoard}
            streetString="river"
            setSelectedBoard={(newSelectedBoard) =>
              setSelectedBoard(newSelectedBoard)
            }
            board={board}
          />
        </div>
      )}
    </div>
  );
}

type PopoverFlopFilterProps = {
  board: string;
  spot: string;
  spotInfo: SpotInfo;
  onCloseFlopDialog: (flopSelected: boolean) => void;
};
function PopoverFlopFilter({
  board,
  spot,
  spotInfo,
  onCloseFlopDialog
}: PopoverFlopFilterProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { boardGhost, currentNodeGhostId } = useNodeStateSelector(
    (state) => state.nodeState
  );
  const { nodeTree } = useSpotStateSelector((state) => state.spotState);
  const [selectedBoard, setSelectedBoard] = useState(board);
  useEffect(() => {
    setSelectedBoard(board);
  }, [board]);
  const onCloseClick = () => onCloseFlopDialog(false);

  const onGo = () => {
    const street = cardsCompressAndSplit(board).length;
    const selectedBoardArr = cardsCompressAndSplit(selectedBoard);
    const newBoard = selectedBoardArr.slice(0, street);
    let selectedGhostBoardArr = cardsCompressAndSplit(boardGhost);
    const newFlop = newBoard.join("").substring(0, 6);
    const flopChanged = newFlop !== board.substring(0, 6);
    for (let i = 0; i < selectedBoardArr.length; i++)
      selectedGhostBoardArr[i] = selectedBoardArr[i];

    if (arrHasDuplicates(selectedGhostBoardArr)) {
      selectedGhostBoardArr = removeDuplicatesFromBoardArray(
        selectedGhostBoardArr
      );
      const newNodeId = getStreetNodeId(
        nodeTree,
        currentNodeGhostId,
        selectedGhostBoardArr.length as StreetsInt
      );
      dispatch(setBoardAndGhost(selectedGhostBoardArr.join("")));
      dispatch(setDisplayAndGhostId(newNodeId));
      if (flopChanged) {
        onCloseFlopDialog(true);
        navigate(`/board/pack/${spot}/${newFlop}`);
      }
      return;
    }
    if (flopChanged) {
      onCloseFlopDialog(true);
      navigate(`/board/pack/${spot}/${newFlop}`);
    }
    dispatch(setBoard(newBoard.join("")));
    dispatch(setBoardGhost(selectedGhostBoardArr.join("")));
  };

  return (
    <div className="card board-change-popover">
      <div className="text-end p-2" onClick={onCloseClick}>
        <CloseButton />
      </div>
      <div className="d-flex">
        <div>
          <FlopFilterCard
            board={selectedBoard}
            flops={sortBoardsByRank(spotInfo.boards, false)}
            onFlopSelection={(flop) => {
              let boardNewFlop = cardsCompressAndSplit(
                replaceFlopInBoard(selectedBoard, flop)
              );
              if (arrHasDuplicates(boardNewFlop))
                boardNewFlop = removeDuplicatesFromBoardArray(boardNewFlop);
              setSelectedBoard(boardNewFlop.join(""));
            }}
            hideBorder={true}
            showCloseButton={false}
            onClose={() => {
              return;
            }}
          />
        </div>
        <div>
          <div className="pb-2 pt-2 px-2">
            <PopoverFlopRunoutSelector
              board={board}
              currentNodeGhostId={currentNodeGhostId}
              selectedBoard={selectedBoard}
              setSelectedBoard={setSelectedBoard}
            />
          </div>
        </div>
        <div>
          <div className="pb-2 pt-2 px-2 text-end">
            <Button
              className="btn-sm"
              disabled={selectedBoard === board}
              onClick={() => {
                onGo();
              }}>
              Go
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function focusChangeHandler(
  dispatch: Dispatch<AnyAction>,
  location: FocusLocation
) {
  return (e: unknown) => {
    dispatch(setFocus(location));
  };
}

type ControlsProps = {
  board: string;
  node: Node | null;
  setTransferNodeState: React.Dispatch<React.SetStateAction<boolean>>;
  spot: string;
  spotInfo: SpotInfo;
};
function Controls({
  board,
  node,
  setTransferNodeState,
  spot,
  spotInfo
}: ControlsProps) {
  const dispatch = useDispatch();
  if (node == null) return <div></div>;

  return (
    <>
      <div
        className="board-node-summary"
        onMouseDown={focusChangeHandler(dispatch, "board/controls")}>
        <NodeSummary
          node={node}
          board={board}
          setTransferNodeState={setTransferNodeState}
          spot={spot}
          spotInfo={spotInfo}
        />
      </div>
      <div
        className="board-view-controls"
        onMouseDown={focusChangeHandler(dispatch, "board/controls")}>
        <ViewControls node={node} />
      </div>
      <div
        className="board-node-controls"
        onMouseEnter={focusChangeHandler(dispatch, "tree")}
        onMouseDown={focusChangeHandler(dispatch, "tree")}>
        <NodeViewerControls />
      </div>
    </>
  );
}

export default Controls;
