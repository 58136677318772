import { Link as RRLink, LinkProps } from "react-router-dom";

import { nodeIdToSolverNodeId } from "./solverUtil";

function Link(props: LinkProps) {
  return <RRLink className="nav-link" {...props} />;
}

function linkToNode(
  spot: string,
  rootBoard: string,
  displayNodeId: string,
  board: string
) {
  const nodeTransformed = nodeIdToSolverNodeId(displayNodeId, board).replaceAll(
    ":",
    "."
  );
  return `${window.location.origin}/board/pack/${spot}/${rootBoard}?node=${nodeTransformed}`;
}

export { Link, linkToNode };
