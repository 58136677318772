import React, { useEffect } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { DashSquareFill, Icon, PlusSquareFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import {
  useCreateGrantMutation,
  useGrantsInfoQuery
} from "../../services/piosaasGrantsApi";
import Spinner from "../../utils/spinner/Spinner";
import { useTestUserMutation } from "../../services/piosaasStorageApi";
import { IUser } from "../../services/piosaasStorageApi";
import { useSpotsQuery } from "../../services/piosaasTreeApi";

function Headers() {
  return (
    <>
      <h1 className="mb-0">New Grant</h1>
      <div className="text-assistance mb-5">
        Give other users access to your spots
      </div>
    </>
  );
}

type ToggledList = { [key: string]: boolean };
function getToggledListItems(list: ToggledList, toggled: boolean) {
  return Object.keys(list).filter((key) => list[key] === toggled);
}
function NewGrant() {
  const {
    data: spots,
    isLoading: isSpotsQueryLoading,
    isFetching: isSpotsQueryFetching
  } = useSpotsQuery();
  const { data: userGrants } = useGrantsInfoQuery();
  const [createGrant, { isError: isCreateGrantError }] =
    useCreateGrantMutation();
  const [testUser, { isError: isTestUserError }] = useTestUserMutation();
  const [validated, setValidated] = React.useState(true);
  const [grantName, setGrantName] = React.useState("");
  const [spotList, setSpotList] = React.useState<ToggledList>({});
  const [username, setUsername] = React.useState("");
  const [grantUsers, setGrantUsers] = React.useState<IUser[]>([]);

  useEffect(() => {
    if (!spots) return;
    const newSpotList = { ...spotList };
    for (const spot of spots) newSpotList[spot.spot] = false;

    setSpotList(newSpotList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spots]);

  useEffect(() => {
    if (!grantName) return setValidated(false);
    if (!userGrants) return setValidated(true);

    return setValidated(!userGrants.grantor.includes(grantName));
  }, [grantName, userGrants, validated]);

  const handleSubmit = async (e: React.FormEvent) => {
    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false || !validated) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!validated) return;
    setValidated(true);

    const createBody = {
      name: grantName,
      recipients: grantUsers.map((user) => user.userId),
      spots: getToggledListItems(spotList, true)
    };
    await createGrant({ body: createBody });
  };

  if (isSpotsQueryLoading || isSpotsQueryFetching) return <Spinner />;

  const testUserErrorMessage = "User does not exist";
  const grantExistsErrorMessage = "Grant with specified name already exists";
  const addUser = async () => {
    if (username.length > 0) {
      try {
        const { userId, displayName }: IUser = await testUser(
          username
        ).unwrap();
        if (grantUsers.some((user) => user.userId === userId)) return;

        setGrantUsers([...grantUsers, { userId, displayName }]);
      } catch (e) {
        return;
      }
    }
  };
  const removeUser = (userDisplayNameToRemove: string) => {
    setGrantUsers(
      grantUsers.filter((user) => user.displayName !== userDisplayNameToRemove)
    );
  };

  const addSpot = (spotName: string) => {
    setSpotList({ ...spotList, [spotName]: true });
  };
  const removeSpot = (spotName: string) => {
    setSpotList({ ...spotList, [spotName]: false });
  };

  return (
    <Row>
      <Col sm={12} md={4} lg={3}>
        <Headers />
      </Col>
      <Col sm={12} md={8} lg={9}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <FormGroup className="mb-3" controlId="grant-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => {
                setGrantName(e.target.value.trim());
              }}
              value={grantName}
              placeholder="My Grant"
              required={true}
              isValid={grantName != null && grantName.length > 0}
              isInvalid={userGrants && userGrants.grantor.includes(grantName)}
            />
            <Form.Text className="text-muted">
              Any name you want to use for the grant
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {!grantName
                ? "Please provide a grant name."
                : grantExistsErrorMessage}
            </Form.Control.Feedback>
          </FormGroup>
          <h4>Spots</h4>
          <div className="d-flex flex-column flex-md-row mb-1">
            <SettingsCardOnValues
              className="mb-2 me-md-2"
              icon={DashSquareFill}
              header="Grant spots"
              list={getToggledListItems(spotList, true).sort()}
              onIconClick={removeSpot}
            />
            <SettingsCardAvailableValues
              className="mb-2"
              icon={PlusSquareFill}
              header="Available spots"
              list={getToggledListItems(spotList, false).sort()}
              onIconClick={addSpot}
            />
          </div>
          <h4>Users</h4>
          <FormGroup className="mb-3" controlId="new-grant-user">
            <Form.Label>Username</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setUsername(e.target.value.trim());
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    await addUser();
                  }
                }}
                value={username}
                placeholder="Username"
                required={false}
              />
              <Button variant="secondary" onClick={addUser}>
                Add
              </Button>
            </InputGroup>
            <Form.Text className="text-muted">
              Enter a username to give them access to these spots
            </Form.Text>
            <p
              className="form-label text-danger mb-4"
              style={{ fontSize: "large" }}>
              {isTestUserError && testUserErrorMessage}
            </p>
          </FormGroup>
          <SettingsCardOnValues
            icon={DashSquareFill}
            list={grantUsers.map((user) => user.displayName).sort()}
            header="Users with access"
            onIconClick={removeUser}
          />
          <CancelUpdate handleSubmit={handleSubmit} />
          <p
            className="form-label text-danger mb-4"
            style={{ fontSize: "large" }}>
            {isCreateGrantError && "Error while creating grant"}
          </p>
        </Form>
      </Col>
    </Row>
  );
}

interface SettingsCardProps {
  className?: string;
  header: string;
  icon: Icon;
  list: string[];
  onIconClick?: (item: string) => void;
}

function SettingsCardOnValues(props: SettingsCardProps) {
  const className = props.className
    ? `${props.className} settings-card on-values flex-md-fill`
    : "settings-card on-values flex-md-fill";
  return <SettingsCard {...props} className={className} />;
}

function SettingsCardAvailableValues(props: SettingsCardProps) {
  const className = props.className
    ? `${props.className} settings-card available-values flex-md-fill`
    : "settings-card available-values flex-md-fill";
  return <SettingsCard {...props} bg="secondary" className={className} />;
}

interface SettingsCardInternalProps extends SettingsCardProps {
  bg?: string;
}

function SettingsCard(props: SettingsCardInternalProps) {
  const onIconClick = props.onIconClick
    ? props.onIconClick
    : (_item: string) => {
        return;
      };

  return (
    <Card className={props.className}>
      <Card.Header>{props.header}</Card.Header>
      <Card.Body>
        {props.list.map((item, i) => (
          <div
            key={i}
            className={`d-flex justify-content-between align-items-center ${
              props.onIconClick ? "clickable" : ""
            }`}>
            <span>{item}</span>
            <div onClick={() => onIconClick(item)}>
              <props.icon width="18" height="18" />
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}

function CancelUpdate({
  handleSubmit
}: {
  handleSubmit: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const creatingGrant = false;
  const navigate = useNavigate();
  const cancel = () => {
    navigate("/grants");
  };
  return (
    <Form.Group
      className="d-flex flex-row-reverse align-items-center mb-3 mt-3"
      controlId="submitButton">
      <Button
        variant="primary"
        type="submit"
        className="ps-5 pe-5"
        onClick={handleSubmit}>
        {creatingGrant ? (
          <Spinner size={16} variant="light" message="Waiting for reset..." />
        ) : (
          "Create"
        )}
      </Button>
      <Button variant="secondary" onClick={cancel} className="ps-5 pe-5 me-3">
        Cancel
      </Button>
    </Form.Group>
  );
}
export default NewGrant;
export type { ToggledList };
