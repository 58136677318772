import { Fragment } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Help() {
  return (
    <Fragment>
      <Row>
        <Col md={8} lg={6}>
          <div>Help text to come</div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Help;
