import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../utils";
import useUser from "./useUser";
type LoginCredentials = {
  newPassword: string;
  oobCode: string;
};
function SetNewPasswordPrompt() {
  const { userUpdating, userResetPasswordError, newPassword } = useUser();
  return (
    <div className="mx-md-3">
      <h1 style={{ width: 360, fontSize: "xxx-large" }}>PioCloud</h1>
      <Row>
        <Col md={8} lg={6}>
          <h2 className="fs-4">Reset your password:</h2>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <NewPassword
            newPassword={newPassword}
            userNewPasswordError={userResetPasswordError}
            userUpdating={userUpdating}
          />
        </Col>
      </Row>
    </div>
  );
}

interface NewPasswordProps {
  newPassword: (credentials: LoginCredentials) => Promise<unknown>;
  userNewPasswordError: FetchBaseQueryError | SerializedError | undefined;
  userUpdating: boolean;
}
function NewPassword(props: NewPasswordProps) {
  const search = new URL(document.URL.replace("#", "?")).search;
  const searchParams = new URLSearchParams(search);
  const userUpdating = false;
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const action = searchParams.get("mode") ?? searchParams.get("type");
  if (action !== "resetPassword" && action !== "recovery") {
    navigate("/");
    return null;
  }
  const handleSubmit = async (e: React.FormEvent) => {
    const form = e.currentTarget as HTMLFormElement;
    const isValid = form.checkValidity();
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);
    if (isValid) {
      await props.newPassword({
        newPassword: password,
        oobCode:
          searchParams.get("oobCode") ?? searchParams.get("access_token") ?? ""
      });
      navigate("/");
    }
  };
  return (
    <div>
      <Form
        noValidate
        validated={validated}
        style={{ maxWidth: "400px" }}
        onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="new-password" className="form-label">
            New Password:
          </label>
          <div className="input-group has-validation">
            <input
              autoComplete="new-password"
              aria-label="new password"
              className="form-control"
              id="new-password"
              onChange={(e) => setPassword(e.target.value.trim())}
              placeholder="Password"
              readOnly={userUpdating}
              required={true}
              type="password"
            />
            <div className="invalid-feedback">Please enter a password.</div>
          </div>
        </div>
        <Form.Group
          className="d-flex flex-row-reverse align-items-center mb-3"
          controlId="submitButton">
          <div>
            <Button variant="primary" type="submit" disabled={userUpdating}>
              {userUpdating ? (
                <Spinner size={16} variant="light" message="Registering..." />
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
          <div className="mx-2 text-danger">
            {props.userNewPasswordError ? "Could not register new user." : null}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

function AuthAction() {
  return <SetNewPasswordPrompt />;
}

export default AuthAction;
