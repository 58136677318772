import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

function Error({ error }: { error?: FetchBaseQueryError | SerializedError }) {
  return (
    <Row>
      <Col md={6}>
        <h1>Error</h1>
        <div>
          We are encountering some technical difficulties. Please try again in a
          few minutes.
        </div>
      </Col>
    </Row>
  );
}

function HandleError({
  children,
  error
}: {
  children: JSX.Element;
  error?: FetchBaseQueryError | SerializedError;
}) {
  if (error == null) return children;
  return <Error error={error} />;
}

export default HandleError;
