import React from "react";
import Button from "react-bootstrap/Button";

import { cardsCompressAndSplit } from "../../../utils/cards";
import {
  Action,
  DisplayStringLength,
  Node,
  NodeTree,
  colorsForAction,
  displayStringForNode,
  shortenBoardToStreet,
  streetStringForNode
} from "../../../utils/nodespace";

import { DealChooser, streetsToCardIndex } from "../deals";

import {
  setDisplayAndGhostId,
  setDisplayNodeId,
  setBoard,
  setBoardAndGhost,
  useNodeStateSelector,
  setBoardGhost
} from "../nodeSlice";
import type { NodeStateDispatch } from "../nodeSlice";
import { onNodeChanged } from "../viewSlice";
import type { ViewControlsDispatch } from "../viewSlice";

import { BetSizeDisplay } from "../../../utils/preferences";

// import { Link } from "../../utils/nav";
const availableStreets = [0, 3, 4, 5];

function boardAfterDeal(
  board: string,
  chosenCards: string[],
  cardIndex: number
): string {
  const boardArray = cardsCompressAndSplit(board);
  if (boardArray.length === cardIndex)
    return `${board}${chosenCards.reduce((a, b) => a + b)}`;
  while (boardArray.length > cardIndex + 1) boardArray.pop();
  const replacedCount = chosenCards.length;

  // turn/river, else flop
  if (replacedCount === 1) boardArray[cardIndex] = chosenCards[0];
  else for (let i = 0; i < replacedCount; i++) boardArray[i] = chosenCards[i];

  return boardArray.reduce((prev, cur) => prev + cur);
}

function onSelectCurrentNodeGhost(
  dispatch: NodeStateDispatch,
  allNodes: NodeTree,
  board: string,
  value: string,
  viewControlsDispatch: ViewControlsDispatch
) {
  dispatch(setDisplayAndGhostId(value));
  dispatch(setBoardAndGhost(shortenBoardToStreet(allNodes[value], board)));
  viewControlsDispatch(onNodeChanged());
}

function onSelectDisplayNode(
  dispatch: NodeStateDispatch,
  allNodes: NodeTree,
  board: string,
  value: string,
  viewControlsDispatch: ViewControlsDispatch
) {
  dispatch(setDisplayNodeId(value));
  dispatch(setBoard(shortenBoardToStreet(allNodes[value], board)));
  viewControlsDispatch(onNodeChanged());
}

interface CardSelectionProps {
  board: string;
  card: string;
  dispatch: NodeStateDispatch;
  node: Node;
  streetString: string;
  boardGhost?: string;
}

function onNewCardSelection({
  board,
  card,
  dispatch,
  node,
  streetString
}: CardSelectionProps) {
  const cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  const newBoard = boardAfterDeal(board, [card], cardIndex);
  dispatch(setBoardAndGhost(newBoard));
  dispatch(setDisplayAndGhostId(node.children[0]));
}
const cardAppearsLaterInGhost = (
  card: string,
  boardGhost: string,
  cardIndex: number
) => {
  const boardArray = cardsCompressAndSplit(boardGhost);
  let index = cardIndex + 1;
  while (boardArray[index]) {
    if (boardArray[index] === card) return true;
    index++;
  }
  return false;
};

const updatedGhostBoard = (board: string, card: string, cardIndex: number) => {
  const ghostBoardArray = cardsCompressAndSplit(board);
  ghostBoardArray[cardIndex] = card;
  return ghostBoardArray.join("");
};

function onBoardUpdate({
  board,
  boardGhost,
  card,
  dispatch,
  node,
  streetString
}: CardSelectionProps) {
  let cardIndex = streetsToCardIndex[streetString.toLowerCase()] - 1;
  if (cardIndex === -1) cardIndex = 0;
  const newBoard = boardAfterDeal(board, [card], cardIndex);
  if (boardGhost && cardAppearsLaterInGhost(card, boardGhost, cardIndex)) {
    dispatch(setBoardAndGhost(newBoard));
    dispatch(setDisplayAndGhostId(node.children[0]));
  } else {
    const newGhostBoard = updatedGhostBoard(board, card, cardIndex);
    dispatch(setBoard(newBoard));
    dispatch(setBoardGhost(newGhostBoard));
    dispatch(setDisplayNodeId(node.children[0]));
  }
}

function styleForActionButton(action: Action): React.CSSProperties {
  const actionColor = colorsForAction(action);
  return {
    backgroundColor: actionColor.background,
    color: actionColor.text
  };
}

function ActionNodeBreadcrumbButton({
  onClick,
  node,
  useFullLabel,
  betSizeDisplay
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  node: Node;
  useFullLabel: boolean;
  betSizeDisplay: BetSizeDisplay;
}) {
  const displayNodeId = useNodeStateSelector(
    (state) => state.nodeState.displayNodeId
  );
  if (node == null) return null;
  const action = node.action;
  const betDisplay = useFullLabel ? betSizeDisplay : "Hide";
  const displayLength = useFullLabel
    ? DisplayStringLength.FULL
    : DisplayStringLength.SHORT;
  const label = displayStringForNode(node, displayLength, betDisplay);
  const className =
    displayNodeId === node.id ? "active-tree-node" : "non-active-tree-node";
  return (
    <Button
      className={className}
      size="sm"
      onClick={onClick}
      style={styleForActionButton(action)}>
      {label}
    </Button>
  );
}

type DealControlProps = {
  allNodes: NodeTree;
  board: string;
  clearGhostNodes: boolean;
  dispatch: NodeStateDispatch;
  flopSubset: string;
  hasFocus: boolean;
  node: Node;
  wideMode: boolean;
};

function DealControl(props: DealControlProps) {
  const {
    allNodes,
    board,
    clearGhostNodes,
    dispatch,
    flopSubset,
    hasFocus,
    node,
    wideMode
  } = props;
  const firstChild = allNodes[node.children[0]];
  const onCardSelection = clearGhostNodes
    ? (card: string) => {
        onNewCardSelection({ board, card, dispatch, node, streetString });
      }
    : (card: string) => {
        onBoardUpdate({ board, card, dispatch, node, streetString });
      };
  const style = wideMode ? {} : { maxWidth: "250px" };
  const streetString = streetStringForNode(firstChild);
  return (
    <div className="mb-1" style={style}>
      <DealChooser
        key={`${streetString}Inline`}
        board={board}
        onCardSelection={onCardSelection}
        hasFocus={hasFocus}
        node={node}
        flopSubset={flopSubset}
        streetString={streetString}
        wideMode={wideMode}
      />
    </div>
  );
}

export {
  ActionNodeBreadcrumbButton,
  DealControl,
  availableStreets,
  onSelectCurrentNodeGhost,
  onSelectDisplayNode,
  styleForActionButton
};
