import React, { ForwardedRef, MouseEvent, useEffect, useRef } from "react";

import Button from "react-bootstrap/Button";

import {
  DisplayStringLength,
  Node,
  NodeTree,
  NodeTypes,
  displayStringForNode
} from "../../../utils/nodespace";
import { BetSizeDisplay } from "../../../utils/preferences";

import { useNodeStateDispatch, useNodeStateSelector } from "../nodeSlice";
import { useSpotStateSelector } from "../spotSlice";
import { useViewControlsDispatch } from "../viewSlice";

import {
  DealControl,
  onSelectCurrentNodeGhost,
  onSelectDisplayNode,
  styleForActionButton
} from "./node";

type SelectorQuickControlProps = {
  allNodes: NodeTree;
  board: string;
  node: Node;
  selectedNode?: Node;
  betSizeDisplay: BetSizeDisplay;
};

function isFrontierNavigation(displayNodeId: string, currentNodeId?: string) {
  return currentNodeId === displayNodeId;
}

interface ActionButtonProps extends SelectorQuickControlProps {
  childId: string;
}

const ActionButton = React.forwardRef(
  (props: ActionButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { allNodes, board, childId, selectedNode } = props;
    const dispatch = useNodeStateDispatch();
    const viewControlsDispatch = useViewControlsDispatch();
    const isSelected = selectedNode?.id === childId;
    const className = isSelected ? "mb-1 fw-bold" : "mb-1";

    const handler = isSelected
      ? (_e: MouseEvent) =>
          onSelectDisplayNode(
            dispatch,
            allNodes,
            board,
            childId,
            viewControlsDispatch
          )
      : (_e: MouseEvent) =>
          onSelectCurrentNodeGhost(
            dispatch,
            allNodes,
            board,
            childId,
            viewControlsDispatch
          );

    return (
      <Button
        className={className}
        id={childId}
        onClick={handler}
        ref={ref}
        size="sm"
        style={styleForActionButton(allNodes[childId].action)}
        value={childId}>
        {displayStringForNode(
          allNodes[childId],
          DisplayStringLength.FULL,
          props.betSizeDisplay
        )}
      </Button>
    );
  }
);

interface ActionChooserProps extends SelectorQuickControlProps {
  hasFocus: boolean;
  isSmallViewport: boolean;
}

function ActionChooser(props: ActionChooserProps) {
  const { node, hasFocus, isSmallViewport, selectedNode } = props;

  if (node.type === NodeTypes.END_NODE) return null;
  const children = [...node.children];
  const otherChildren = children.filter(
    (childId) => childId !== selectedNode?.id
  );
  const selectedChildren = children.filter(
    (childId) => childId === selectedNode?.id
  );
  const childButton = (childId: string, i: number) => (
    <ActionButton key={i} childId={childId} {...props} />
  );

  const ocClass =
    hasFocus || !isSmallViewport ? "d-flex flex-column" : "d-none";
  const style = isSmallViewport
    ? undefined
    : { height: "100%", overflow: "auto" };

  return (
    <div
      className="mx-1 px-1 pb-1 d-flex flex-column node-chooser"
      style={style}>
      <div>{selectedChildren.map(childButton)}</div>
      <div className={ocClass}>{otherChildren.map(childButton)}</div>
    </div>
  );
}

function NodeChooser(props: {
  betSizeDisplay: BetSizeDisplay;
  board: string;
  hasFocus: boolean;
  isSmallViewport: boolean;
  selectedNode?: Node;
}) {
  const flopSubset = useNodeStateSelector(
    (state) => state.nodeState.flopSubset
  );
  const { currentNodeGhostId, displayNodeId } = useNodeStateSelector(
    (state) => ({
      currentNodeGhostId: state.nodeState.currentNodeGhostId,
      displayNodeId: state.nodeState.displayNodeId
    })
  );
  const atFrontier = isFrontierNavigation(displayNodeId, currentNodeGhostId);
  const allNodes = useSpotStateSelector((state) => state.spotState.nodeTree);
  const dispatch = useNodeStateDispatch();
  const node = allNodes[displayNodeId];

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const elt = ref.current;
    elt?.scrollIntoView({ block: "end", inline: "nearest" });
  }, [node]);

  return (
    <div className="node-action-viewer h-100" ref={ref}>
      {node.type === NodeTypes.SPLIT ? (
        <DealControl
          allNodes={allNodes}
          board={props.board}
          clearGhostNodes={atFrontier}
          dispatch={dispatch}
          flopSubset={flopSubset}
          hasFocus={props.hasFocus}
          node={node}
          wideMode={false}
        />
      ) : (
        <ActionChooser {...props} allNodes={allNodes} node={node} />
      )}
    </div>
  );
}

export default NodeChooser;

export { isFrontierNavigation };
