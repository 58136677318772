import { MouseEventHandler, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import CloseButton from "react-bootstrap/CloseButton";

import { useDispatch } from "react-redux";

import {
  cardsCompress,
  CommunityCards,
  ranks,
  suits
} from "../../../utils/cards";
import { CardSuit } from "../../../utils/cardTypes";
import type { Node } from "../../../utils/nodespace";
import { parseFlopSubset } from "../../../utils/solverUtil";
import { setBoardAndGhost, setDisplayAndGhostId } from "../nodeSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import useMediaQuery, {
  mediaBreakpoints
} from "../../../utils/hooks/useMediaQuery";

type FlopSelectionProps = {
  flop: string;
  onClick: (flop: string) => void;
  board?: string;
};

type FlopSelectionDisplayProps = {
  board: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

interface FlopFilterSpecificProps {
  board: string;
  flops: string[];
  onFlopSelection: (flop: string) => void;
  showCloseButton: boolean;
  onClose: () => void;
  hideBorder?: boolean;
}

type FlopModalDialogProps = {
  backButton?: JSX.Element;
  board?: string;
  node: Node;
  flops: string;
  useCardStyle?: boolean;
};

interface FlopFilterAnyProps extends FlopFilterSpecificProps {
  styling: "Card" | "Div";
  initialFlopsShowCount: number;
  flopCountIncrease: number;
}

type FlopFilterContentProps = {
  showCloseButton: boolean;
  flops: string[];
  flopStringsFiltered: string[];
  onCloseClick: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  flopsToLoad: string[];
  getNextRows: () => void;
  onFlopSelection: (flop: string) => void;
  board: string;
  hideBorder: boolean;
};

function FlopSelection({ flop, onClick, board }: FlopSelectionProps) {
  const style = board?.startsWith(flop)
    ? `selected suit-h-borderColor`
    : "normal";
  return (
    <div onClick={() => onClick(flop)} className={`selection-card-${style}`}>
      <CommunityCards cards={flop} />
    </div>
  );
}

function FlopInline({
  backButton,
  board,
  node,
  flops,
  useCardStyle
}: FlopModalDialogProps) {
  const flopStrings = parseFlopSubset(flops).map(
    (flopWeight) => flopWeight.flop
  );

  const dispatch = useDispatch();

  const onCardSelection = (flop: string) => {
    dispatch(setBoardAndGhost(flop));
    dispatch(setDisplayAndGhostId(node.children[0]));
  };

  const rows = flopStrings.map((flop) => (
    <Row key={`row-${flop}`}>
      <FlopSelection board={board} flop={flop} onClick={onCardSelection} />
    </Row>
  ));

  return useCardStyle ? (
    <Card className="deal-inline">
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>Select Flop</Card.Title>
        <div>{backButton}</div>
      </Card.Header>
      <Card.Body>{rows}</Card.Body>
    </Card>
  ) : (
    <div className="p-1" style={{ minWidth: "120px" }}>
      {rows}
    </div>
  );
}

function FlopFilterDiv(props: FlopFilterSpecificProps) {
  return (
    <FlopFilterContent
      flopCountIncrease={20}
      initialFlopsShowCount={40}
      styling="Div"
      {...props}
    />
  );
}

function FlopFilterCard(props: FlopFilterSpecificProps) {
  return (
    <FlopFilterContent
      flopCountIncrease={20}
      initialFlopsShowCount={20}
      styling="Card"
      {...props}
    />
  );
}

function FlopFilterContent({
  board,
  flops,
  onFlopSelection,
  showCloseButton,
  onClose,
  styling,
  initialFlopsShowCount,
  flopCountIncrease,
  hideBorder
}: FlopFilterAnyProps) {
  const [textFilters, setTextFilter] = useState([] as string[]);
  const [flopStringsFiltered, setFlopStringsFiltered] = useState([...flops]);
  const [flopsToLoad, setFlopsToLoad] = useState(
    [...flopStringsFiltered].splice(0, initialFlopsShowCount)
  );

  const resetView = () => {
    setFlopStringsFiltered([...flops]);
    setFlopsToLoad([...flopStringsFiltered].splice(0, initialFlopsShowCount));
  };

  useEffect(() => {
    const newFlopStringsFiltered =
      textFilters.length === 0
        ? flops
        : flops.filter((flop) => evaluateTextFilter(flop, textFilters));
    setFlopStringsFiltered(newFlopStringsFiltered);
    setFlopsToLoad(
      [...newFlopStringsFiltered].splice(
        0,
        Math.max(initialFlopsShowCount, flopsToLoad.length)
      )
    );
  }, [textFilters, flops, flopsToLoad.length, initialFlopsShowCount]);

  const getNextRows = () => {
    const newRowsCount = Math.min(
      flopsToLoad.length + flopCountIncrease,
      flopStringsFiltered.length
    );
    setFlopsToLoad([...flopStringsFiltered].splice(0, newRowsCount));
  };
  const onCloseClick = () => {
    onClose();
    resetView();
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTextFilter(splitFlopFilter(e.target.value));

  const contentProps = {
    showCloseButton,
    flops,
    flopStringsFiltered,
    onCloseClick,
    onInputChange,
    flopsToLoad,
    getNextRows,
    onFlopSelection,
    board,
    hideBorder
  } as FlopFilterContentProps;

  return styling === "Card" ? (
    <FlopFilterCardContent {...contentProps} />
  ) : (
    <FlopFilterDivContent {...contentProps} />
  );
}

function FlopFilterCardContent({
  showCloseButton,
  flops,
  flopStringsFiltered,
  onCloseClick,
  onInputChange,
  flopsToLoad,
  getNextRows,
  onFlopSelection,
  board,
  hideBorder
}: FlopFilterContentProps) {
  const borderStyle = hideBorder ? "border-0" : "";
  return (
    <Card className={borderStyle}>
      <Card.Header className={borderStyle}>
        <FlopHeader
          showCloseButton={showCloseButton}
          flops={flops}
          flopStringsFiltered={flopStringsFiltered}
          onCloseClick={() => onCloseClick()}
          onInputChange={onInputChange}
        />
      </Card.Header>
      <Card.Body className={borderStyle}>
        <BoardList
          flopsToLoad={flopsToLoad}
          getNextRows={getNextRows}
          flopStringsFiltered={flopStringsFiltered}
          onFlopSelection={onFlopSelection}
          board={board}
          limitHeight={true}
        />
      </Card.Body>
    </Card>
  );
}
function FlopFilterDivContent({
  showCloseButton,
  flops,
  flopStringsFiltered,
  onCloseClick,
  onInputChange,
  flopsToLoad,
  getNextRows,
  onFlopSelection,
  board
}: FlopFilterContentProps) {
  return (
    <div>
      <FlopHeader
        showCloseButton={showCloseButton}
        flops={flops}
        flopStringsFiltered={flopStringsFiltered}
        onCloseClick={() => onCloseClick()}
        onInputChange={onInputChange}
      />
      <BoardList
        flopsToLoad={flopsToLoad}
        getNextRows={getNextRows}
        flopStringsFiltered={flopStringsFiltered}
        onFlopSelection={onFlopSelection}
        board={board}
        limitHeight={false}
      />
    </div>
  );
}

type FlopListComponent = {
  flopsToLoad: string[];
  getNextRows: () => void;
  flopStringsFiltered: string[];
  onFlopSelection: (flop: string) => void;
  board: string;
  limitHeight: boolean;
};

function BoardList({
  flopsToLoad,
  getNextRows,
  flopStringsFiltered,
  onFlopSelection,
  board,
  limitHeight
}: FlopListComponent) {
  const overMdSize = useMediaQuery(`(min-width: ${mediaBreakpoints.md})`);
  const heightClassName = limitHeight ? "" : overMdSize ? "height-fill-vh" : "";
  return (
    <div id="boards-list-div" className={`board-list ${heightClassName}`}>
      <InfiniteScroll
        key={`infiniteScroll-overMdSize-${String(overMdSize)}`}
        dataLength={flopsToLoad.length}
        next={getNextRows}
        hasMore={flopsToLoad.length < flopStringsFiltered.length}
        scrollableTarget={overMdSize ? "boards-list-div" : "boards-list-div"}
        loader={<p>Loading...</p>}>
        {flopsToLoad.map((flop) => {
          return (
            <FlopSelection
              key={`Flop-${flop}`}
              board={board}
              flop={flop}
              onClick={onFlopSelection}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

type FlopHeaderComponent = {
  showCloseButton: boolean;
  onCloseClick: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  flopStringsFiltered: string[];
  flops: string[];
};
function FlopHeader({
  showCloseButton,
  onCloseClick,
  onInputChange,
  flopStringsFiltered,
  flops
}: FlopHeaderComponent) {
  return (
    <>
      <Row>
        {showCloseButton ? (
          <div className="text-end" onClick={onCloseClick}>
            <CloseButton />
          </div>
        ) : null}
        <input
          type="text"
          className="center-block"
          onChange={onInputChange}
          style={{ maxWidth: "80%" }}
        />{" "}
      </Row>

      <Row>
        <span
          className="strong mt-1 p-1"
          style={{
            textAlign: "center"
          }}>{`${flopStringsFiltered.length} of ${flops.length} boards`}</span>
      </Row>
    </>
  );
}

function evaluateTextFilter(flop: string, textFilterTokens: string[]) {
  if (textFilterTokens.length === 0) return true;

  for (const token of textFilterTokens)
    if (!flop.match(RegExp(token, "gi"))) return false;

  const anyFilterHasNoSuit =
    textFilterTokens.filter((f) => f.length === 1).length > 0;

  if (anyFilterHasNoSuit) {
    const rankCountFilter: { [key: string]: number } = {};
    for (const filter of textFilterTokens) {
      if (!rankCountFilter[filter[0]]) rankCountFilter[filter[0]] = 0;
      rankCountFilter[filter[0]] += 1;
    }
    for (const rank of Object.keys(rankCountFilter)) {
      const regex = RegExp(rank, "gi");
      const match = flop.match(regex);
      if (!match || match.length < rankCountFilter[rank]) return false;
    }
  }
  return true;
}

function splitFlopFilter(filter: string) {
  filter = cardsCompress(filter);
  const tokens: string[] = [];
  let currentToken = "";
  for (const t of filter) {
    if (ranks.includes(t.toUpperCase())) {
      if (currentToken) tokens.push(currentToken);
      currentToken = "";
    }
    currentToken += t;
  }
  if (currentToken) tokens.push(currentToken);

  //check if each tokens is proper (rank + suit or just rank)
  for (const token of tokens) {
    if (token.length > 2) return [] as string[];
    if (!ranks.includes(token[0].toUpperCase())) return [] as string[];
    if (token.length > 1 && !suits.includes(token[1] as CardSuit))
      return [] as string[];
  }
  return tokens;
}

//for now not used
function FlopDisplay({ board, onClick }: FlopSelectionDisplayProps) {
  const selectedFlop = board ? board.substring(0, 6) : "";

  if (!selectedFlop && board) return null;
  return (
    <Button
      className={"btn btn-dark"}
      type="button"
      size="sm"
      onClick={onClick}>
      <CommunityCards cards={selectedFlop} />
    </Button>
  );
}

export default FlopInline;
export {
  FlopDisplay,
  FlopSelection,
  FlopFilterDiv,
  FlopFilterCard,
  evaluateTextFilter,
  splitFlopFilter
};
