import { cardsCompressAndSplit } from "../cards";
import {
  Action,
  Deal,
  DealActionCode,
  DealActionsNames,
  Node,
  NodesByStreet,
  NodeType,
  NodeTree,
  SplitLines
} from "./nodespace.types";

const PotContrib = {
  OOP: 0,
  IP: 1,
  EXTRA: 2
};

const DealActions: { [key: string]: Deal } = {
  FLOP: "<flop>",
  TURN: "<turn>",
  RIVER: "<river>"
};

const DealStrings: { [key in Deal]: string } = {
  "<flop>": "flop",
  "<turn>": "turn",
  "<river>": "river"
};

const NodeTypes: { [key: string]: NodeType } = {
  OOP: "OOP",
  IP: "IP",
  SPLIT: "SPLIT",
  END_NODE: "END_NODE"
};

type StreetName = typeof DealStrings[Deal] | "preflop";
const DealActionCodes: DealActionCode = {};
(Object.keys(DealActions) as Array<DealActionsNames>).map(
  (k) => (DealActionCodes[DealActions[k]] = k)
);

const flopLength = 3;
const dealActionArray = [DealActions.FLOP, DealActions.TURN, DealActions.RIVER];

/**
 * Add the line into the collection of nodes.
 * @returns The newly added node.
 */
function accumulateLine(
  currentId: string,
  splitLine: string[],
  parentId: string,
  nodes: NodeTree
) {
  const parent = nodes[parentId];

  const parentPotDiff = parent.pot[PotContrib.OOP] - parent.pot[PotContrib.IP];
  const street = parent.street;
  const baseStreetContrib = baseStreetPotContribution(parent);
  const currentAction = parseAction(
    splitLine[splitLine.length - 1],
    parentPotDiff,
    parent.pot,
    baseStreetContrib
  );

  const newPot = calculateNewPot(parent, currentAction);
  const currentNode: Node = {
    id: currentId,
    pot: newPot,
    parent: parent,
    action: currentAction,
    children: [],
    type: getNodeType(parent, currentAction, street),
    street: street
  };

  nodes[currentId] = currentNode;
  if (!parent.children.includes(currentId)) {
    parent.children.unshift(currentId);
    sortChildren(parent, nodes);
  }
  return currentNode;
}

const weightsMap = {
  Fold: -2,
  Call: -1,
  Check: -1
} as { [key: string]: number };

/**
 * Sort children for node - the order should be: Bet/Raise maxSize->minSize, then Check/Call, then fold.
 * @param {Node} node
 * @returns The deal action id
 */
function sortChildren(node: Node, allNodes: NodeTree) {
  if (node.children.length <= 1) return;
  const weightForAction = (action: Action) =>
    weightsMap[action.action] ?? action.size;

  node.children.sort(
    (a, b) =>
      weightForAction(allNodes[b].action) - weightForAction(allNodes[a].action)
  );
}
/**
 * Some extra accumulation happens for split nodes.
 * @param {string} currentId
 * @param {object} nodes
 * @returns The deal action id
 */
function accumulateSplitNode(currentNode: Node, nodes: NodeTree) {
  const currentId = currentNode.id;
  const newStreet = streetAfterStreet(currentNode.street);
  const dealAction = dealActionForStreet(newStreet);
  const dealActionId = `${currentId}:${dealAction}`;
  const action = parseAction(
    dealAction,
    0,
    currentNode.pot,
    baseStreetPotContribution(currentNode)
  );
  const pot = currentNode.pot.map((a) => a);
  nodes[dealActionId] = {
    id: dealActionId,
    pot,
    parent: currentNode,
    action,
    children: [],
    type: NodeTypes.OOP,
    street: newStreet
  };
  nodes[currentId].children.push(dealActionId);
  return dealActionId;
}

/**
 * The deal lines need to be adjusted after a split node is processed.
 * @param {array} splitLines
 * @param {string} currentId
 * @param {string} _dealActionId
 * @param {object} nodes
 * @returns
 */
function fixDealLines(
  splitLines: SplitLines,
  currentId: string,
  dealActionId: string,
  nodes: NodeTree
) {
  return splitLines.map((line) => {
    if (!line[0].startsWith(currentId)) return line;
    const newLine = line[0].replace(
      currentId,
      `${currentId}:${nodes[dealActionId].action.code}`
    );
    const newSplitLine = newLine.split(":");
    return [newLine, newSplitLine] as SplitLines;
  });
}

function parseAction(
  actionCode: string,
  potDiff: number,
  parentPot: number[],
  rootStreetContrib: number
) {
  if (DealActionCodes[actionCode] != null) {
    return {
      code: actionCode,
      action: actionCode,
      sizeCumulative: undefined
    } as Action;
  }

  const actionType = actionCode.charAt(0);
  let sizeCumulative =
    actionCode.length > 1 ? Number(actionCode.substring(1)) : null;
  let action = "";
  const size = sizeCumulative
    ? sizeCumulative - rootStreetContrib
    : sizeCumulative;
  switch (actionType) {
    case "c":
      if (potDiff === 0) {
        action = "Check";
        // eslint-disable-next-line brace-style
      } else {
        action = "Call";
        sizeCumulative = Math.abs(potDiff);
      }
      break;
    case "b":
      if (sizeCumulative) {
        action =
          sizeCumulative > Math.abs(potDiff) && potDiff !== 0 ? "Raise" : "Bet";
      } else {
        Error("Bet size cannot be null");
      }
      break;
    case "f":
      action = "Fold";
      break;
    default:
      // this is the root node
      action = "r:0";
      break;
  }

  return {
    code: actionCode,
    action: action,
    sizeCumulative,
    size
  } as Action;
}

function calculateNewPot(parent: Node, action: Action) {
  let newPot = [...parent.pot];
  if (action.code.toLowerCase() === "f") return newPot;

  const actionPlayer =
    parent.type === NodeTypes.OOP ? PotContrib.OOP : PotContrib.IP;
  const otherPlayer =
    actionPlayer === PotContrib.OOP ? PotContrib.IP : PotContrib.OOP;
  if (!action.sizeCumulative) return newPot;
  const hero = newPot[actionPlayer];
  const villain = newPot[otherPlayer];
  const dead = newPot[PotContrib.EXTRA];
  const max = Math.max(hero, villain);
  if (action.code.toLowerCase() === "c") {
    newPot = [max, max, dead];
    return newPot;
  }

  newPot[actionPlayer] = action.sizeCumulative;
  return newPot;
}

// !need fix! for root node if OOPPot !=IPPot
/** returns contributions from when contributions were equal
 * if root street, returns 0
 */
function baseStreetPotContribution(node: Node) {
  let n = node;

  while (n.pot[0] !== n.pot[1]) {
    if (!node.parent) return 0;
    n = n.parent as Node;
  }

  return n.pot[0];
}

/**
 * A split node marks the end of a street.
 * @param parentNode The parent of the current node
 * @param currentAction The current action
 * @param street The current street
 * @returns
 */
function isSplitNode(parentNode: Node, currentAction: Action, street: number) {
  if (currentAction.code !== "c") return false;
  if (!parentNode.parent) return false;
  if (street === 5) return false;
  if (DealActionCodes[parentNode.action.code] != null) return false;

  return true;
}

function getParentId(splitId: string[]) {
  const splitCopy = [...splitId];
  if (splitCopy.length <= 2) return null; //r and r:0
  splitCopy.pop();
  return splitCopy.join(":");
}

function getNodeType(parent: Node, action: Action, street: number): NodeType {
  const isSplit = isSplitNode(parent, action, street);
  if (isSplit) return NodeTypes.SPLIT;
  if (parent.type === NodeTypes.SPLIT) return NodeTypes.OOP; //dealAction node - always next is OOP
  return parent.type === NodeTypes.OOP ? NodeTypes.IP : NodeTypes.OOP;
}

function streetAfterStreet(previousStreet: number) {
  const street = Number(previousStreet);
  if (street === 0) return street + flopLength;
  if (street + 1 > 5)
    throw new Error("Node tree: street cannot be higher than 5");
  return street + 1;
}

function dealActionForStreet(previousStreet: number) {
  if (previousStreet === 0) return DealActions.FLOP;

  return dealActionArray[previousStreet - flopLength];
}

function removeNodeFromTree(node: Node, tree: NodeTree) {
  if (node.parent)
    node.parent.children.splice(node.parent.children.indexOf(node.id), 1);

  delete tree[node.id];
}
function nodeIsDealAction(node: string) {
  return (Object.keys(DealActions) as Array<DealActionsNames>).some((action) =>
    node.endsWith(DealActions[action])
  );
}

function addBetIndexForNodeAndSiblings(node: Node, allNodes: NodeTree) {
  if (!node.parent) return;

  const betRaiseActions = ["bet", "raise"];
  const betSiblings = node.parent.children
    .filter((child) =>
      betRaiseActions.includes(allNodes[child].action.action.toLowerCase())
    )
    .reverse();
  for (const sibling of betSiblings) {
    allNodes[sibling].action.betIndex = {
      total: betSiblings.length,
      index: betSiblings.indexOf(sibling)
    };
  }
}
///////  Exported functions below, Internal functions above /////////

/**
 * Take the output of show_all_lines and return an array
 * of decision points where each value contains the options
 * at each decision point.
 * @param {array} nodeLines
 * @param {string} rootPot (e.g., "0 0 10")
 * @param {number} rootStreet (e.g., 3 - length of root board)
 * @returns [{name, options}]
 */
function buildNodeTree(nodeLines: string[], pot: number[], rootStreet: number) {
  const nodes: NodeTree = {};

  const rootPotDiff = pot[PotContrib.OOP] - pot[PotContrib.IP];
  const baseStreetContrib = 0;
  const action = parseAction("0", rootPotDiff, pot, baseStreetContrib);
  nodes["r:0"] = {
    id: "r:0",
    pot,
    parent: undefined,
    action,
    children: [],
    type: rootPotDiff >= 0 ? NodeTypes.OOP : NodeTypes.IP,
    street: rootStreet
  };

  let splitLines = nodeLines
    .map((line) => [line, line.split(":")])
    .sort((a, b) => a[1].length - b[1].length) as SplitLines;

  while (splitLines.length > 0) {
    const l = splitLines.shift() as SplitLines;
    const currentId = l[0];
    const splitLine = l[1];
    if (splitLine.length < 2) continue; //just to skip r and r:0

    const parentId = getParentId(splitLine);
    if (!parentId) continue;
    const currentNode = accumulateLine(currentId, splitLine, parentId, nodes);
    if (currentNode.type === NodeTypes.SPLIT) {
      const dealActionId = accumulateSplitNode(currentNode, nodes);
      splitLines = fixDealLines(
        splitLines,
        currentId,
        dealActionId,
        nodes
      ) as SplitLines;
    }
  }

  for (const node of Object.keys(nodes)) {
    const nodeObj = nodes[node];

    //Add bettingIndexes
    addBetIndexForNodeAndSiblings(nodeObj, nodes);
    //delete DealNodes at the end
    if (nodeObj.children.length !== 0) continue;
    let endNode: Node | undefined = nodeObj;
    if (nodeIsDealAction(node)) {
      const parent = endNode.parent;
      removeNodeFromTree(nodeObj, nodes);
      endNode = parent;
    }
    if (!endNode) throw Error("endNode cannot be null");
    endNode.type = NodeTypes.END_NODE;
  }
  return nodes;
}

/**
 * Check if the child candidate is a child of the parent.
 * @param childId Child node id
 * @param parentId Parent node id
 * @returns true if the child is a child of the parent
 */
function isNodeChildOfNode(childId: string, parentId: string) {
  const parentPath = parentId.split(":");
  const childPath = childId.split(":");
  if (parentPath.length >= childPath.length) return false;
  const discrepancies = parentPath.filter((p, i) => childPath[i] !== p);
  return discrepancies.length < 1;
}

/**
 * Return the depth of the node in the tree. The root is at depth 0.
 * @param nodeId The node id
 * @returns The depth of the node
 */
function nodeDepth(nodeId: string) {
  return nodeId.split(":").length - 2;
}

/**
 * Find all the parents of the current node, and return them grouped by street.
 * @param currentNode The starting node
 * @returns Nodes grouped by street
 */
function parentsOfNodeByStreet(currentNode: Node) {
  const nodesByStreet: NodesByStreet = {};
  const streets = [];
  function addParentsByStreet(node: Node) {
    let street = node.street;
    if (node.parent?.type === NodeTypes.SPLIT)
      street = streetAfterStreet(node.parent.street);
    if (!nodesByStreet[street]) {
      nodesByStreet[street] = [];
      streets.unshift(street);
    }

    nodesByStreet[street].unshift(node);
    if (!node.parent) return;

    addParentsByStreet(node.parent);
  }
  addParentsByStreet(currentNode);

  return nodesByStreet;
}

/**
 * Find the immediate descendant of the parent that is a parent of the current node.
 * @param parentNode parent node
 * @param currentNode the current node
 * @returns The matching node.
 */
function selectedChildOfNode(
  parentNode: Node,
  currentNode: Node
): Node | undefined {
  let descendant: Node | undefined = currentNode;
  while (descendant != null) {
    if (descendant.parent === parentNode) return descendant;
    descendant = descendant.parent;
  }

  return undefined;
}

function shortenBoardToStreet(node: Node, board: string) {
  if (!board || !board.trim()) return "";
  const newBoard = cardsCompressAndSplit(board);

  while (newBoard.length > node.street) newBoard.pop();

  return newBoard.join("");
}

function streetStringForNode(node: Node) {
  return DealStrings[node.action.code as Deal];
}

function trimSplitTrim(nodeLines: string) {
  return nodeLines
    .trim()
    .split("\n")
    .map((s) => s.trim());
}

export {
  DealActions,
  DealStrings,
  NodeTypes,
  PotContrib,
  baseStreetPotContribution,
  buildNodeTree,
  isNodeChildOfNode,
  nodeDepth,
  parentsOfNodeByStreet,
  selectedChildOfNode,
  shortenBoardToStreet,
  streetStringForNode,
  trimSplitTrim,
  dealActionForStreet
};

export type { StreetName };
