import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type GrantType = {
  grantor: string[];
  recipient: string[];
};
type GrantSpotsInfo = {
  spots: string[];
};
type CreateGrantArgs = {
  body: {
    name: string;
    spots: string[];
    recipients: string[];
  };
};
// Define a service using a base URL and expected endpoints
export const piosaasGrantsApi = createApi({
  reducerPath: "piosaasGrantsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/grants" }),
  tagTypes: ["Grants", "GrantSpots", "GrantUsers"],
  endpoints: (builder) => ({
    grantsInfo: builder.query<GrantType, void>({
      query: () => {
        return {
          url: ""
        };
      },
      providesTags: ["Grants"]
    }),
    deleteGrant: builder.mutation({
      query: (grant: string) => {
        return {
          url: `/${grant}`,
          method: "DELETE"
        };
      },
      invalidatesTags: ["Grants"]
    }),
    createGrant: builder.mutation({
      query: (args: CreateGrantArgs) => {
        return {
          url: ``,
          method: "POST",
          body: args.body
        };
      },
      invalidatesTags: ["Grants"]
    }),
    modifyGrant: builder.mutation({
      query: ({ name }: { name: string }) => {
        return {
          url: `/${name}`,
          method: "POST",
          body: { name }
        };
      },
      invalidatesTags: ["Grants"]
    }),
    grantSpotInfo: builder.query<GrantSpotsInfo, string>({
      query: (grantName) => {
        return {
          url: `/${grantName}/spots`
        };
      },
      providesTags: ["GrantSpots"]
    }),
    grantRecipientsInfo: builder.query<string[], string>({
      query: (grantName) => {
        return {
          url: `/${grantName}/recipients`
        };
      },
      providesTags: ["GrantUsers"]
    })
  })
});

export const {
  useGrantsInfoQuery,
  useDeleteGrantMutation,
  useCreateGrantMutation,
  useModifyGrantMutation,
  useGrantSpotInfoQuery,
  useGrantRecipientsInfoQuery
} = piosaasGrantsApi;
export type { CreateGrantArgs };
