import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { CheckLg, Clipboard } from "react-bootstrap-icons/";

interface CopyToClipboardProps {
  clipboardText: string;
  children?: React.ReactNode;
}

function CopyToClipboard(props: CopyToClipboardProps) {
  const [showCheck, setShowCheck] = React.useState(false);

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.clipboardText);
      setShowCheck(true);
      setTimeout(() => {
        setShowCheck(false);
      }, 3000);
    } catch (e) {
      // Do nothing
    }
  };
  return (
    <>
      {props.children}{" "}
      <OverlayTrigger
        overlay={(props) => (
          <Tooltip
            id="error-link-to-node"
            className="position-absolute"
            {...props}>
            Copy a link to the node
          </Tooltip>
        )}
        placement="top">
        <Button
          className="p-0 pb-1 m-0 text-reset"
          variant="link"
          onClick={onCopy}>
          {showCheck ? (
            <CheckLg width={20} height={20} />
          ) : (
            <Clipboard width={20} height={20} />
          )}
        </Button>
      </OverlayTrigger>
    </>
  );
}

export default CopyToClipboard;
