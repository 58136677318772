// function colorsForActionColorBrewer(action: string) {
//   if (action === "call" || action === "check") {
//     return {
//       background: "#4a9c79",
//       text: "#000",
//       variant: "success"
//     };
//   }
//   if (action === "bet" || action === "raise") {
//     return {
//       background: "#dc3545",
//       text: "#FFF",
//       variant: "danger"
//     };
//   }
//   return {
//     background: "#1f78b4",
//     text: "#000",
//     variant: "primary"
//   };
// }

// call -> darkseagreen
// bet -> darksalmon
// fold -> rgb(109, 162, 192)

import { Action } from "./nodespace.types";

type RGBColor = {
  r: number;
  g: number;
  b: number;
};

//https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? ({
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } as RGBColor)
    : null;
}

function getMixedColorUsingGradient(
  colors: [string, string] | [string],
  weight: number,
  linearMix?: boolean
) {
  if (colors.length === 1) return colors[0];

  const mix1 = hexToRgb(colors[0]);
  const mix2 = hexToRgb(colors[1]);
  if (!mix1) throw Error(`Couldn't parse color ${colors[0]}`);
  if (!mix2) throw Error(`Couldn't parse color ${colors[1]}`);

  const resultColor = {
    r: mixColors(mix1.r, mix2.r, weight, linearMix),
    g: mixColors(mix1.g, mix2.g, weight),
    b: mixColors(mix1.b, mix2.b, weight)
  } as RGBColor;
  return `rgb(${resultColor.r}, ${resultColor.g}, ${resultColor.b})`;
}

function mixColors(i: number, j: number, remain: number, linear?: boolean) {
  if (i === j) return i;
  if (remain < 0) return i;
  if (remain > 1) return j;
  const RGBBlendingFactor = linear ? 1 : 2.2;
  const i2 = Math.pow(i, RGBBlendingFactor);
  const j2 = Math.pow(j, RGBBlendingFactor);
  const r2 = i2 + (j2 - i2) * remain;
  return Math.round(Math.pow(r2, 1 / RGBBlendingFactor));
}
function colorsForActionPioViewer(nodeAction: Action) {
  if (nodeAction.action === "r:0") {
    return {
      background: "green",
      text: "#000",
      variant: "success"
    };
  }
  if (["call", "check"].includes(nodeAction.action.toLowerCase())) {
    return {
      background: "darkseagreen",
      text: "#000",
      variant: "success"
    };
  }

  if (["bet", "raise"].includes(nodeAction.action.toLowerCase())) {
    const index = nodeAction.betIndex.index;
    const total = nodeAction.betIndex.total;
    const weight = total === 1 ? 0 : index / total;

    return {
      background: getMixedColorUsingGradient(["#e9967a", "#8b0000"], weight),
      text: "#000",
      variant: "danger"
    };
  }

  return {
    background: "rgb(109, 162, 192)",
    text: "#000",
    variant: "primary"
  };
}

export {
  colorsForActionPioViewer as colorsForAction,
  getMixedColorUsingGradient
};
