import React, { useRef } from "react";
import { useResizeDetector } from "react-resize-detector";

import {
  Node,
  NodesByStreet,
  NodeTree,
  NodeTypes,
  isNodeChildOfNode,
  nodeDepth,
  parentsOfNodeByStreet,
  streetStringForNode
} from "../../../utils/nodespace";

import { DealDisplay } from "../deals";
import { useNodeStateDispatch, useNodeStateSelector } from "../nodeSlice";
import {
  isBelowLg,
  useViewControlsDispatch,
  useViewControlsSelector
} from "../viewSlice";

import {
  ActionNodeBreadcrumbButton,
  availableStreets,
  onSelectDisplayNode
} from "./node";

import NodeChooser, { isFrontierNavigation } from "./node-chooser";
import {
  BetSizeDisplay,
  useUserPrefsContext
} from "../../../utils/preferences";
import { useSpotStateSelector } from "../spotSlice";

interface BreadcrumbProps {
  allNodes: NodeTree;
  board: string;
  betSizeDisplay: BetSizeDisplay;
}

interface NodeBreadcrumbProps extends BreadcrumbProps {
  allNodes: NodeTree;
  board: string;
  isLabelCompressed: boolean;
  node: Node;
}

interface NodeBreadcrumbSwitchProps extends NodeBreadcrumbProps {
  displayNodeId: string;
  hasFocus: boolean;
  isSmallViewport: boolean;
}

interface StreetBreadcrumbProps extends BreadcrumbProps {
  displayNodeId: string;
  hasFocus: boolean;
  isLabelCompressed: boolean;
  isSmallViewport: boolean;
  rowNodes: Node[];
  street: number;
}

function RootNodeBreadcrumb({
  allNodes,
  betSizeDisplay,
  board,
  isLabelCompressed,
  node
}: NodeBreadcrumbProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();
  const fullLabel = !isLabelCompressed;
  return (
    <ActionNodeBreadcrumbButton
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      node={node}
      useFullLabel={fullLabel}
      betSizeDisplay={betSizeDisplay}
    />
  );
}

function ActionBreadcrumb({
  allNodes,
  board,
  isLabelCompressed,
  node,
  betSizeDisplay
}: NodeBreadcrumbProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();
  const fullLabel = !isLabelCompressed;
  return (
    <ActionNodeBreadcrumbButton
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      node={node}
      useFullLabel={fullLabel}
      betSizeDisplay={betSizeDisplay}
    />
  );
}

function DealBreadcrumb({
  allNodes,
  board,
  displayNodeId,
  node
}: NodeBreadcrumbSwitchProps) {
  const dispatch = useNodeStateDispatch();
  const viewControlsDispatch = useViewControlsDispatch();
  const isDisplayNode = displayNodeId === node.id;
  const streetString = streetStringForNode(node);
  return (
    <DealDisplay
      isDisplayNode={isDisplayNode}
      streetString={streetString}
      board={board}
      onClick={() =>
        onSelectDisplayNode(
          dispatch,
          allNodes,
          board,
          node.id,
          viewControlsDispatch
        )
      }
      key={`${streetString}Display`}
    />
  );
}

function NodeBreadcrumbSwitch(props: NodeBreadcrumbSwitchProps) {
  const { board, displayNodeId, hasFocus, isSmallViewport, node } = props;
  const atFrontier = isFrontierNavigation(displayNodeId, node.parent?.id);
  if (atFrontier) {
    return (
      <NodeChooser
        betSizeDisplay={props.betSizeDisplay}
        board={board}
        hasFocus={hasFocus}
        isSmallViewport={isSmallViewport}
        selectedNode={node}
      />
    );
  }
  if (node.parent?.type === NodeTypes.SPLIT)
    return <DealBreadcrumb {...props} />;

  return <ActionBreadcrumb {...props} />;
}

function NodeBreadcrumb(props: NodeBreadcrumbSwitchProps) {
  const { displayNodeId, isLabelCompressed, node } = props;
  const nodeDownstreamOfDisplay = isNodeChildOfNode(node.id, displayNodeId);
  const isGhost = node.parent?.id !== displayNodeId && nodeDownstreamOfDisplay;
  const style = isGhost ? { opacity: 0.4 } : {};
  return (
    <div className="mx-1" style={style}>
      <NodeBreadcrumbSwitch
        {...props}
        isLabelCompressed={isLabelCompressed || isGhost}
      />
    </div>
  );
}

function StreetBreadcrumb(props: StreetBreadcrumbProps) {
  const { allNodes, street } = props;
  let rowNodes = props.rowNodes;
  if (!rowNodes) return null;
  const isRootStreet = allNodes["r:0"].street === street;
  if (isRootStreet) {
    // We do not want the root node, since that is already displayed
    rowNodes = rowNodes.slice(1);
  }

  return (
    <>
      {rowNodes.map((node, i) => (
        <NodeBreadcrumb {...props} node={node} key={i} />
      ))}
    </>
  );
}

function displayNodeStreet(
  nodesByStreet: NodesByStreet,
  displayNodeId: string
) {
  let streetForDisplayNode = -1;
  for (const s of availableStreets) {
    if (streetForDisplayNode > -1) break;
    const nodes = nodesByStreet[s];
    if (nodes == null) continue;
    for (const n of nodes) {
      if (displayNodeId === n.id) {
        streetForDisplayNode = s;
        break;
      }
    }
  }
  return streetForDisplayNode;
}

function ViewerStyleBreadcrumb({
  betSizeDisplay,
  hasFocus,
  isSmallViewport
}: {
  betSizeDisplay: BetSizeDisplay;
  hasFocus: boolean;
  isSmallViewport: boolean;
}) {
  const { nodeTree: allNodes } = useSpotStateSelector(
    (state) => state.spotState
  );
  const { board, currentNodeGhostId, displayNodeId } = useNodeStateSelector(
    (state) => ({
      board: state.nodeState.boardGhost,
      currentNodeGhostId: state.nodeState.currentNodeGhostId,
      displayNodeId: state.nodeState.displayNodeId
    })
  );

  const targetRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDetector({ targetRef });
  const displayNodeDepth = nodeDepth(displayNodeId);
  // The number of nodes we draw is displayNodeDepth + 2 (root + next)
  // Consider compressing labels if there is fewer than 60px per node
  const neededPxPerNode = betSizeDisplay === "Both" ? 60 * 2 : 60;
  const considerCompressing = width
    ? width / (displayNodeDepth + 2) < neededPxPerNode
    : false;
  const nodesByStreet = parentsOfNodeByStreet(allNodes[currentNodeGhostId]);
  const streetForDisplayNode = displayNodeStreet(nodesByStreet, displayNodeId);

  return (
    <div
      ref={targetRef}
      className="d-flex node-breadcrumb w-100 overflow-auto pt-1">
      <div className="mx-1">
        <RootNodeBreadcrumb
          allNodes={allNodes}
          board={board}
          node={allNodes["r:0"]}
          isLabelCompressed={considerCompressing}
          betSizeDisplay={betSizeDisplay}
        />
      </div>
      {availableStreets.map((street, i) => (
        <StreetBreadcrumb
          key={`street-${street}`}
          allNodes={allNodes}
          board={board}
          displayNodeId={displayNodeId}
          hasFocus={hasFocus}
          isLabelCompressed={
            considerCompressing && streetForDisplayNode !== street
          }
          isSmallViewport={isSmallViewport}
          rowNodes={nodesByStreet[street]}
          street={street}
          betSizeDisplay={betSizeDisplay}
        />
      ))}
      {displayNodeId === currentNodeGhostId ? (
        <NodeChooser
          betSizeDisplay={betSizeDisplay}
          board={board}
          hasFocus={hasFocus}
          isSmallViewport={isSmallViewport}
        />
      ) : null}
      <span className="px-3">&nbsp;</span>
    </div>
  );
}

function ViewerStyleControls() {
  const { focus, viewportDimensions } = useViewControlsSelector(
    (state) => state.viewControls
  );
  const { presentation } = useUserPrefsContext();
  const { treeBrowser } = presentation.betSizesDisplay;
  const hasFocus = focus === "tree";
  return (
    <div className="h-100">
      <div className={`node-viewer-container${hasFocus ? "" : " unfocused"}`}>
        <ViewerStyleBreadcrumb
          betSizeDisplay={treeBrowser}
          hasFocus={hasFocus}
          isSmallViewport={isBelowLg(viewportDimensions)}
        />
      </div>
    </div>
  );
}

export default ViewerStyleControls;
