import React from "react";

import {
  SetSquareSource,
  SquareType,
  ViewControlsDispatch,
  setSquare,
  useViewControlsSelector,
  useViewControlsDispatch
} from "../../features/view-controls/viewSlice";

import { FieldProps, GridHandGroupSquare } from "./grid.types";

function squareCursor(square: GridHandGroupSquare) {
  const handSquareMean = square.rangeMean;
  return handSquareMean > 0 ? "pointer" : "default";
}

function onMouseOver(
  _e: unknown,
  v: GridHandGroupSquare,
  dispatch: ViewControlsDispatch
) {
  const handSquareMean = v.rangeMean;
  let handGroup = null;
  if (handSquareMean > 0) handGroup = v.squareName;
  dispatch(setSquare({ handGroup, source: SetSquareSource.MOUSEOVER }));
}

function onMouseOut(_e: unknown, _v: unknown, dispatch: ViewControlsDispatch) {
  dispatch(setSquare({ handGroup: null, source: SetSquareSource.MOUSEOVER }));
}

function onMouseDown(
  e: React.MouseEvent<SVGGElement, MouseEvent>,
  v: GridHandGroupSquare,
  dispatch: ViewControlsDispatch
) {
  const handSquareMean = v.rangeMean;
  let handGroup = null;
  if (handSquareMean > 0) handGroup = v.squareName;
  const location = { x: e.pageX, y: e.pageY };
  dispatch(setSquare({ handGroup, location, source: SetSquareSource.CLICK }));
}

interface HandGridRowBorderProps extends FieldProps {
  dispatch: ViewControlsDispatch;
  row: GridHandGroupSquare[];
  rowIndex: number;
  selectedSquare: SquareType;
}

function HandGridRowBorder({
  dispatch,
  fieldDimensions,
  row,
  rowIndex,
  selectedSquare
}: HandGridRowBorderProps) {
  const rowSquares = row.map((v, i) => {
    const cursor = squareCursor(v);
    const selected = selectedSquare.handGroup === v.squareName;
    const className = selected ? "square-border selected" : "square-border";
    const stroke = "rgba(100, 100, 100, 0.7)";
    return (
      <g
        className="hand-grid-border"
        key={i}
        onMouseOut={(e) => onMouseOut(e, v, dispatch)}
        onMouseOver={(e) => onMouseOver(e, v, dispatch)}
        onMouseDown={(e) => onMouseDown(e, v, dispatch)}
        transform={`translate(${fieldDimensions.width * i})`}>
        <rect
          className={className}
          fillOpacity={0}
          height={fieldDimensions.height}
          width={fieldDimensions.width}
          stroke={stroke}
          strokeWidth="0.2"
          style={{ cursor }}
        />
      </g>
    );
  });
  return (
    <g
      key={rowIndex}
      transform={`translate(0, ${fieldDimensions.height * rowIndex})`}>
      {rowSquares}
    </g>
  );
}

interface HandGridBordersProps extends FieldProps {
  squares: GridHandGroupSquare[][];
}

function HandGridBorders({
  fieldDimensions,
  fontSizes,
  squares
}: HandGridBordersProps) {
  const dispatch = useViewControlsDispatch();
  const selectedSquare = useViewControlsSelector(
    (state) => state.viewControls.square
  );

  const svgRowBorders = squares.map((r, i) => {
    return (
      <HandGridRowBorder
        key={`${i}-border`}
        dispatch={dispatch}
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        row={r}
        rowIndex={i}
        selectedSquare={selectedSquare}
      />
    );
  });
  return <g className="borders">{svgRowBorders}</g>;
}

export default HandGridBorders;

export type { GridHandGroupSquare };
