import {
  canonicalHandRankOrder,
  cardRankOrdering,
  handspace,
  handsValuesMap
} from "./handspace";

// You may want to call the result of handspace() shs for structured hand space
export default handspace;
export { canonicalHandRankOrder, cardRankOrdering, handsValuesMap };
