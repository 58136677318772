import { SpotInfo } from "../services/piosaasTreeApi";
import { handValuesToMeanValuesMatrix } from "../utils/handspace/handspace";
import handspace from "../utils/handspace";

import { getRangeColorForValue } from "./grid/range";

function RangeSummaries({
  spotData,
  rectWidth,
  rectHeight
}: {
  spotData: SpotInfo;
  rectWidth: number;
  rectHeight: number;
}) {
  return (
    <div className="d-flex justify-content-around my-lg-3">
      <div className="flex-lg-grow-1 m-1">
        <RangeSummary
          range={spotData.range_IP}
          rectWidth={rectWidth}
          rectHeight={rectHeight}
          labelText="Range IP"
        />
      </div>
      <div className="flex-lg-grow-1 m-1">
        <RangeSummary
          range={spotData.range_OOP}
          rectWidth={rectWidth}
          rectHeight={rectHeight}
          labelText="Range OOP"
        />
      </div>
    </div>
  );
}

function RangeSummary({
  range,
  rectWidth,
  rectHeight,
  labelText
}: {
  range: number[];
  rectWidth: number;
  rectHeight: number;
  labelText: string;
}) {
  const rangeMeans = handValuesToMeanValuesMatrix(range, handspace);

  const width = rectWidth * 13;
  const height = rectHeight * 13;
  return (
    <>
      {labelText && <div className="fw-bolder">{labelText}</div>}
      <div>
        <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
          <g>
            {rangeMeans.map((rangeMeanRow, rowIndex) => {
              return (
                <g
                  transform={`translate(0, ${(height / 13) * rowIndex})`}
                  key={`range-summary-row-${rowIndex}`}>
                  <RangeSummaryRow
                    rangeMeanRow={rangeMeanRow}
                    width={rectWidth}
                    height={rectHeight}
                  />
                </g>
              );
            })}
          </g>
        </svg>
      </div>
    </>
  );
}

function RangeSummaryRow({
  rangeMeanRow,
  width,
  height
}: {
  rangeMeanRow: number[];
  width: number;
  height: number;
}) {
  return (
    <g>
      {rangeMeanRow.map((rangeMeanValue, i) => (
        <g transform={`translate(${width * i})`} key={`range-summary--${i}`}>
          <RangeSummarySquare
            value={rangeMeanValue}
            height={height}
            width={width}
          />
        </g>
      ))}
    </g>
  );
}

function RangeSummarySquare({
  value,
  width,
  height
}: {
  value: number;
  width: number;
  height: number;
}) {
  const fill = `${getRangeColorForValue(value, false)}`;
  return <rect fill={fill} height={height} width={width} />;
}

export default RangeSummaries;
