import { configureStore } from "@reduxjs/toolkit";
import { metadataApi } from "../features/metadata/metadataApi";
import { piosaasServerApi } from "../services/piosaasServerApi";
import { piosaasTreeApi } from "../services/piosaasTreeApi";
import { piosaasStorageApi } from "../services/piosaasStorageApi";

import {
  nodeReducer,
  spotReducer,
  viewControlsReducer
} from "../features/view-controls";
import { piosaasGrantsApi } from "../services/piosaasGrantsApi";

export const store = configureStore({
  reducer: {
    nodeState: nodeReducer,
    spotState: spotReducer,
    viewControls: viewControlsReducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
    [piosaasServerApi.reducerPath]: piosaasServerApi.reducer,
    [piosaasTreeApi.reducerPath]: piosaasTreeApi.reducer,
    [piosaasStorageApi.reducerPath]: piosaasStorageApi.reducer,
    [piosaasGrantsApi.reducerPath]: piosaasGrantsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(metadataApi.middleware)
      .concat(piosaasServerApi.middleware)
      .concat(piosaasTreeApi.middleware)
      .concat(piosaasStorageApi.middleware)
      .concat(piosaasGrantsApi.middleware)
});
