import React from "react";
type BetSizeDisplay = "Chips" | "Percent" | "Both" | "Hide";
enum EvScaling {
  RAW,
  STANDARD,
  PERCENT
}

enum EvEqAverage {
  ARITHMETIC,
  WEIGHTED //by Matchups
}
interface IUserPreferences {
  presentation: {
    grid: {
      precisionGrid: number;
      precisionOther: number;
      trailingZerosHidden: boolean;
      averageForEvEq: EvEqAverage;
    };
    betSizesDisplay: {
      treeBrowser: BetSizeDisplay;
      grid: BetSizeDisplay;
      gridDetails: BetSizeDisplay;
    };
    evDisplay: {
      evRescale: EvScaling;
    };
  };
}

const defaultUserPrefs: IUserPreferences = {
  presentation: {
    grid: {
      precisionGrid: 4,
      precisionOther: 4,
      trailingZerosHidden: false,
      averageForEvEq: EvEqAverage.WEIGHTED
    },
    betSizesDisplay: {
      treeBrowser: "Both",
      grid: "Both",
      gridDetails: "Chips"
    },
    evDisplay: {
      evRescale: EvScaling.STANDARD
    }
  }
};

const UserPrefsContext =
  React.createContext<IUserPreferences>(defaultUserPrefs);

const useUserPrefsContext = () => React.useContext(UserPrefsContext);

export {
  UserPrefsContext,
  defaultUserPrefs,
  useUserPrefsContext,
  EvScaling,
  EvEqAverage
};
export type { BetSizeDisplay, IUserPreferences };
