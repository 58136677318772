import axios from "axios";
import React from "react";
import { Fragment } from "react";
import { Button, Form, FormGroup, ProgressBar, Table } from "react-bootstrap";
import { CheckSquareFill, XSquareFill, Trash } from "react-bootstrap-icons";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import { useSubmitSpotsMutation } from "../services/piosaasStorageApi";

import Spinner from "../utils/spinner/Spinner";

interface SpotsError {
  data: string;
  status: number;
}

function fileValidator(file: File) {
  return file.name?.endsWith(".cfr")
    ? null
    : {
        code: "invalid-file-name",
        message: `Invalid file. Please make sure the file has a .cfr extension.`
      };
}

function bytesToMegaBytes(size: number) {
  return (size / 1024 / 1024).toFixed(1);
}

type FileWithStatus = {
  file: File;
  status: "uploading" | "verified" | "rejected" | "processing";
  progress: number;
  abortController: AbortController;
};

function NewSpot() {
  const [validated, setValidated] = React.useState(false);
  const [spotName, setSpotName] = React.useState("");
  const [allFilesWithStatus, setAllFilesWithStatus] = React.useState<
    FileWithStatus[]
  >([]);
  const [nameError, setNameError] = React.useState<string | undefined>(
    undefined
  );
  const [fileError, setFileError] = React.useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = React.useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const cancel = () => {
    navigate("/");
  };

  const onDrop = async (
    acceptedFiles: File[],
    _fileRejections: FileRejection[],
    _event: DropEvent
  ) => {
    let newFilesWithStatus: FileWithStatus[] = [];
    const acceptedFilesWithStatus: FileWithStatus[] = acceptedFiles.map(
      (file) => ({
        file,
        status: "uploading",
        progress: 0,
        abortController: new AbortController()
      })
    );

    newFilesWithStatus = [...acceptedFilesWithStatus];
    setAllFilesWithStatus([...newFilesWithStatus]);
    newFilesWithStatus.forEach((file) => {
      file.abortController.signal.addEventListener("abort", () => {
        newFilesWithStatus = newFilesWithStatus.filter(
          (fileWithStatus) => fileWithStatus.file !== file.file
        );
      });
    });
    for (const file of newFilesWithStatus) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", file.file.type);
      try {
        await axios.post("/storage/upload/spots/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          // eslint-disable-next-line no-loop-func
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = total ? Math.floor((loaded * 100) / total) : 0;
            file.progress = percent;
            if (percent === 100) file.status = "processing";
            setAllFilesWithStatus([...newFilesWithStatus]);
          },
          // This is tot a way to cancel the upload, but maybe the cancelToken?
          // signal: file.abortController.signal,
          withCredentials: true
        });
        file.status = "verified";
        setAllFilesWithStatus([...newFilesWithStatus]);
      } catch (error) {
        file.status = "rejected";
        setAllFilesWithStatus([...newFilesWithStatus]);
      }
    }
    setAllFilesWithStatus(newFilesWithStatus);
  };

  const { getRootProps, getInputProps } = useDropzone({
    disabled: false,
    multiple: true,
    validator: fileValidator,
    onDrop
  });

  const [submitSpots, { isLoading: uploadLoading, error: uploadError }] =
    useSubmitSpotsMutation();

  const error = uploadError ? (uploadError as SpotsError).data : "";

  const handleSubmit = async (e: React.FormEvent) => {
    const form = e.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    const allFilesOk =
      allFilesWithStatus.length > 0 &&
      allFilesWithStatus.every((f) => f.status === "verified");
    const spotNameOk = /^[x00-x7F-_:-]/.test(spotName);
    if (!spotNameOk)
      setNameError("Please provide a name that conforms to the requirements.");
    if (!allFilesOk)
      setFileError("Please include only '.cfr' files for the spot.");

    if (!allFilesOk || !spotNameOk) return;

    const spotFiles = allFilesWithStatus.map(
      (fileWithStatus) => fileWithStatus.file.name
    );

    const result = await submitSpots({ spotName, spotFiles });
    if ("data" in result) setSuccess(result.data as string);
  };

  const statusIcons = {
    verified: <CheckSquareFill className="text-success" />,
    rejected: <XSquareFill className="text-danger" />,
    processing: <Spinner size={15} />,
    uploading: <Spinner size={15} />
  };

  const deleteAction = (file: FileWithStatus) => {
    file.abortController.abort();
    const newFiles = allFilesWithStatus.filter(
      (fileWithStatus) => fileWithStatus.file !== file.file
    );

    setAllFilesWithStatus(newFiles);
  };

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={4} lg={3}>
          <h1 className="mb-0">New Spot (WIP)</h1>
          <p className="text-muted">
            Create a new spot made up of solves for multiple boards. All solves
            for the spot should have the same solve parameters.
          </p>
          <p className="text-muted">This feature is still being worked on.</p>
        </Col>
        <Col sm={12} md={8} lg={6}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <FormGroup className="mb-3" controlId="spot-name-upload">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setSpotName(e.target.value.trim());
                }}
                value={spotName}
                id="spot-name-upload"
                placeholder="Example Spot Name"
                required={true}
                isValid={nameError == null || nameError.length < 1}
              />
              <Form.Text className="text-muted">
                A spot name can contain ASCII letters, numbers, and ., _, -, :.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {nameError}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className="mb-4" controlId="spot-files">
              <Form.Label>Files</Form.Label>
              <section className="bg-dark container w-50" id="myDropzone">
                {uploadLoading ? (
                  <Spinner />
                ) : (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} name="file" type="file" />
                    <p className="text-muted">
                      Drag 'n' drop .cfr files here, or click to select files.
                    </p>
                  </div>
                )}
              </section>
              <FileTable
                allFilesWithStatus={allFilesWithStatus}
                statusIcons={statusIcons}
                actionIcon={<Trash />}
                action={deleteAction}
              />
              <Form.Control.Feedback type="invalid">
                {fileError}
              </Form.Control.Feedback>
              <p className="mt-1 form-label text-danger">{fileError}</p>
            </FormGroup>
          </Form>
          <Form.Group
            className="d-flex flex-row-reverse align-items-center mb-3"
            controlId="submitButton">
            <div>
              <Button
                variant="secondary"
                onClick={cancel}
                className="ps-5 pe-5 me-3">
                {"Cancel"}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ps-5 pe-5"
                onClick={handleSubmit}>
                {uploadLoading ? (
                  <Spinner
                    size={16}
                    variant="light"
                    message="Waiting for reset..."
                  />
                ) : (
                  "Create"
                )}
              </Button>
            </div>
          </Form.Group>
          <p
            className="form-label text-danger mb-4"
            style={{ fontSize: "large" }}>
            {error}
          </p>
          <p className="form-label text-success" style={{ fontSize: "large" }}>
            {success}
          </p>
        </Col>
      </Row>
    </Fragment>
  );
}

type StatusIcons = {
  verified: JSX.Element;
  rejected: JSX.Element;
  processing: JSX.Element | null;
  uploading: JSX.Element | null;
};

function FileTable({
  allFilesWithStatus,
  statusIcons: icons,
  actionIcon,
  action
}: {
  allFilesWithStatus: FileWithStatus[];
  statusIcons: StatusIcons;
  actionIcon: JSX.Element;
  action: (file: FileWithStatus) => void;
}) {
  const statusText = {
    verified: "Verified",
    rejected: "Rejected",
    processing: "Processing...",
    uploading: "Uploading..."
  };

  return (
    <Table striped bordered className="mt-3">
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {allFilesWithStatus.map((row) => (
          <tr key={row.file.name}>
            <td>{row.file.name}</td>
            <td>{bytesToMegaBytes(row.file.size)} MB</td>
            <td>
              {row.status === "uploading" && (
                <ProgressBar now={row.progress} label={`${row.progress}%`} />
              )}
              <div>
                {icons[row.status]} {statusText[row.status]}
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="clickable"
                  onClick={() => {
                    action(row);
                  }}>
                  {actionIcon}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
export default NewSpot;
