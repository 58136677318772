/* eslint-disable indent */
import React from "react";
import { useResizeDetector } from "react-resize-detector";

import {
  isBelowSm,
  setRangeAction,
  useViewControlsDispatch,
  useViewControlsSelector,
  ViewControlsDispatch
} from "../../features/view-controls/viewSlice";

import type {
  Dimensions,
  DisplayOptions,
  FontSizes,
  RangeAction
} from "../../features/view-controls/viewSlice";
import handspace, { handsValuesMap } from "../../utils/handspace";
import type { HandSquareStack } from "../../utils/handspace/handspace.types";

import math, { NumberFormatter } from "../../utils/math";
import { BetSizeDisplay, useUserPrefsContext } from "../../utils/preferences";
import {
  Action,
  DisplayStringLength,
  NodeTree,
  colorsForAction,
  displayStringForNode
} from "../../utils/nodespace";

import type {
  FieldProps,
  GridComponentProps,
  GridRows,
  SquareDetailsGridProps
} from "./grid.types";
import {
  DetailsGridRow,
  SummaryGridRow,
  heightForRect,
  fontSizeForDetails,
  rescaleRangeValue,
  rescaleRangeToBasis
} from "./shared";
import type { SummaryGridHandGroup } from "./shared";

import {
  SquareRangeScaling,
  SquareRangeScalingBasis
} from "../../features/view-controls/viewSlice";

interface ActionDetails {
  action: Action;
  actionText: string;
  proportion: number;
}

interface ActionDetailsWithCombos extends ActionDetails {
  actionTextLong: string;
  actionTextShort: string;
  combos: number;
}

function strategySummaryShort(
  frequencies: number[][],
  allNodes: NodeTree,
  childIds: string[]
): ActionDetails[] {
  let totalFrequency = 0;

  const summary = frequencies.map((f, i) => {
    const total = math.sum(f);
    totalFrequency += total;
    const ithChild = allNodes[childIds[i]];

    return {
      action: ithChild.action,
      proportion: 0,
      total,
      actionText: displayStringForNode(
        ithChild,
        DisplayStringLength.DETAILS,
        "Chips"
      )
    };
  });
  if (totalFrequency > 0)
    summary.forEach((s) => (s.proportion = s.total / totalFrequency));
  return summary;
}

function strategySummaryLong(
  frequencies: number[][],
  allNodes: NodeTree,
  childIds: string[],
  range: number[]
): ActionDetailsWithCombos[] {
  let totalFrequency = 0;

  const summary = frequencies.map((f, i) => {
    const combosArray = f.map((freq, j) => freq * range[j]);
    const combos = math.sum(combosArray);
    totalFrequency += combos;
    const ithChild = allNodes[childIds[i]];

    return {
      action: ithChild.action,
      combos,
      proportion: 0,
      actionTextLong: displayStringForNode(
        ithChild,
        DisplayStringLength.FULL,
        "Chips"
      ),
      actionTextShort: displayStringForNode(
        ithChild,
        DisplayStringLength.SHORT,
        "Chips"
      ),
      actionText: displayStringForNode(
        ithChild,
        DisplayStringLength.DETAILS,
        "Chips"
      )
    };
  });
  if (totalFrequency > 0)
    summary.forEach((s) => (s.proportion = s.combos / totalFrequency));
  return summary;
}

interface ChildSummaryProps {
  areCombosShown: boolean;
  details: ActionDetailsWithCombos;
  fieldDimensions: Dimensions;
  fontSizes: FontSizes;
  width: number;
  x: number;
}

function spaceTextWidthProportion(
  fieldDimensions: Dimensions,
  fontSizes: FontSizes
) {
  const hSpace = fieldDimensions.width;
  const lineHeight = fontSizes.legend.strategyFontSize;
  return hSpace / lineHeight;
}

function ActionSummaryLabelLong(props: ChildSummaryProps) {
  const { presentation } = useUserPrefsContext();
  const { precisionOther, trailingZerosHidden } = presentation.grid;
  const { details, fieldDimensions, fontSizes, width, x } = props;
  const formatter = math.formatterForFloat(99, precisionOther);
  const actionColor = colorsForAction(details.action);

  const lineHeight = fontSizes.legend.strategyFontSize;
  const textWidthProportion = spaceTextWidthProportion(
    fieldDimensions,
    fontSizes
  );
  const isTruncated = textWidthProportion < 9;
  const isLabelTruncated = textWidthProportion < 5;

  const label = isLabelTruncated
    ? details.actionTextShort
    : details.actionTextLong;
  const value = `${math.formattedFloat(
    details.proportion * 100,
    formatter,
    trailingZerosHidden
  )}%`;
  const combos = `${math.formattedFloat(
    details.combos,
    formatter,
    trailingZerosHidden
  )}`;

  const style = { fontSize: `${lineHeight + 1}px` };
  return (
    <g>
      <text
        className="strategy-legend-action"
        dx={math.numberToProportion(width * 0.5)}
        dy={0}
        fill={actionColor.text}
        x={math.numberToProportion(x)}
        y={lineHeight * 1.5}
        style={style}
        textAnchor="middle">
        {label}
      </text>
      {props.areCombosShown ? (
        <text
          className="strategy-legend-combos"
          dx={math.numberToProportion(width * 0.5)}
          dy={lineHeight * 0.75}
          fill={actionColor.text}
          x={math.numberToProportion(x)}
          y={lineHeight * 2}
          style={style}
          textAnchor="middle">
          {combos} {isTruncated ? "c" : "combos"}
        </text>
      ) : null}
      <text
        className="strategy-legend-percentage"
        dx={math.numberToProportion(width * 0.5)}
        dy={props.areCombosShown ? lineHeight * 1.25 : lineHeight * -0.25}
        fill={actionColor.text}
        x={math.numberToProportion(x)}
        y={lineHeight * 3}
        style={style}
        textAnchor="middle">
        {value}
      </text>
    </g>
  );
}

function StrategyProportionSummary({
  summary,
  height
}: {
  summary: ActionDetails[];
  height: number;
}) {
  let xPos = 0;
  const actionSummaries = summary.map((s, i) => {
    const actionColor = colorsForAction(s.action);
    const result = (
      <rect
        key={`${i}-rect`}
        x={math.numberToProportion(xPos)}
        y={0}
        fill={actionColor.background}
        stroke={undefined}
        height={height}
        width={math.numberToProportion(s.proportion)}
      />
    );
    xPos += s.proportion;
    return result;
  });
  return <g>{actionSummaries}</g>;
}

function ChildSummary(props: ChildSummaryProps) {
  const { details, fieldDimensions, width, x } = props;
  const actionColor = colorsForAction(details.action);
  const height = fieldDimensions.height;
  return (
    <g>
      <rect
        x={math.numberToProportion(x)}
        y={0}
        fill={actionColor.background}
        stroke={undefined}
        height={height}
        width={math.numberToProportion(width)}
      />
      <ActionSummaryLabelLong {...props} />
    </g>
  );
}

interface ChildrenSummaryProps {
  areCombosShown: boolean;
  combos: ActionDetailsWithCombos[];
  dispatch: ViewControlsDispatch;
  display: DisplayOptions;
  fieldDimensions: Dimensions;
  fontSizes: FontSizes;
  rangeAction?: RangeAction;
  totalDimensions: Dimensions;
}

function ChildrenSummary({
  areCombosShown,
  dispatch,
  display,
  combos,
  fieldDimensions,
  fontSizes,
  rangeAction,
  totalDimensions
}: ChildrenSummaryProps) {
  const onClick = (action: ActionDetailsWithCombos, index: number) => {
    const newAction = {
      action: action.action.action,
      code: action.action.code,
      index
    };
    dispatch(setRangeAction(newAction));
  };
  const height = fieldDimensions.height;
  const gap = 0.01;
  const available = 1 - gap * (combos.length - 1);
  const width = available / combos.length;
  const summaries = combos.map((s, i) => {
    const x = i * width + i * gap;
    const borderClassName =
      display !== "Action+Range"
        ? null
        : rangeAction?.code === s.action.code
        ? "strategy-legend-border-active"
        : "strategy-legend-border";

    return (
      <g
        key={i}
        className="strategy-legend-child"
        onClick={() => onClick(s, i)}>
        <ChildSummary
          areCombosShown={areCombosShown}
          details={s}
          fieldDimensions={{
            height: fieldDimensions.height,
            width: totalDimensions.width * width
          }}
          fontSizes={fontSizes}
          width={width}
          x={x}
        />
        {borderClassName ? (
          <rect
            className={borderClassName}
            x={math.numberToProportion(x)}
            y={0}
            height={height}
            width={math.numberToProportion(width)}
          />
        ) : null}
      </g>
    );
  });

  return <g>{summaries}</g>;
}

interface ActionSquareProps extends FieldProps {
  summary: HandGroupSquareSummary;
  squareRangeScaling: SquareRangeScaling;
  squareRangeScalingBasis: SquareRangeScalingBasis;
  baseRescaleNumber: number;
}

function StrategyRect(props: ActionSquareProps) {
  const { fieldDimensions, summary, squareRangeScaling, baseRescaleNumber } =
    props;
  const s = summary.strategySummary;
  let rangeScaled = summary.rangeMean;
  if (squareRangeScaling === SquareRangeScaling.HEIGHT)
    rangeScaled = rescaleRangeValue(summary.rangeMean, baseRescaleNumber);

  const height = heightForRect(
    fieldDimensions.height,
    rangeScaled,
    squareRangeScaling
  );
  let xPos = 0;
  const actionSummaries = s.map((s, i) => {
    const actionColor = colorsForAction(s.action);
    const result = (
      <rect
        key={i}
        x={xPos}
        y={fieldDimensions.height - height}
        fill={actionColor.background}
        height={height}
        width={`${s.proportion * fieldDimensions.width}`}
      />
    );
    xPos += s.proportion * fieldDimensions.width;
    return result;
  });
  return <>{actionSummaries}</>;
}

function strategySummaries(props: GridComponentProps) {
  const { allNodes, childIds, gridData } = props;
  const rangedHandspace = handspace.rangedHandspace(
    gridData.rootRange,
    gridData.range
  );

  const handSquareSummaries =
    rangedHandspace.mapStackedRows<HandGroupSquareSummary>(
      gridData.frequencies,
      (r) => {
        return r.mapValues((v) => {
          return {
            borderColor: undefined,
            squareName: v.squareName,
            rangeMean: v.rangeMean,
            rootRangeMean: v.rootRangeMean,
            strategySummary: strategySummaryShort(
              v.handSquareValues,
              allNodes,
              childIds
            )
          };
        });
      }
    );

  return handSquareSummaries;
}

interface HandGroupSquareSummary extends SummaryGridHandGroup {
  strategySummary: ActionDetails[];
}

function strategyRows(props: GridComponentProps): GridRows {
  const { childIds, gridData, squareRangeScaling, squareRangeScalingBasis } =
    props;
  // Sometimes stale data comes in -- need to figure out why
  if (gridData.frequencies.length !== childIds.length) return undefined;

  const handSquareSummaries = strategySummaries(props);
  const { fieldDimensions, fontSizes } = props;
  let rangeRescaleBasis = 1;
  if (
    squareRangeScaling === SquareRangeScaling.HEIGHT &&
    squareRangeScalingBasis === SquareRangeScalingBasis.MAX
  ) {
    rangeRescaleBasis = Math.max(
      ...handSquareSummaries.map((row) =>
        Math.max(...row.map((v) => v.rangeMean))
      )
    );
  }

  const bgFunc = (summary: HandGroupSquareSummary) => (
    <StrategyRect
      summary={summary}
      baseRescaleNumber={rangeRescaleBasis}
      {...props}
    />
  );
  const labelFunc = (_summary: HandGroupSquareSummary) => null;

  const rows = handSquareSummaries.map((s, i) => (
    <SummaryGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      labelFunc={labelFunc}
      rowIndex={i}
      rowSummaries={s}
    />
  ));

  return {
    rows,
    squares: handSquareSummaries
  };
}

function StrategyLegend(props: GridComponentProps) {
  const { allNodes, childIds, fieldDimensions, fontSizes, gridData, node } =
    props;
  const { display, rangeAction, viewportDimensions } = useViewControlsSelector(
    (state) => state.viewControls
  );
  const dispatch = useViewControlsDispatch();
  const nodeTypeNormalized = node.type.toUpperCase();
  const targetRef = React.useRef<HTMLDivElement>(null);
  const { height, width } = useResizeDetector({ targetRef });

  const emptyLegend = <div />;

  if (nodeTypeNormalized.includes("SPLIT")) return emptyLegend;
  if (nodeTypeNormalized.includes("END")) return emptyLegend;

  // Sometimes stale data comes in -- need to figure out why
  if (gridData.frequencies.length !== childIds.length)
    return <div>Data mismatch: cannot show strategies</div>;
  const combos = strategySummaryLong(
    gridData.frequencies,
    allNodes,
    childIds,
    gridData.range
  );

  const actionSummaryHeight = fieldDimensions.height * 0.33;
  return (
    <div>
      <div
        className="d-flex"
        style={{ width: "100%", height: actionSummaryHeight }}>
        <svg className="viz" width="100%" height={actionSummaryHeight}>
          <StrategyProportionSummary
            summary={combos}
            height={actionSummaryHeight}
          />
        </svg>
      </div>
      <div
        className="mt-1 d-flex"
        style={{ width: "100%", height: fieldDimensions.height }}
        ref={targetRef}>
        <svg className="viz" width="100%" height={fieldDimensions.height}>
          <ChildrenSummary
            areCombosShown={!isBelowSm(viewportDimensions)}
            combos={combos}
            dispatch={dispatch}
            display={display}
            fieldDimensions={fieldDimensions}
            fontSizes={fontSizes}
            rangeAction={rangeAction}
            totalDimensions={{ height: height ?? 0, width: width ?? 0 }}
          />
        </svg>
      </div>
    </div>
  );
}

interface ActionSummaryTextProps {
  details: ActionDetails;
  label: string;
  value: string;
}

function ActionSummariesText({
  fieldDimensions,
  lineHeight,
  strategyTextStyle,
  summaries
}: {
  fieldDimensions: Dimensions;
  lineHeight: number;
  strategyTextStyle: React.CSSProperties;
  summaries: ActionSummaryTextProps[];
}) {
  // distance from bottom edge fieldDimensions.height * 0.05
  // Need room for at least summaries.length lines in all the squares
  const dy =
    fieldDimensions.height -
    lineHeight * (summaries.length - 1) -
    fieldDimensions.height * 0.05;
  let yPos = 0;
  const text = summaries.map((s, i) => {
    const actionColor = colorsForAction(s.details.action);
    const label = s.label;
    const value = s.value;
    const result = (
      <g key={i}>
        <text
          className="strategy-details-label"
          dx={0}
          dy={dy}
          fill={actionColor.text}
          style={strategyTextStyle}
          x={fieldDimensions.width * 0.05}
          y={yPos}
          textAnchor="start">
          {label}
        </text>
        <text
          className="strategy-details-value"
          dx={0}
          dy={dy}
          fill={actionColor.text}
          style={strategyTextStyle}
          x={fieldDimensions.width * 0.95}
          y={yPos}
          textAnchor="end">
          {value}
        </text>
      </g>
    );
    yPos += lineHeight;
    return result;
  });

  return <>{text}</>;
}

interface ActionSquareSummaryProps extends FieldProps {
  formatter: NumberFormatter;
  rangeValue: number;
  showNodeLabel: boolean;
  squareRangeScaling: SquareRangeScaling;
  summary: ActionDetails[];
  totalDimensions: Dimensions;
}

function ActionSquareSummary({
  fieldDimensions,
  fontSizes,
  formatter,
  rangeValue,
  showNodeLabel,
  squareRangeScaling,
  summary
}: ActionSquareSummaryProps) {
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;
  if (showNodeLabel == null) showNodeLabel = true;
  const height = heightForRect(
    fieldDimensions.height,
    rangeValue,
    squareRangeScaling
  );
  let xPos = 0;
  const targetLineHeight = fontSizes.details.strategyFontSize;
  // Need room for at least summaries.length lines in all the squares
  const lineHeight = fontSizeForDetails(
    targetLineHeight,
    fieldDimensions,
    0.66,
    summary.length + 1,
    8 + presentation.grid.precisionOther // "Check" + space + . + % = 8 chars
  );
  const strategyTextStyle = { fontSize: `${lineHeight}px` };
  const actionSummariesRect = summary.map((s, i) => {
    if (s.proportion < 0.001) return null;
    const actionColor = colorsForAction(s.action);
    const result = (
      <rect
        key={`${i}-rect`}
        x={xPos}
        y={fieldDimensions.height - height}
        fill={actionColor.background}
        stroke={undefined}
        height={height}
        width={s.proportion * fieldDimensions.width}
      />
    );
    xPos += s.proportion * fieldDimensions.width;
    return result;
  });

  const actionSummariesTextSummaries = summary.map((s, i) => {
    const label = showNodeLabel ? `${s.actionText}` : "";
    let value = "";
    /* eslint-disable indent */
    value = showNodeLabel
      ? `${math.formattedFloat(
          s.proportion * 100,
          formatter,
          trailingZerosHidden
        )}%`
      : `${math.formattedFloat(
          s.proportion * 100,
          formatter,
          trailingZerosHidden
        )}%`;
    /* eslint-enable indent */
    return { details: s, label, value };
  });

  return (
    <g>
      {actionSummariesRect}
      <ActionSummariesText
        fieldDimensions={fieldDimensions}
        lineHeight={lineHeight}
        strategyTextStyle={strategyTextStyle}
        summaries={actionSummariesTextSummaries}
      />
    </g>
  );
}

function strategySummaryForSquare(
  square: HandSquareStack,
  allNodes: NodeTree,
  childIds: string[],
  betSizeDisplay: BetSizeDisplay
): ActionDetails[] {
  const summary = square.stack.map((f, i) => {
    const ithChild = allNodes[childIds[i]];
    const proportion = isNaN(f) ? 0 : f;

    return {
      action: ithChild.action,
      proportion,
      total: proportion,
      actionText: displayStringForNode(
        ithChild,
        DisplayStringLength.DETAILS,
        betSizeDisplay
      )
    };
  });
  return summary;
}

function strategySquareDetailsRows(props: SquareDetailsGridProps) {
  const {
    allNodes,
    childIds,
    gridData,
    fieldDimensions,
    fontSizes,
    gridDetailsSizeDisplay,
    height,
    square,
    squareRangeScaling,
    squareRangeScalingBasis,
    width
  } = props;

  const handGroup = square.handGroup;
  const frequencies = gridData.frequencies;

  // Sometimes stale data comes in -- need to figure out why
  if (frequencies.length !== childIds.length) return null;
  if (handGroup == null) return null;

  const precision = props.numberDisplayPrecision;
  const formatter = math.formatterForFloat(99, precision);

  const range = gridData.range;
  const rhs = handspace.rangedHandspace(gridData.rootRange, range);
  const handStackDetails = rhs.mapChunkedHandGroupStackDetails<HandSquareStack>(
    handGroup,
    frequencies,
    (r) => r.mapValues((v) => v)
  );

  const handsRangeMap = handsValuesMap(
    rescaleRangeToBasis(range, squareRangeScalingBasis)
  );

  const totalDimensions = { width, height };

  const bgFunc = (square: HandSquareStack) => {
    const strategy = strategySummaryForSquare(
      square,
      allNodes,
      childIds,
      gridDetailsSizeDisplay
    );
    return (
      <ActionSquareSummary
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        formatter={formatter}
        rangeValue={handsRangeMap[square.hand]}
        showNodeLabel={true}
        squareRangeScaling={squareRangeScaling}
        summary={strategy}
        totalDimensions={totalDimensions}
      />
    );
  };

  const svgRows = handStackDetails.map((row, i) => (
    <DetailsGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      row={row}
      rowIndex={i}
      totalDimensions={totalDimensions}
    />
  ));

  return svgRows;
}

export {
  ActionSummariesText,
  StrategyLegend,
  strategyRows,
  strategySquareDetailsRows
};
