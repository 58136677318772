/* eslint-disable indent */
import { IScale } from "../../features/grid";
import {
  Dimensions,
  SquareRangeScaling
} from "../../features/view-controls/viewSlice";

import handspace, { handsValuesMap } from "../../utils/handspace";
import type {
  HandSquareData,
  HandSquareStack
} from "../../utils/handspace/handspace.types";

import math, { NumberFormatter } from "../../utils/math";
import {
  Action,
  DisplayStringLength,
  NodeTree,
  NodeTypes,
  colorsForAction,
  displayStringForNode
} from "../../utils/nodespace";
import { BetSizeDisplay, useUserPrefsContext } from "../../utils/preferences";
import { colorScaleForGrid, EqEvLegendViz } from "./eq-ev";

import type {
  FieldProps,
  GridComponentProps,
  SquareDetailsGridProps
} from "./grid.types";
import {
  DetailsGridRow,
  fontSizeForDetails,
  heightForRect,
  rescaleRangeToBasis
} from "./shared";
import { ActionSummariesText, strategyRows } from "./strategy";

interface ICombinedHandSquareStack extends HandSquareData {
  frequencies: HandSquareStack;
  childrenEvs: HandSquareStack;
}

class CombinedHandSquareStack implements ICombinedHandSquareStack {
  frequencies: HandSquareStack;
  childrenEvs: HandSquareStack;
  colIndex: number;
  hand: string;
  rowIndex: number;

  constructor(frequencies: HandSquareStack, childrenEvs: HandSquareStack) {
    this.frequencies = frequencies;
    this.childrenEvs = childrenEvs;
    this.colIndex = frequencies.colIndex;
    this.hand = frequencies.hand;
    this.rowIndex = frequencies.rowIndex;
  }
}

interface ActionEvDetails {
  action: Action;
  actionText: string;
  ev: number;
  proportion: number;
  total: number;
}

interface ActionSquareSummaryProps extends FieldProps {
  formatter: NumberFormatter;
  showNodeLabel: boolean;
  rangeValue: number;
  squareRangeScaling: SquareRangeScaling;
  summary: ActionEvDetails[];
  totalDimensions: Dimensions;
  evColorScale: IScale;
}

function ActionSquareSummary({
  evColorScale,
  fieldDimensions,
  fontSizes,
  formatter,
  rangeValue,
  showNodeLabel,
  squareRangeScaling,
  summary
}: ActionSquareSummaryProps) {
  const { presentation } = useUserPrefsContext();
  const { trailingZerosHidden } = presentation.grid;
  if (showNodeLabel == null) showNodeLabel = true;

  const evMaxHeight = fieldDimensions.height / 3;
  const strategyMaxHeight = fieldDimensions.height - evMaxHeight;

  const evHeight = heightForRect(evMaxHeight, rangeValue, squareRangeScaling);
  const strategyHeight = heightForRect(
    strategyMaxHeight,
    rangeValue,
    squareRangeScaling
  );
  let xPos = 0;
  const targetLineHeight = fontSizes.details.strategyFontSize;
  // Need room for at least summaries.length lines in all the squares
  const lineHeight = fontSizeForDetails(
    targetLineHeight,
    fieldDimensions,
    0.66,
    summary.length + 1,
    8 + presentation.grid.precisionOther // "Check" + space + . + % = 8 chars
  );
  const strategyTextStyle = { fontSize: `${lineHeight}px` };
  const actionSummaries = summary.map((s, i) => {
    if (s.proportion < 0.001) return null;
    const actionColor = colorsForAction(s.action);
    const strategyRect = (
      <rect
        key={`${i}-rect-strat`}
        x={xPos}
        y={fieldDimensions.height - evHeight - strategyHeight}
        fill={actionColor.background}
        stroke={undefined}
        height={strategyHeight}
        width={s.proportion * fieldDimensions.width}
      />
    );
    const evRect = (
      <rect
        key={`${i}-rect-ev`}
        x={xPos}
        y={fieldDimensions.height - evHeight}
        fill={`${evColorScale(s.ev)}`}
        stroke={undefined}
        height={evHeight}
        width={s.proportion * fieldDimensions.width}
      />
    );
    xPos += s.proportion * fieldDimensions.width;
    return (
      <g key={`${i}-strat-ev-rectangles`}>
        {strategyRect}
        {evRect}
      </g>
    );
  });

  const actionSummariesTextSummaries = summary.map((s, i) => {
    const label = showNodeLabel ? (isNaN(s.ev) ? "" : `${s.actionText}`) : "";
    let value = "";
    value = isNaN(s.ev)
      ? ""
      : `${math.formattedFloat(s.ev, formatter, trailingZerosHidden)}`;
    return { details: s, label, value };
  });

  return (
    <g>
      {actionSummaries}
      <ActionSummariesText
        fieldDimensions={fieldDimensions}
        lineHeight={lineHeight}
        strategyTextStyle={strategyTextStyle}
        summaries={actionSummariesTextSummaries}
      />
    </g>
  );
}

function strategyEvSummaryForSquare(
  square: CombinedHandSquareStack,
  allNodes: NodeTree,
  childIds: string[],
  betSizeDisplay: BetSizeDisplay
): ActionEvDetails[] {
  const summary = square.frequencies.stack.map((f, i) => {
    const ithChild = allNodes[childIds[i]];
    const proportion = isNaN(f) ? 0 : f;

    return {
      action: ithChild.action,
      actionText: displayStringForNode(
        ithChild,
        DisplayStringLength.DETAILS,
        betSizeDisplay
      ),
      ev: square.childrenEvs.stack[i],
      proportion,
      total: proportion
    };
  });
  return summary;
}

function strategyEvSquareDetailsRows(props: SquareDetailsGridProps) {
  const {
    allNodes,
    childIds,
    gridData,
    fieldDimensions,
    fontSizes,
    gridDetailsSizeDisplay,
    height,
    square,
    squareRangeScaling,
    squareRangeScalingBasis,
    width,
    node,
    player,
    display,
    evRescale
  } = props;

  const handGroup = square.handGroup;
  const frequencies = gridData.frequencies;
  const childrenEvs = gridData.childrenEvs;

  // Sometimes stale data comes in -- need to figure out why
  if (frequencies.length !== childIds.length) return null;
  if (handGroup == null) return null;

  const precision = props.numberDisplayPrecision;
  const formatter = math.formatterForFloat(99, precision);
  const evColorScale = colorScaleForGrid(
    gridData,
    allNodes[node.children[0]], //strategy + EV - rescale color to first child
    player,
    display,
    evRescale
  );
  const range = gridData.range;
  const rhs = handspace.rangedHandspace(gridData.rootRange, range);
  const frequencyDetails = rhs.mapChunkedHandGroupStackDetails<HandSquareStack>(
    handGroup,
    frequencies,
    (r) => r.mapValues((v) => v)
  );

  const childrenEvDetails =
    rhs.mapChunkedHandGroupStackDetails<HandSquareStack>(
      handGroup,
      childrenEvs,
      (r) => r.mapValues((v) => v)
    );

  const combinedDetails = frequencyDetails.map((ff, i) => {
    return ff.map(
      (f, j) => new CombinedHandSquareStack(f, childrenEvDetails[i][j])
    );
  });

  const handsRangeMap = handsValuesMap(
    rescaleRangeToBasis(range, squareRangeScalingBasis)
  );

  const totalDimensions = { width, height };

  const bgFunc = (combinedSquares: CombinedHandSquareStack) => {
    const strategy = strategyEvSummaryForSquare(
      combinedSquares,
      allNodes,
      childIds,
      gridDetailsSizeDisplay
    );
    return (
      <ActionSquareSummary
        evColorScale={evColorScale}
        fieldDimensions={fieldDimensions}
        fontSizes={fontSizes}
        formatter={formatter}
        rangeValue={handsRangeMap[combinedSquares.hand]}
        showNodeLabel={true}
        squareRangeScaling={squareRangeScaling}
        summary={strategy}
        totalDimensions={totalDimensions}
      />
    );
  };

  const svgRows = combinedDetails.map((row, i) => (
    <DetailsGridRow
      key={i}
      bgFunc={bgFunc}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
      row={row}
      rowIndex={i}
      totalDimensions={totalDimensions}
    />
  ));

  return svgRows;
}
function StrategyEvLegend(props: GridComponentProps) {
  const {
    gridData,
    allNodes,
    display,
    evRescale,
    fieldDimensions,
    fontSizes,
    node,
    player
  } = props;

  if (node.type === NodeTypes.END_NODE) return null;

  const colorScale = colorScaleForGrid(
    gridData,
    allNodes[node.children[0]], //strategy + EV - rescale color to first child
    player,
    display,
    evRescale
  );

  return (
    <EqEvLegendViz
      colorScale={colorScale}
      fieldDimensions={fieldDimensions}
      fontSizes={fontSizes}
    />
  );
}
export {
  StrategyEvLegend,
  strategyRows as strategyEvRows,
  strategyEvSquareDetailsRows
};
