import React from "react";
import { useDispatch } from "react-redux";

import Card from "react-bootstrap/Card";
import CloseButton from "react-bootstrap/CloseButton";

import { useNodeStateSelector } from "../../features/view-controls/nodeSlice";
import {
  SetSquareSource,
  SquareType,
  isBelowLg,
  setSquare,
  useViewControlsSelector
} from "../../features/view-controls/viewSlice";
import type { Dimensions } from "../../features/view-controls/viewSlice";

import handspace from "../../utils/handspace";
import { useUserPrefsContext } from "../../utils/preferences";

import { DetailsProps, GridComponentProps, GridProps } from "./grid.types";

import { eqEvSquareDetailsRows } from "./eq-ev";
import { rangeSquareDetailsRows } from "./range";
import { actionRangeData } from "./shared";
import { strategySquareDetailsRows } from "./strategy";
import { strategyEvSquareDetailsRows } from "./strategy-ev";
import { useSpotStateSelector } from "../../features/view-controls/spotSlice";

interface SquareDetailsGridProps extends GridComponentProps {
  square: SquareType;
  height: number;
  width: number;
  isDisplayingStrategy: boolean;
  isEndNode: boolean;
  isSplitNode: boolean;
}

function getSquareDetailsRows(props: SquareDetailsGridProps) {
  if (props.isDisplayingStrategy && (props.isSplitNode || props.isEndNode))
    return [];
  switch (props.display) {
    case "Strat":
      return strategySquareDetailsRows(props);
    case "Strat+EV":
      return strategyEvSquareDetailsRows(props);
    case "Range":
      return rangeSquareDetailsRows(props);
    case "EV":
    case "EQ":
      return eqEvSquareDetailsRows(props);
    case "Action+Range": {
      const actionRangeProps = actionRangeData(props);
      return rangeSquareDetailsRows(actionRangeProps);
    }
    default:
      return null;
  }
}

function OmniSquareDetailsGridSvg(props: SquareDetailsGridProps) {
  const { width, height } = props;

  const rows = getSquareDetailsRows(props);

  if (rows == null) return <div>Data mismatch</div>;
  return (
    <svg
      className="details"
      width="95%"
      height="95%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet">
      {rows}
    </svg>
  );
}

function PinButton() {
  /* eslint-disable max-len */
  return (
    <svg
      aria-label="Pin button"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pin-fill"
      viewBox="0 0 16 16">
      <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z" />
    </svg>
  );
  /* eslint-enable max-len */
}

function CloseHandGroup({
  detailsStyle,
  onClick,
  source
}: {
  detailsStyle: "floating" | "popover";
  onClick: () => void;
  source: string | null;
}) {
  if (source !== SetSquareSource.CLICK) return null;
  const style = { zIndex: 1, width: "100%" };

  return (
    <div className="text-end me-2 mt-1" style={style} onClick={onClick}>
      {detailsStyle === "floating" ? <PinButton /> : <CloseButton />}
    </div>
  );
}

interface OmniSquareEmptyDetailsProps extends GridProps {
  dimensions: Dimensions;
}

function OmniSquareEmptyDetails(props: OmniSquareEmptyDetailsProps) {
  const { width, height } = props.dimensions;
  const onClick = () => null;
  return (
    <Card className="hand-details">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div className="text-white">
            <span className="fs-4">&nbsp;</span>
          </div>
          <CloseHandGroup
            onClick={onClick}
            source={null}
            detailsStyle="floating"
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div
          className="d-flex flex-column flex-lg-row justify-content-lg-center"
          onClick={onClick}>
          <svg
            className="details"
            width="95%"
            height="95%"
            viewBox={`0 0 ${width} ${height}`}
            preserveAspectRatio="xMidYMid meet"></svg>
        </div>
      </Card.Body>
    </Card>
  );
}

function OmniSquareDetails(props: DetailsProps) {
  const { presentation } = useUserPrefsContext();
  const { precisionOther } = presentation.grid;
  const { gridDetails: gridDetailsSizeDisplay } = presentation.betSizesDisplay;
  const { nodeTree: allNodes } = useSpotStateSelector(
    (state) => state.spotState
  );
  const { displayNodeId } = useNodeStateSelector((state) => state.nodeState);
  const { fontSizes, square, squareDisplay, viewportDimensions } =
    useViewControlsSelector((state) => state.viewControls);
  const componentDims = props.componentDims;
  const height = componentDims.height - 50 || viewportDimensions.height - 460;
  const width = componentDims.width || viewportDimensions.width * 0.35;
  const { squareRangeScaling, squareRangeScalingBasis } = squareDisplay;
  const handGroup = square.handGroup;
  const dispatch = useDispatch();
  const onClickClose = () => {
    dispatch(setSquare({ handGroup: null, source: SetSquareSource.CLICK }));
  };

  const onClickGrid = () => {
    if (isBelowLg(viewportDimensions))
      dispatch(setSquare({ handGroup: null, source: SetSquareSource.CLICK }));
  };

  const minSize = 200;

  if (handGroup == null)
    return <OmniSquareEmptyDetails {...props} dimensions={{ height, width }} />;
  const node = allNodes[displayNodeId];
  const pot = node.pot;

  const chunkedHandStructure = handspace.chunkedHandStructure(handGroup);

  const fieldDimensions = {
    width: width / chunkedHandStructure.chunkSize,
    height: height / chunkedHandStructure.numberOfChunks
  };

  return (
    <Card className="hand-details">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div className="text-white">
            <span className="fw-bolder fs-4">{handGroup}</span>
          </div>
          <CloseHandGroup
            onClick={onClickClose}
            source={square.source}
            detailsStyle={props.detailsStyle}
          />
        </div>
      </Card.Header>
      <Card.Body style={{ minHeight: minSize }}>
        <div className="w-100 h-100 text-center" onClick={onClickGrid}>
          <OmniSquareDetailsGridSvg
            width={width}
            height={height}
            allNodes={allNodes}
            childIds={node.children}
            fieldDimensions={fieldDimensions}
            fontSizes={fontSizes}
            gridDetailsSizeDisplay={gridDetailsSizeDisplay}
            numberDisplayPrecision={precisionOther}
            pot={pot}
            square={square}
            squareRangeScaling={squareRangeScaling}
            squareRangeScalingBasis={squareRangeScalingBasis}
            {...props}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

function OmniDetails(props: DetailsProps) {
  return (
    <div
      className="d-flex h-100 user-select-none"
      style={{ minHeight: "200px" }}>
      <OmniSquareDetails {...props} />
    </div>
  );
}

export default OmniDetails;
