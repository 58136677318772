import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { ExclamationCircleFill } from "react-bootstrap-icons";

import { useMetadataQuery } from "./metadataApi";
import version from "./metadata";

/**
 * Display an indicator that there is some information to show the user.
 * @returns A status indicator
 */
function StatusIndicator() {
  const { data, error, isLoading } = useMetadataQuery("", {
    pollingInterval: 60 * 1000 * 5
  });
  if (isLoading || error != null || data == null) return null;
  if (version.version === data.version) return null;
  return (
    <Dropdown align="end" autoClose="outside">
      <Dropdown.Toggle variant="dark">
        <ExclamationCircleFill
          className="mb-1"
          fill="#C72C3A"
          width="18"
          height="18"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark">
        <Dropdown.Item className="pe-none user-select-none">
          <div>
            <b>New version is available</b>
            <br />
            <span>Please refresh the page to update.</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default StatusIndicator;
