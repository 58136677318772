import { Fragment } from "react";
import {
  CardsInDeck,
  CommunityCardsProps,
  PlayingCardProps,
  CardRank,
  CardSuit
} from "./cardTypes";

/**
 * Take a list of cards and compress their representation (remove spaces).
 * @param {string} cards
 * @returns The card in a compressed representation
 */
function cardsCompress(cards: string) {
  if (!cards) return "";
  return cards.replace(/\s/g, "");
}

const ranks = "23456789TJQKA";
const suits: CardSuit[] = ["s", "h", "d", "c"];

/**
 * Return the cards that could still be in the deck given a board, grouped by suit
 * @param {array}/{string} board the cards on the board or board as string
 * @returns {s: [], h: [], d: [], c: []}
 */
function cardsInDeck(board: string[] | string, currentStreet: number) {
  const result: CardsInDeck = { s: [], h: [], d: [], c: [] };
  if (typeof board === "string") board = cardsCompressAndSplit(board);
  const trimmedBoard = trimBoardToStreet(board, currentStreet);
  for (const rank of ranks) {
    for (const suit of suits)
      if (!trimmedBoard.includes(rank + suit)) result[suit].push(rank);
  }
  return result;
}
function trimBoardToStreet(board: string[], street: number) {
  if (board.length < 1) return board;
  const newBoard = [...board];

  while (newBoard.length > street) newBoard.pop();

  return newBoard;
}

/**
 * Extract the rank and suit from the card value
 * @param {string} value
 * @returns {rank, suit}
 */
function cardRankAndSuit(value: string) {
  const rank = value[0].toUpperCase() as CardRank;
  const suit = value[1].toLowerCase() as CardSuit;
  return { rank, suit };
}

/**
 * Break a string with multiple cards into individual cards.
 * @param {string} cards
 * @returns {array}
 */
function cardsSplit(cards: string) {
  const matches = cards.match(/.{1,2}/g);
  return matches ?? ([] as string[]);
}

function cardsCompressAndSplit(cards: string) {
  return cardsSplit(cardsCompress(cards));
}

function cardProps(value: string, isSelected?: boolean, isPlain?: boolean) {
  // See https://unicode-table.com/en/html-entities/#block-symbols for unicode points
  const suits = {
    s: { class: "suit-spades", htmlCode: "\u2660" },
    h: { class: "suit-hearts", htmlCode: "\u2665" },
    d: { class: "suit-diamonds", htmlCode: "\u2666" },
    c: { class: "suit-clubs", htmlCode: "\u2663" }
  };
  const { rank, suit } = cardRankAndSuit(value);
  const suitProps = suits[suit];
  if (!ranks.includes(rank) || !suit) return null;
  let className = "playing-card";
  if (isPlain) className += " text-plain-white";
  else className += ` ${suitProps.class}${isSelected ? "-selected" : ""}`;
  return { className, rank, suitProps };
}

function PlayingCard({ value, isBold, isPlain, isSelected }: PlayingCardProps) {
  const p = cardProps(value, isSelected, isPlain);
  if (p == null) return null;
  const { rank, suitProps } = p;
  let { className } = p;
  if (isBold) className = className + " fw-bolder";

  return (
    <span className={className}>
      {rank}
      {suitProps.htmlCode}
    </span>
  );
}

function PlayingCardSvg({ value, isSelected, isPlain }: PlayingCardProps) {
  // Same as PlayingCard, but returns an svg text

  const p = cardProps(value, isSelected, isPlain);
  if (p == null) return null;
  const { className, rank, suitProps } = p;

  return (
    <tspan className={className}>
      {rank}
      {suitProps.htmlCode}
    </tspan>
  );
}

function CommunityCards({
  cards,
  isPlain,
  isBold = false
}: CommunityCardsProps) {
  const splitCards = cardsCompressAndSplit(cards);
  if (splitCards.length < 1) return <span>Preflop</span>;
  const allCards = splitCards.map((card, i) => (
    <Fragment key={`community-cards-fragment${i}`}>
      {i !== 0 ? " " : null}
      <PlayingCard
        key={i}
        value={card}
        isPlain={isPlain}
        isSelected={false}
        isBold={isBold}
      />
    </Fragment>
  ));

  return <span>{allCards}</span>;
}
function sortBoardsByRank(boards: string[], ascending: boolean) {
  const result = [...boards];
  return result.sort((a, b) =>
    ascending ? compareBoards(a, b, 0) : compareBoards(b, a, 0)
  );
}
function compareBoards(a: string, b: string, index: number): number {
  if (!a[index]) return 0;
  const indexA = ranks.indexOf(a[index] as CardRank);
  const indexB = ranks.indexOf(b[index] as CardRank);
  if (indexA < indexB) return -1;
  if (indexA > indexB) return 1;
  return compareBoards(a, b, index + 2);
}
function HandCardsSvg({ cards, isPlain }: CommunityCardsProps) {
  const splitCards = cardsCompressAndSplit(cards);
  const allCards = splitCards?.map((card, i) => (
    <Fragment key={`hand-cards-fragment${i}`}>
      {i !== 0 ? " " : null}
      <PlayingCardSvg
        key={i}
        value={card}
        isPlain={isPlain}
        isSelected={false}
      />
    </Fragment>
  ));

  return <>{allCards}</>;
}

export {
  CommunityCards,
  HandCardsSvg,
  PlayingCard,
  cardsCompress,
  cardsInDeck,
  cardRankAndSuit,
  cardsSplit,
  cardsCompressAndSplit,
  sortBoardsByRank,
  ranks,
  suits
};
